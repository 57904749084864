import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Row, FormGroup } from "reactstrap";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { EditMaintenanceType } from "../../services/MaintenanceType";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../features/snackbar";

function UpdateModal({ data }) {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [item, setItem] = useState(data);
  const handelChange = (event) => {
    setItem({
      ...item,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    EditMaintenanceType(dispatch, item);
    const action = {
      snackbarOpen: true,
      snackbarType: "success",
      snackbarMessage: "L'opération s'est terminée avec succès",
    };
    dispatch(setSnackbar(action));
    handleClose();
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <IconButton aria-label="edit" onClick={handleShow} color="success">
        <EditIcon />
      </IconButton>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Entretien periodique </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <FormGroup className="booking__form d-inline-block text-left">
              <label className="text_color ">Nom de l'entretien périodique :</label>
              <input
                type="text"
                placeholder="Tapez le nom"
                required
                name="name"
                defaultValue={data.name}
                onChange={(e) => handelChange(e)}
              />
            </FormGroup>
            <FormGroup className="booking__form d-inline-block text-left">
              <label className="text_color ">Description de l'entretien périodique </label>
              <input
                type="textarea"
                placeholder="Autre informations"
                name="description"
                defaultValue={data.description}
                onChange={(e) => handelChange(e)}
                rows="6"
                className="textarea w-100"></input>
            </FormGroup>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn_secondary h-50 p-2 "
            onClick={() => handleSubmit()}>
            Eregistrer
          </button>
          <Button
            variant="secondary"
            style={{ borderRadius: "55px" }}
            onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateModal;
