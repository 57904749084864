import React from "react";

export default function LegalNotice() {
  return (
    <div className="m-3 p-2">
      <div className="m-3 p-2">
        <h4 className="text_color ">Mentions légales</h4>
        <div className="text_color text-left">
          RANCAR <br />
          Siège social : [Adresse de l'entreprise]
          <br />
          Téléphone : [Numéro de téléphone]
          <br />
          Numéro d'immatriculation : [Numéro d'immatriculation]
          <br />
        </div>
      </div>
      <div className="m-3 p-2">
        <h4 className="text_color text-left">
          Conditions générales d'utilisation
        </h4>
        <div className="text_color text-left">
          En utilisant ce site, vous acceptez les conditions générales
          d'utilisation de RANCAR. Vous vous engagez à respecter toutes les lois
          et réglementations applicables.
        </div>
      </div>
      <div className="m-3 p-2">
        <h4 className="text_color text-left">Politique de confidentialité</h4>
        <div className="text_color text-left">
          Consultez notre politique de confidentialité complète pour comprendre
          comment nous collectons, utilisons et protégeons vos informations
          personnelles. Nous nous engageons à respecter votre vie privée et à
          utiliser vos données de manière responsable.
        </div>
      </div>
      <div className="m-3 p-2">
        <h4 className="text_color text-left">Cookies</h4>
        <div className="text_color text-left">
          Notre site utilise des cookies. En continuant à naviguer sur le site,
          vous acceptez notre utilisation des cookies. Consultez notre politique
          de cookies pour plus d'informations.
        </div>
      </div>
      <div className="m-3 p-2">
        <h4 className="text_color text-left">Propriété intellectuelle</h4>
        <div className="text_color text-left">
          Tous les contenus du site, y compris textes, images, marques déposées,
          sont la propriété de RANCAR et sont protégés par les lois sur la
          propriété intellectuelle.
        </div>
      </div>
      <div className="m-3 p-2">
        <h4 className="text_color text-left">Responsabilité</h4>
        <div className="text_color text-left">
          RANCAR ne peut être tenu responsable des dommages directs ou indirects
          résultant de l'utilisation du site ou des services de location de
          véhicules.
        </div>
      </div>
      <div className="m-3 p-2">
        <h4 className="text_color text-left">
          Modalités de paiement et de réservation
        </h4>
        <div className="text_color text-left">
          Les paiements peuvent être effectués par [méthodes de paiement
          acceptées]. Les réservations sont sujettes à disponibilité et aux
          conditions spécifiées lors du processus de réservation.
        </div>
      </div>
      <div className="m-3 p-2">
        <h4 className="text_color text-left">Loi applicable et juridiction</h4>
        <div className="text_color text-left">
          Tout litige relatif à l'utilisation de notre site sera régi par les
          lois en vigueur [juridiction applicable]. Les tribunaux de [ville ou
          région] auront compétence exclusive.
        </div>
      </div>
      <div className="m-3 p-2">
        <h4 className="text_color text-left">Contact</h4>
        <div className="text_color text-left">
          Pour toute question ou préoccupation, veuillez nous contacter à
          [adresse e-mail de contact] ou par téléphone au [numéro de téléphone
          de contact].
        </div>
      </div>
    </div>
  );
}
