import React, { useEffect } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Form,
} from "reactstrap";
import "../../assets/styles/car-item.css";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { EditCalendarEvent } from "../../services/CalendarEvent";
import { Chip, Button } from "@mui/material";

const InfoEvent = ({ modelopen, handleModel }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const event = useSelector((state) => state.calendarEvent.event);

  const handleSubmit = async (e) => {
    const data = {
      ...event,
      confirmed: e,
    };
    EditCalendarEvent(dispatch, data);
    handleModel();
  };

  // const handleDelete = async (e) => {
  //   DeleteCalendarEvent(dispatch,event);
  //   handleModel();

  // };
  // const ev = event?.phone ? event : null;
  return (
    <Modal isOpen={modelopen} toggle={() => handleModel()} size="lg">
      <ModalHeader toggle={handleModel}>Réservation</ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Nom du véhicule: &nbsp;</Label>
                {event.vehicle.name}
              </FormGroup>
              <FormGroup>
                <Label>Marque: &nbsp;</Label>
                {event.vehicle.brand.name}
              </FormGroup>
              <FormGroup>
                <Label>Matricule: &nbsp;</Label>
                {event.vehicle.registration}
              </FormGroup>
              <FormGroup>
                <Label>Nom du client: &nbsp;</Label>
                {event.user.firstName}
              </FormGroup>
              <FormGroup>
                <Label>Prénom: &nbsp;</Label>
                {event.user.lastName}
              </FormGroup>
              <FormGroup>
                <Label>E-mail: &nbsp;</Label>
                {event.user.email}
              </FormGroup>
              <FormGroup>
                <Label>Numéro du telephone: &nbsp;</Label>
                {event.user.phone}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Date de prise en charge: &nbsp;</Label>
                {dayjs(event.startDate).format("DD/MM/YYYY")}
              </FormGroup>
              <FormGroup>
                <Label>Heure de prise en charge: &nbsp;</Label>
                {dayjs(event.startDate).format("HH:MM")}
              </FormGroup>
              <FormGroup>
                <Label>Date de restitution: &nbsp;</Label>
                {dayjs(event.endDate).format("DD/MM/YYYY")}
              </FormGroup>
              <FormGroup>
                <Label>Heure de restitution: &nbsp;</Label>
                {dayjs(event.endDate).format("HH:MM")}
              </FormGroup>
              {event.description ? (
                <FormGroup>
                  <Label>Description: &nbsp;</Label>
                  {event.description}
                </FormGroup>
              ) : null}
              <FormGroup>
                <Label>Methode de payment: &nbsp;</Label>
                {event.method}
              </FormGroup>
              <FormGroup>
                <Label>Statut: &nbsp;</Label>
                {event.confirmed === null ? (
                  <Chip
                    label="En attend"
                    style={{
                      color: "var(--white_color)",
                      backgroundColor: "var(--blue_color)",
                    }}
                    variant="elevated"
                  />
                ) : null}
                {event.confirmed === true ? (
                  <Chip
                    label="Confirmé"
                    className="text_color"
                    color="success"
                    variant="elevated"
                  />
                ) : null}
                {event.confirmed === false ? (
                  <Chip
                    label="Refusé"
                    className="text_color"
                    color="error"
                    variant="elevated"
                  />
                ) : null}
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        {event.confirmed === null ? (
          <>
            <Button
              onClick={() => handleSubmit(true)}
              color="success"
              variant="contained"
              style={{ borderRadius: "50px" }}>
              Confirmer
            </Button>{" "}
            &nbsp;
            <Button
              onClick={() => handleSubmit(false)}
              color="error"
              variant="contained"
              style={{ borderRadius: "50px" }}>
              Refuser
            </Button>
          </>
        ) : (
          <>
            {event.confirmed ? null : (
              <>
                {/* <Button onClick={(e) => handleDelete(e)} color="error" variant="contained" style={{borderRadius:"50px"}}>
          Supprimer
        </Button>{" "}&nbsp; */}
              </>
            )}
            <Button
              color="info"
              variant="contained"
              onClick={() => handleModel()}
              style={{ borderRadius: "50px" }}>
              Fermer
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default InfoEvent;
