import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../assets/data/config.json";
import Http from "../../lib/http/http";
import Loader from "../../lib/Loader/Loader";

export default function Payement({ user }) {
  const [subscription, setSubscription] = useState({
    id: 0,
    period: "",
    startDate: "",
    endDate: "",
    packageName: "",
    packageId: "",
    pricePayMonth: "",
    totalPrice: 0,
  });
  const [isPending, setIsPending] = useState(false);
  // const USER_ID_KEY = "userId";
  // const userId = localStorage.getItem(USER_ID_KEY);

  useEffect(() => {
    const getSubscription = async () => {
      const res = await Http.get(
        "/getSubscriptionInformation/" + user.subscription.id
      );
      setSubscription(res.data[0]);
    };
    getSubscription();
  }, [user]);

  const data = JSON.stringify({
    apikey: "462465890627e487755cbd0.65576267",
    site_id: "840574",
    transaction_id: Math.floor(Math.random() * 100000000).toString(), //
    amount: parseInt(subscription.totalPrice),
    currency: "XOF",
    alternative_currency: "",
    description: " TEST INTEGRATION ",
    //Carte bancaire
    customer_id: user?.id,
    customer_name: user?.firstName,
    customer_surname: user?.lastName,
    customer_email: user?.email,
    customer_phone_number: "+225" + user?.phone,
    customer_address: "Antananarivo",
    customer_city: "Antananarivo",
    customer_country: "CM",
    customer_state: "CM",
    customer_zip_code: "065100",
    notify_url: config.url + "/updatePaymentStatus/" + subscription.id,
    return_url: config.urlFrontEnd,
    channels: "",
    metadata: "user1",
    lang: "FR",
    invoice_data: {
      Donnee1: "",
      Donnee2: "",
      Donnee3: "",
    },
  });

  const configuration = {
    method: "post",
    url: "https://api-checkout.cinetpay.com/v2/payment",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const handleSubmit = async () => {
    const windowFeatures =
      "width=600,height=400,menubar=no,toolbar=no,location=no,resizable=yes,scrollbars=yes";
    try {
      setIsPending(true);
      const response = await axios(configuration);
      if (response.data.data.payment_url) {
        window.open(response.data.data.payment_url, "_blank", windowFeatures);
      } else {
        console.error(
          "La propriété 'payment_url' est manquante dans la réponse."
        );
      }
      setIsPending(false);
    } catch (error) {
      console.error("Une erreur s'est produite lors de la requête :", error);
    }
  };

  return (
    <div>
      {isPending ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <button className="btn_secondary" onClick={handleSubmit}>
            Payer
          </button>
        </>
      )}
    </div>
  );
}
