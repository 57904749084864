import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ConditionText from "./ConditionText";

export default function COnditionModal() {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <div>
      <button className="btn_secondary" onClick={toggle}>
        Conditions
      </button>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader className="body_color text_color" toggle={toggle}>
          Conditions générales{" "}
        </ModalHeader>
        <ModalBody className="body_color">
          <h2 className="text_color">
            Conditions générales d’utilisation du service de réservation :
          </h2>
          <ConditionText />
        </ModalBody>
        <ModalFooter className="body_color">
          <Button color="secondary" onClick={toggle}>
            Fermer
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
