import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import FormGroup from "react-bootstrap/esm/FormGroup";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { GetDocumentType } from "../../../../services/DocumentType";
import Http from "../../../../lib/http/http";
import { useParams } from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Tooltip from "@mui/material/Tooltip";


function AddDocumentModal({ item,refreshFunction }) {
  const toDay = dayjs(new Date()).format("YYYY-MM-DD");
  const dispatch = useDispatch();
  const documents = useSelector((state) => state.document.DocumentType);
  // const isPending= useSelector(state => state.document.loading);
  const { slug } = useParams();

  useEffect(() => {
    GetDocumentType(dispatch);
  }, [dispatch]);

  const [data, setData] = useState({
    documentType: 0,
    vehicle: slug,
    date: toDay,
    cost: 0,
    nextDate: toDay,
    note: "",
    notificationEnabled:1
  });
  // const [response, setResponse] = useState(false);
  const response = false;
  const [show, setShow] = useState(false);

  const handleAdd = async () => {
    setShow(false);
    const url = "/addDocument";
    await Http.post(url, data);
    refreshFunction();
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [open, setOpen] = useState(false);
  const handleCloseSnakBar = () => setOpen(false);
  // const handleOpenSnakBar = () => setOpen(true);
  const handelChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <>
    <Tooltip title="Ajouter document" placement="bottom">
      <button className="btn_modal" onClick={handleShow}>
        <AddCircleIcon/>
      </button>
    </Tooltip>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title>Ajouter document </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup className="booking__form d-inline-block text-left ">
            <label className="text_color">Type document:</label>
            <select
              className="select_input"
              onChange={(e) => handelChange(e)}
              name="documentType">
              <option value={0}>Choisir le type du document</option>
              {documents.map((item, index) => {
                return (
                  <option key={index} value={parseInt(item.id)}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </FormGroup>
          <FormGroup className="booking__form d-inline-block text-left ">
            <label className="text_color">Date opérationnelle</label>
            <input
              className="select_input"
              type="date"
              placeholder="Date"
              defaultValue={data["date"]}
              name="date"
              onChange={(e) => handelChange(e)}
            />
          </FormGroup>
          <FormGroup className="booking__form d-inline-block text-left ">
            <label className="text_color">Date de validité:</label>
            <input
              className="select_input"
              type="date"
              placeholder="Date"
              defaultValue={data["nextDate"]}
              name="nextDate"
              onChange={(e) => handelChange(e)}
            />
          </FormGroup>
          <FormGroup className="booking__form d-inline-block text-left ">
            <label className="text_color">Le frait du document:</label>
            <input
              className="select_input"
              type="number"
              defaultValue={data["cost"]}
              name="cost"
              onChange={(e) => handelChange(e)}
            />
          </FormGroup>
          <FormGroup className="booking__form d-inline-block text-left ">
            <label className="text_color">Note:</label>
            <input
              className="select_input"
              type="text"
              defaultValue={data["costs"]}
              name="note"
              onChange={(e) => handelChange(e)}
            />
          </FormGroup>
          <FormGroup className="booking__form d-inline-block text-left ">
            <label className="text_color">la Notification:</label>
            <select
              className="select_input"
              onChange={(e) => handelChange(e)} defaultValue={data["notificationEnabled"]}
              name="notificationEnabled">
              <option value={1}>Activer la notification</option>
              <option value={0}>Désactiver la notification</option>      
            </select>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn_modal" onClick={handleAdd}>
            Ajouter
          </button>
          <Button variant="secondary" onClick={handleClose} style={{borderRadius:"55px" }}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleCloseSnakBar}>
        {response ? (
          <Alert
            onClose={handleCloseSnakBar}
            severity="success"
            sx={{ width: "100%" }}>
            L'opération s'est terminée avec succès
          </Alert>
        ) : (
          <Alert
            severity="error"
            onClose={handleCloseSnakBar}
            sx={{ width: "100%" }}>
            Opération échouée !
          </Alert>
        )}
      </Snackbar>
    </>
  );
}

export default AddDocumentModal;
