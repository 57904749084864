import { setManagers } from "../features/manager";
import { setSnackbar } from "../features/snackbar";
import { Role_Manger } from "../assets/data/Roles";
import Http from "../lib/http/http";

const USER_ID_KEY = "userId";

const getUserId = () => {
  const userId = localStorage.getItem(USER_ID_KEY);
  if (!userId) {
    throw new Error("User ID not found in localStorage");
  }
  return userId;
};

const handleApiError = (dispatch, errorType, errorMessage) => {
  const action = {
    snackbarOpen: true,
    snackbarType: errorType,
    snackbarMessage: errorMessage,
  };
  dispatch(setSnackbar(action));
};

export const GetManagers = async (dispatch) => {
  try {
    const userId = getUserId();
    const { data } = await Http.get(
      "/api/getAllUserByRole/" + userId + "/" + Role_Manger
    );
    console.log(data);
    dispatch(setManagers(data));
  } catch {
    handleApiError(dispatch, "error", "Erreur lors du chargement les abonnées");
  }
};

// export const NewDocumentType = async (dispatch, DocumentType) => {
//     try {
//         const userId = getUserId();
//         const { data } = await Http.post('/api/addDocumentType/'+userId, DocumentType);
//         dispatch(newDocumentType(data));
//         handleApiError(dispatch, 'success', 'Le document a été soumise avec succès!');
//     } catch {
//       handleApiError(dispatch, 'error', 'Erreur lors de la soumission du document!');
//     }
// }

// export const EditDocumentType = async (dispatch, DocumentType) => {
//     try {
//         const userId = getUserId();
//         const { data } = await Http.post('/api/updateDocumentType/'+userId+"/"+DocumentType.id, DocumentType);
//         dispatch(editDocumentType(data));
//         handleApiError(dispatch, 'success', 'La modification a été soumise avec succès!');
//     } catch {
//       handleApiError(dispatch, 'error', 'Erreur lors de la soumission de la modification!');
//     }
// }

// export const DeleteDocumentType = async (dispatch, DocumentType) => {
//     try {
//         const userId = getUserId();
//         await Http.delete('/api/documentType/'+userId+"/"+DocumentType.id);
//         dispatch(deleteDocumentType(DocumentType));
//         handleApiError(dispatch, 'success', 'La suppression a été soumise avec succès!');
//     } catch {
//       handleApiError(dispatch, 'error', 'Erreur lors de la soumission de la suppression!');
//     }
// }
