import React, { useEffect, useState } from "react";
import Heading from "../Heading";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../assets/data/config.json";
import { GetCategory } from "../../../services/Category";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./VehiculeTypes.css";
import dayjs from "dayjs";

export default function VehiculeTypes() {
  const matches = useMediaQuery("(min-width:1400px)");
  const settings = {
    infinite: true,
    lazyLoad: false,
    speed: 1000,
    slidesToShow: matches ? 3 : 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    swipeToSlide: true,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />, //imported from 'react-icons'
    prevArrow: <PrevArrow />, //imported from 'react-icons'
    beforeChange: (current, next) => setImgIndex(next),
  };
  const [imgIndex, setImgIndex] = useState(0);
  const dispatch = useDispatch();
  const cars = useSelector((state) => state.category.Category);

  // const isPending = useSelector(state => state.category.loading);

  useEffect(() => {
    GetCategory(dispatch);
  }, [dispatch]);
  const toDay = dayjs(new Date()).format("YYYY-MM-DD");

  return (
    <div className=" w-100">
      <Heading
        title="Trouvez le véhicule parfait à posséder avec nous"
        subtitle="Une vaste gamme vous attend, pour répondre à toutes vos envies de conduite"
      />
      {imgIndex === 0 ? <></> : null}
      <Slider {...settings}>
        {cars.slice(0, 10).map((car) => {
          return (
            <div key={car.id} className="hero__wrapper m-3">
              <div
                className={`rectangle ${
                  car.name === "bad" ? "bad__alignment" : ""
                }`}>
                <div className="alignment__value">{car.name}</div>
              </div>
              <img
                className="hero__img"
                loading="lazy"
                src={config.images_category + "/" + car.uriPicture}
                alt={car.name}
              />
              <div className="mask">
                <h3 className="hero__name">{car.name}</h3>
                <div className="hero__info">
                  <ul>
                    <li>
                      Description:<span>{car.description}</span>
                    </li>
                    <Link
                      to={"/cars/ALL/" + car.name + "/" + toDay + "/" + toDay}>
                      <button className="btn_secondary mt-3">Reserver</button>
                    </Link>
                  </ul>
                </div>
                <div className="hero__powerstats"></div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
const NextArrow = ({ onClick }) => {
  return (
    <div className="arrow next" onClick={onClick}>
      <FaArrowRight />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className="arrow prev" onClick={onClick}>
      <FaArrowLeft />
    </div>
  );
};
