import React, { useEffect, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ProfileUpdateModel from "../../components/UI/UpdateProfile/ProfileUpdateModel";
import getCurrentUser from "../../hooks/GetCurrentUser";
import HistoryProfile from "./HistoryProfile";
import HistoryPackage from "./HistoryPackage";
import { Role_Manger } from "../../assets/data/Roles";
import Payement from "./Payement";
import { useSelector } from "react-redux";

export default function Profile() {
  const { data } = getCurrentUser();
  const [realData, setRealData] = useState();
  const user = useSelector((state) => state.user.value);
  
  useEffect(() => {
    if (data) {
      setRealData(data);
    }
  }, [data]);
  return (
    <div className="m-3">
      <div className="container mt-3 ">
        <div className="main-body ">
          <div className="row gutters-sm">
            <div className="col-md-4 mb-3 ">
              <div className="card ">
                <div className="card-body body_color">
                  <div className="d-flex flex-column align-items-center text-center">
                    <AccountCircleIcon
                      style={{ fontSize: "120px", color: "var(--blue_color)" }}
                    />
                    <div className="mt-3">
                      <h4 className="text_color">
                        {data?.firstName && data?.lastName ? data?.firstName + " " + data?.lastName: null}
                      </h4>
                      <p className=" mb-1 text_color">{data?.email}</p>
                      {user?.roles[0] === Role_Manger ? (
                        <>
                          <p className=" font-size-sm text_color">
                            Abonnement : {data?.subscription?.package.name}
                          </p>
                          <p className=" font-size-sm text_color">
                            Statut :{" "}
                            {data?.subscription?.status ? (
                              <>active</>
                            ) : (
                              <>bloque</>
                            )}
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                      {user?.roles[0] === Role_Manger &&
                      data?.subscription?.paymentStatus === false ? (
                        <p>
                          <Payement user={data} />
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card mb-3">
                <div className="card-body body_color">
                  <div className="row">
                    <div className="col-sm-3 text_color">
                      <h6 className="mb-0 text_color">Nom et Prénom</h6>
                    </div>
                    <div className="col-sm-9 text_color">
                    {data?.firstName && data?.lastName ? data?.firstName + " " + data?.lastName: null}
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0 text_color">Email</h6>
                    </div>
                    <div className="col-sm-9 text_color">{data?.email}</div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0 text_color">Téléphone</h6>
                    </div>
                    <div className="col-sm-9 text_color">{data?.phone}</div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0 text_color">Adresse</h6>
                    </div>
                    <div className="col-sm-9 text_color">{data?.address}</div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-12">
                      <ProfileUpdateModel
                        data={realData}
                        setData={(e) => setRealData(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row gutters-sm">
                {user?.roles[0] === Role_Manger ? (
                  <HistoryPackage data={data} />
                ) : (
                  <HistoryProfile />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
