import React, { useState } from "react";
import { FormGroup } from "reactstrap";
import { Link } from "react-router-dom";

export default function PackageItem({ item, key }) {
  const [defaultPrice, setDefaultPrice] = useState(item.priceS);
  const handleChange = (e) => {
    parseFloat(e.target.value) === parseFloat(item.priceA)
      ? setDefaultPrice(item.priceA)
      : setDefaultPrice(item.priceS);
  };
  return (
    <div
      className="box shadow text_color mt-3"
      style={{ borderRadius: "55px" }}
      key={key}>
      <h3
        className="text-center font-weight-bold ml-2"
        style={{ color: "var(--blue_color)" }}>
        {item.name}
      </h3>
      <h1>
        {defaultPrice}
        <span>CFA</span>
      </h1>
      {defaultPrice === item.priceA ? (
        <p>HT/mois pendant 12 mois</p>
      ) : (
        <p>HT/mois pendant 6 mois</p>
      )}
      <FormGroup className="booking__form text-center centered-element  w-75">
        <select
          className="select_input"
          onChange={(e) => handleChange(e)}
          name="id">
          <option value={item.priceS} selected={defaultPrice === item.priceS}>
            Engagement 6 mois{" "}
          </option>
          )
          <option value={item.priceA} selected={defaultPrice === item.priceA}>
            Engagement 12 mois{" "}
          </option>
          )
        </select>
      </FormGroup>

      <ul>
        <li>
          <label
            style={{
              background: item.parc ? "#27ae60af" : "#dc3545ae",
              color: item.parc ? "#27ae60" : "#dc3848",
            }}>
            {item.parc ? (
              <i className="fa-solid fa-check"></i>
            ) : (
              <i className="fa-solid fa-x"></i>
            )}
          </label>
          <p>Parking Sécurisé</p>
        </li>

        <li>
          <label
            style={{
              background: item.reservation ? "#27ae60af" : "#dc3545ae",
              color: item.reservation ? "#27ae60" : "#dc3848",
            }}>
            {item.reservation ? (
              <i className="fa-solid fa-check"></i>
            ) : (
              <i className="fa-solid fa-x"></i>
            )}
          </label>
          <p>Gestion Facile des Réservations</p>
        </li>
        <li>
          <label
            style={{
              background: item.notification ? "#27ae60af" : "#dc3545ae",
              color: item.notification ? "#27ae60" : "#dc3848",
            }}>
            {item.notification ? (
              <i className="fa-solid fa-check"></i>
            ) : (
              <i className="fa-solid fa-x"></i>
            )}
          </label>
          <p>Notification Instantanée</p>
        </li>
        <li>
          <label
            style={{
              background: item.caisse ? "#27ae60af" : "#dc3545ae",
              color: item.caisse ? "#27ae60" : "#dc3848",
            }}>
            {item.caisse ? (
              <i className="fa-solid fa-check"></i>
            ) : (
              <i className="fa-solid fa-x"></i>
            )}
          </label>
          <p>Caisse Rapide</p>
        </li>
        <li>
          <label
            style={{
              background: item.garage ? "#27ae60af" : "#dc3545ae",
              color: item.garage ? "#27ae60" : "#dc3848",
            }}>
            {item.garage ? (
              <i className="fa-solid fa-check"></i>
            ) : (
              <i className="fa-solid fa-x"></i>
            )}
          </label>
          <p>Garage d'Entretien Professionnel</p>
        </li>
      </ul>
      <Link to={`/subscribe/${item.name}`}>
        <button className="btn_secondary">Commander {item.name}</button>
      </Link>
    </div>
  );
}
