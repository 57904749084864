import React from "react";
import {
  BarChart,
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import useFetchManager from "../../../hooks/useFetchManager";

export default function Exemple() {
  const { data } = useFetchManager(
    "/api/getTotalCreditAndDebitByUser/" + localStorage.getItem("userId")
  );
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip />
        <Legend verticalAlign="top" wrapperStyle={{ lineHeight: "40px" }} />
        <ReferenceLine y={0} stroke="#000" />
        <Brush dataKey="month" height={30} stroke="var(--blue_color)" />
        <Bar dataKey="debit" fill="#FF8C8B" />
        <Bar dataKey="credit" fill="var(--blue_color)" />
      </BarChart>
    </ResponsiveContainer>
  );
}
