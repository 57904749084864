import { createSlice, createAction } from "@reduxjs/toolkit";

export const setDocumentTypeError = createAction("setDocumentTypeError");
export const newDocumentTypeError = createAction("newDocumentTypeError");
export const editDocumentTypeError = createAction("editDocumentTypeError");
export const deleteDocumentTypeError = createAction("deleteDocumentTypeError");

export const DocumentTypeSlice = createSlice({
  name: "DocumentType",
  initialState: {
    DocumentType: [],
    loading: false,
  },
  reducers: {
    setDocumentType: (state, action) => {
      return { ...state, DocumentType: action.payload, loading: true };
    },
    newDocumentType: (state, action) => {
      return {
        ...state,
        DocumentType: [...state.DocumentType, action.payload],
      };
    },
    editDocumentType: (state, action) => {
      const DocumentType = state.DocumentType.map((DocumentType) => {
        if (DocumentType.id === action.payload.id) {
          DocumentType = action.payload;
        }
        return DocumentType;
      });
      return { ...state, DocumentType: [...DocumentType] };
    },
    deleteDocumentType: (state, action) => {
      const DocumentType = state.DocumentType.filter(
        (DocumentType) => DocumentType.id !== action.payload.id
      );
      return { ...state, DocumentType: [...DocumentType] };
    },
    setLoading: (state, action) => {
      return { ...state, loading: action.payload };
    },
  },
});

export const {
  setDocumentType,
  newDocumentType,
  editDocumentType,
  deleteDocumentType,
  setLoading,
} = DocumentTypeSlice.actions;

export default DocumentTypeSlice.reducer;
