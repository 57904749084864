import { createSlice } from "@reduxjs/toolkit";

export const customerSlice = createSlice({
  name: "customer",
  initialState: {
    customers: [],
    loading: false,
  },
  reducers: {
    setCustomers: (state, action) => {
      return { ...state, customers: action.payload, loading: true };
    },
    newCustomer: (state, action) => {
      return { ...state, customers: [...state.customers, action.payload] };
    },
    editCustomer: (state, action) => {
      const customers = state.customers.map((customer) => {
        if (customer.id === action.payload.id) {
          customer = action.payload;
        }
        return customer;
      });
      return { ...state, customers: [...customers] };
    },
    deleteCustomer: (state, action) => {
      const customers = state.customers.filter(
        (customer) => customer.id !== action.payload.id
      );
      return { ...state, customers: [...customers] };
    },
    setLoading: (state, action) => {
      return { ...state, loading: action.payload };
    },
  },
});

export const {
  setCustomers,
  newCustomer,
  editCustomer,
  deleteCustomer,
  setLoading,
} = customerSlice.actions;

export default customerSlice.reducer;
