import React from "react";
import ErrorIcon from "@mui/icons-material/Error";

export default function OutOfService() {
  return (
    <div style={{marginTop: "100px",marginBottom:"100px" }}>
      <ErrorIcon style={{color: "var(--blue_color)",fontSize:"150px"}} />
      <div
        className="text_color"
        style={{ height: "100px",marginTop:"25px"}}
      >
        Ce service n'est pas disponible pour le moment
      </div>
    </div>
  );
}
