import React from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

export default function SnackbarInfo({
  successMessage,
  errorMessage,
  open,
  setOpen,
  response,
  vertical,
  horizontal,
}) {
  const handleCloseSnakBar = () => setOpen(false);
  // const handleOpenSnakBar = () => setOpen(true);
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical, horizontal }}
      autoHideDuration={6000}
      onClose={handleCloseSnakBar}>
      {response ? (
        <Alert
          onClose={handleCloseSnakBar}
          severity="success"
          sx={{ width: "100%" }}>
          {successMessage}
        </Alert>
      ) : (
        <Alert
          severity="error"
          onClose={handleCloseSnakBar}
          sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      )}
    </Snackbar>
  );
}
