import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
// import { Link } from "react-router-dom";
import PackageItem from "./PackageItem";
import { useDispatch, useSelector } from "react-redux";
import { GetPackages } from "../../services/Package";

const PriceCard = () => {
  const dispatch = useDispatch();
  const packages = useSelector((state) => state.package.packages);

  // const isPending = useSelector((state) => state.package.loading);

  useEffect(() => {
    GetPackages(dispatch);
  }, [dispatch]);
  return (
    <Row className="m-3">
      {packages.map((item, index) => (
        <Col>
          <PackageItem item={item} key={index} />
        </Col>
      ))}
    </Row>
  );
};

export default PriceCard;
