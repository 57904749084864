import {
  setCategory,
  newCategory,
  editCategory,
  deleteCategory,
  setLoading,
} from "../features/category";
import { setSnackbar } from "../features/snackbar";
import HttpForm from "../lib/http/httpForm";

const USER_ID_KEY = "userId";

const getUserId = () => {
  const userId = localStorage.getItem(USER_ID_KEY);
  if (!userId) {
    throw new Error("User ID not found in localStorage");
  }
  return userId;
};

const handleApiError = (dispatch, errorType, errorMessage) => {
  const action = {
    snackbarOpen: true,
    snackbarType: errorType,
    snackbarMessage: errorMessage,
  };
  dispatch(setSnackbar(action));
};

export const GetCategory = async (dispatch) => {
  try {
    const { data } = await HttpForm.get("/categories");
    dispatch(setCategory(data));
  } catch {
    handleApiError(
      dispatch,
      "error",
      "Erreur lors du chargement les categorys"
    );
  }
};

export const NewCategory = async (dispatch, Category) => {
  try {
    dispatch(setLoading(false));
    const userId = getUserId();
    const { data } = await HttpForm.post(
      "/api/addCategory/" + userId,
      Category
    );
    dispatch(newCategory(data));
    handleApiError(dispatch, "success", "La ville a été soumise avec succès!");
    dispatch(setLoading(true));
  } catch {
    handleApiError(
      dispatch,
      "error",
      "Erreur lors de la soumission de la category!"
    );
  }
};

export const EditCategory = async (dispatch, Category) => {
  try {
    dispatch(setLoading(false));
    const userId = getUserId();
    const { data } = await HttpForm.post(
      "/api/updateCategory/" + userId + "/" + Category.get("id"),
      Category
    );
    dispatch(editCategory(data));
    handleApiError(
      dispatch,
      "success",
      "La modification a été soumise avec succès!"
    );
    dispatch(setLoading(true));
  } catch {
    handleApiError(
      dispatch,
      "error",
      "Erreur lors de la soumission de la modification!"
    );
  }
};

export const DeleteCategory = async (dispatch, Category) => {
  try {
    dispatch(setLoading(false));
    const userId = getUserId();
    await HttpForm.delete("/api/category/" + userId + "/" + Category.id);
    dispatch(deleteCategory(Category));
    handleApiError(
      dispatch,
      "success",
      "La suppression a été soumise avec succès!"
    );
    dispatch(setLoading(true));
  } catch {
    handleApiError(
      dispatch,
      "error",
      "Erreur lors de la soumission de la suppression!"
    );
  }
};
