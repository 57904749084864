import React from "react";
import useFetch from "../../hooks/useFetch";
import Loader from "../../lib/Loader/Loader";
import { Chip } from "@mui/material";
import dayjs from "dayjs";

export default function HistoryProfile() {
  const { data, isPending } = useFetch(
    "/reservationByUser/" + localStorage.getItem("userId")
  );

  const history = data;
  return (
    <div className="col-md-12 mb-3">
      <div className="card h-100 w-100">
        <div className="card-body body_color">
          <h6 className="d-flex align-items-center mb-3 text_color">
            Historique activite
          </h6>
          {isPending ? (
            <Loader />
          ) : (
            <>
              {history.length !== 0 ? (
                <>
                  <table>
                    <thead>
                      <td width={"20%"}>Nom</td>
                      <td width={"20%"}>Type</td>
                      <td width={"20%"}>Date de prise</td>
                      <td width={"20%"}>Date de restitution</td>
                      <td width={"20%"}>Status</td>
                    </thead>
                    <tbody>
                      {history.map((item, index) => {
                        return (
                          <tr>
                            <td>{item.vehicle.name}</td>
                            <td>{item.vehicle.brand.name}</td>
                            <td>
                              {dayjs(item.startDate).format("YYYY-MM-DD")}
                            </td>
                            <td>{dayjs(item.endDate).format("YYYY-MM-DD")}</td>
                            <td>
                              {item.confirmed === null ? (
                                <Chip label="En attend" color="primary" />
                              ) : null}
                              {item.confirmed === true ? (
                                <Chip label="Confirmé" color="success" />
                              ) : null}
                              {item.confirmed === false ? (
                                <Chip label="Erreur" color="error" />
                              ) : null}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              ) : (
                <>Actuellement, aucune information n'est disponible</>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
