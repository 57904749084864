import React, { useEffect } from "react";
import Heading from "../../components/UI/Heading.jsx";
import "./Package.css";
import { Col, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { GetPackages } from "../../services/Package";
import Looks6Icon from "@mui/icons-material/Looks6";
import GradeIcon from "@mui/icons-material/Grade";
import UpdateModal from "./UpdateModal.jsx";
import Loader from "../../lib/Loader/Loader.jsx";

export default function ManagePackages() {
  const dispatch = useDispatch();
  const packages = useSelector((state) => state.package.packages);

  const isPending = useSelector((state) => state.package.loading);

  useEffect(() => {
    GetPackages(dispatch);
  }, [dispatch]);

  return (
    <div className="price padding ">
      <div className="container">
        <Heading title="Forfait" />
        {isPending ? (
          <>
            <Row className="m-3">
              {packages.map((item, index) => (
                <Col>
                  <div
                    className="box shadow text_color mt-3"
                    style={{ borderRadius: "55px" }}
                    key={index}>
                    <h3
                      className="text-center font-weight-bold ml-2"
                      style={{ color: "var(--blue_color)" }}>
                      {item.name}
                    </h3>
                    <ul>
                      <li>
                        <label
                          style={{
                            background: "#27ae60af",
                            color: "white",
                          }}>
                          <Looks6Icon />
                        </label>
                        Prix semestriel :{item.priceS}
                      </li>
                      <li>
                        <label
                          style={{
                            background: "#27ae60af",
                            color: "white",
                          }}>
                          <GradeIcon />
                        </label>
                        Prix annuel :{item.priceA}
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <label
                          style={{
                            background: item.parc ? "#27ae60af" : "#dc3545ae",
                            color: item.parc ? "#27ae60" : "#dc3848",
                          }}>
                          {item.parc ? (
                            <i className="fa-solid fa-check"></i>
                          ) : (
                            <i className="fa-solid fa-x"></i>
                          )}
                        </label>
                        <p>Parking Sécurisé</p>
                      </li>

                      <li>
                        <label
                          style={{
                            background: item.reservation
                              ? "#27ae60af"
                              : "#dc3545ae",
                            color: item.reservation ? "#27ae60" : "#dc3848",
                          }}>
                          {item.reservation ? (
                            <i className="fa-solid fa-check"></i>
                          ) : (
                            <i className="fa-solid fa-x"></i>
                          )}
                        </label>
                        <p>Gestion Facile des Réservations</p>
                      </li>
                      <li>
                        <label
                          style={{
                            background: item.notification
                              ? "#27ae60af"
                              : "#dc3545ae",
                            color: item.notification ? "#27ae60" : "#dc3848",
                          }}>
                          {item.notification ? (
                            <i className="fa-solid fa-check"></i>
                          ) : (
                            <i className="fa-solid fa-x"></i>
                          )}
                        </label>
                        <p>Notification Instantanée</p>
                      </li>
                      <li>
                        <label
                          style={{
                            background: item.caisse ? "#27ae60af" : "#dc3545ae",
                            color: item.caisse ? "#27ae60" : "#dc3848",
                          }}>
                          {item.caisse ? (
                            <i className="fa-solid fa-check"></i>
                          ) : (
                            <i className="fa-solid fa-x"></i>
                          )}
                        </label>
                        <p>Caisse Rapide</p>
                      </li>
                      <li>
                        <label
                          style={{
                            background: item.garage ? "#27ae60af" : "#dc3545ae",
                            color: item.garage ? "#27ae60" : "#dc3848",
                          }}>
                          {item.garage ? (
                            <i className="fa-solid fa-check"></i>
                          ) : (
                            <i className="fa-solid fa-x"></i>
                          )}
                        </label>
                        <p>Garage d'Entretien Professionnel</p>
                      </li>
                    </ul>

                    <UpdateModal data={item} />
                  </div>
                </Col>
              ))}
            </Row>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}
