import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { Container } from "reactstrap";
import config from "../../assets/data/config.json";
import Diversity1Icon from '@mui/icons-material/Diversity1';
import WarningIcon from '@mui/icons-material/Warning';
import { useNavigate, useParams } from "react-router-dom";
import Step2 from "./Step2";
import Step4 from "./Step4";
import Step3 from "./Step3";
import Step1 from "./Step1";
import "./MultiStepProgressBar.css";
import { setSnackbar } from "../../features/snackbar";
import { useDispatch } from "react-redux";
import { Role_Manger } from "../../assets/data/Roles";
const steps = ["General", "Abonnement", "Conditions", "Facture"];

export default function AddProject() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { plan } = useParams("plan");
  const [data, setData] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    package: plan,
    roles: Role_Manger,
    period: "6",
    method: "Master Card",
    verified: false,
    registration: "",
    userType: "PARTICULAR_USER",
    cin: "",
    password: "",
    repassword: "",
    taxId: "",
    agencyName: "",
    job: "",
  });

  const handleSubmit = async () => {
    try {
      await axios.post(`${config.url}/addSubscription`, data);
      const action = {
        snackbarOpen: true,
        snackbarType: "success",
        snackbarMessage: "Votre demande a été soumise avec succès!",
      };
      dispatch(setSnackbar(action));
    } catch (error) {
      const action = {
        snackbarOpen: true,
        snackbarType: "Erreur",
        snackbarMessage: "Les données fournies sont incorrect!",
      };
      dispatch(setSnackbar(action));
    }
  };

  //Form
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    if (activeStep === totalSteps() - 1) handleSubmit();
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleNavigate = () => {
    navigate(`/login`);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container className="mb-3 mt-3 shadow p-3 card-rounded-packages">
      <Stepper color="secondary" nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton onClick={handleStep(index)} disabled>
              <span className="text_color">{label}</span>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div className="mb-3 p-3">
        {allStepsCompleted() ? (
          <>
            <div
              style={{ width: "100%", minHeight: "350px", padding: "100px" }}
              className="text_color">
                <Diversity1Icon style={{ fontSize: "150px"}}/>
              <Typography sx={{ mt: 2, mb: 1 }}>
                Toutes les étapes sont désormais complètes.
                <br /> Merci pour votre confiance. Profitez pleinement de votre
                temps avec nous!
              </Typography>
              <Typography sx={{ mt: 0, mb: 1 }} style={{color:"var(--blue_color)"}} >
                <WarningIcon sx={{ mt: 0, mb: 1 }} style={{ fontSize: "30px"}}/>
              Veuillez finaliser l'étape de paiement dans votre profil afin d'activer votre abonnement!
              </Typography>
            </div>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <button className="btn_secondary" onClick={handleNavigate}>
                Se connecter
              </button>
            </Box>
          </>
        ) : (
          <React.Fragment>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <div style={{ width: "100%", minHeight: "450px" }}>
                {activeStep + 1 === 1 ? (
                  <Step1 data={data} setData={(e) => setData(e)} />
                ) : null}
                {activeStep + 1 === 2 ? (
                  <Step2 data={data} setData={(e) => setData(e)} />
                ) : null}
                {activeStep + 1 === 3 ? (
                  <Step3 data={data} setData={(e) => setData(e)} />
                ) : null}
                {activeStep + 1 === 4 ? (
                  <Step4 data={data} setData={(e) => setData(e)} />
                ) : null}
              </div>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              {activeStep === 0 ? null : (
                <button
                  className="btn_secondary"
                  color="inherit"
                  onClick={handleBack}
                  sx={{ mr: 1 }}>
                  RETOUR
                </button>
              )}
              <Box sx={{ flex: "1 1 auto" }} />

              <button
                className="btn_secondary"
                onClick={handleComplete}
                disabled={
                  (!data["verified"] && activeStep === 2) ||
                  (passwordVerification(data) && activeStep === 0)
                }>
                {completedSteps() === totalSteps() - 1
                  ? "Confirmer"
                  : "Suivant"}
              </button>
            </Box>
          </React.Fragment>
        )}
      </div>
    </Container>
  );
}

function passwordVerification(data) {
  if (data["password"] === data["repassword"] && data["password"] !== "") {
    return false;
  }
  return true;
}
