import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
// import axios from "axios";
// import Select from "@mui/material/Select";
// import FormControl from "@mui/material/FormControl";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import { Theme, useTheme } from "@mui/material/styles";
// import config from "../../assets/data/config.json";
// import MenuItem from "@mui/material/MenuItem";
// import InputLabel from "@mui/material/InputLabel";
// import { Button, Modal, Input } from "react-bootstrap";
import { Row, Col } from "reactstrap";
import ClientConditionText from "../../components/UI/ConditionText/ClientConditionText";

export default function Step3({ data, setData }) {
  const handelChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div class="container ">
      <Row>
        <Col>
          <div
            className="shadow p-2 m-3"
            style={{
              overflow: "scroll",
              border: "2px var(--blue_color) solid",
              height: "50vh",
            }}>
            <ClientConditionText />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="text-left ml-3">
          <input
            type="checkbox"
            defaultChecked={data["verified"]}
            name="verified"
            onChange={(e) => handelChange(e)}
          />
          <span
            className="text_color font-weight-bold ml-2"
            style={{ wordWrap: "break-word" }}>
            J'accepte les conditions générales
          </span>
        </Col>
      </Row>
    </div>
  );
}
