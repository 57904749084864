import {
  setTransaction,
  newTransaction,
  setLoading,
  setTransactionError,
  newTransactionError,
} from "../features/transaction";
import axios from "axios";
import config from "../assets/data/config.json";

const axiosInstance = axios.create({
  baseURL: `${config.url}`,
  timeout: 1000,
});

// axiosInstance.interceptors.request.use((conf) => {
//     const [cookies, setCookie] = useCookies(["user"]);

//     conf.headers = { authorization:  `Bearer ${cookies.user}` };
//     return conf;
// });

export const GetTransaction = async (dispatch, cookies) => {
  try {
    if (!cookies) {
      throw new Error("User token not found in cookies");
    }
    const userId = localStorage.getItem("userId");
    if (!userId) {
      throw new Error("User ID not found in localStorage");
    }
    const { data } = await axiosInstance.get(
      `/api/transactionsByUser/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${cookies}`,
        },
      }
    );
    dispatch(setTransaction(data));
  } catch (error) {
    console.error("Error fetching transaction:", error.message);
    dispatch(setTransactionError());
  }
};

export const NewTransaction = async (dispatch, transaction, cookies) => {
  try {
    dispatch(setLoading(false));
    if (!cookies) {
      throw new Error("User token not found in cookies");
    }
    const userId = localStorage.getItem("userId");
    if (!userId) {
      throw new Error("User ID not found in localStorage");
    }
    const { data } = await axiosInstance.post(
      "/api/addTransactionsByUser",
      transaction,
      {
        headers: {
          Authorization: `Bearer ${cookies}`,
        },
      }
    );
    dispatch(newTransaction(data));
    dispatch(setLoading(true));
  } catch {
    dispatch(newTransactionError());
  }
};

// export const EditTransaction = async (dispatch, Category) => {
//     try {
//         const { data } = await axiosInstance.post('/updateCategory/'+Category.get("id"), Category);
//         dispatch(editTransaction(data));
//     } catch {
//         dispatch(editTransactionError());
//     }
// }

// export const DeleteTransaction = async (dispatch, transaction) => {
//     try {
//         // api call
//         await axiosInstance.delete('/transaction/'+transaction.id);
//         dispatch(deleteTransaction(transaction));
//     } catch {
//         dispatch(deleteTransactionError());
//     }
// }
