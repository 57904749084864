import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Row, FormGroup } from "reactstrap";
import NoCrashIcon from '@mui/icons-material/NoCrash';
import { setSnackbar } from "../../features/snackbar";
import { useDispatch } from "react-redux";
import { NewCategory } from "../../services/Category";

function AddModal() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState("");
  const [currentFile, setCurrentFile] = useState("");

  const fileHandler = (e) => {
    setFile(e.target.files[0]);
    setCurrentFile(e.target.files[0]);
    const currentFile = e.target.files[0];
    if (currentFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCurrentFile(reader.result);
      };
      reader.readAsDataURL(currentFile);
    }
  };
  const handleEmptyData=() => {
    setName("");
    setDescription("");
    setCurrentFile("");
    setFile("");
  }

  const handleSubmit = async (e) => {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("image", file.name);
    NewCategory(dispatch, formData);
    const action = {
      snackbarOpen: true,
      snackbarType: "success",
      snackbarMessage:
        "Your engineer application has been successfully submitted!",
    };
    dispatch(setSnackbar(action));
    handleClose();
    handleEmptyData();
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <button className="btn_secondary" onClick={handleShow}>
        Ajouter
      </button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Nouvelle catégorie </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <FormGroup className="booking__form d-inline-block text-left">
              <label className="text_color ">Nom de la catégorie:</label>
              <input
                type="text"
                placeholder="Tapez le nom"
                required
                name="name"
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>
            <FormGroup className="booking__form d-inline-block text-left">
              <label className="text_color ">
                Description de la catégorie:
              </label>
              <input
                type="text"
                placeholder="Tapez la description"
                required
                name="description"
                defaultValue={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormGroup>
            {!currentFile ? (
              <NoCrashIcon
                style={{ fontSize: "200px", color: "var(--blue_color)" }}
                className="centered-element"
              />
            ) : (
              <img
                src={currentFile}
                alt="véhicule"
                className="centered-element"
                style={{ maxWidth: "500px", minWidth: "300px" }}
              />
            )}
            <div className=" d-flex align-items-center gap-5 mb-5 ">
              <input
                type="file"
                onChange={(e) => fileHandler(e)}
                accept="image/*"
                id="file"
                name="image"
                className="btn_secondary input_file w-100"
              />
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn_secondary h-50 p-2"
            onClick={() => handleSubmit()}>
            Eregistrer
          </button>
          <Button
            variant="secondary"
            style={{ borderRadius: "55px" }}
            onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddModal;
