import React from "react";
import { Link } from "react-router-dom";
import CalendarModal from "../../components/UI/CalendarView/CalendarModal";
import COnditionModal from "../../components/UI/ConditionText/ConditionModel";
import config from "../../assets/data/config.json";
import { Row, Col } from "reactstrap";
import Person4Icon from '@mui/icons-material/Person4';
import PersonOffIcon from '@mui/icons-material/PersonOff';

export default function Details({ data, ref, events, disableButtons }) {
  const rating = 4.5;
  const userId = localStorage.getItem("userId");

  return (
    <Row>
      <Col>
        <div className="img_scale">
          <img
            src={config.images + "/" + data?.uriPicture}
            loading="lazy"
            alt=""
            className="w-100"
          />
        </div>
      </Col>

      <Col>
        <div className="car__info text_color">
          <h2 className="section__title">{data?.name}</h2>

          <div className=" d-flex align-items-center gap-5 mb-4 mt-3">
            <h6 className="rent__price fw-bold fs-4">
              {data?.payPerDay}.00 CFA/ Jour
            </h6>

            <span className=" d-flex align-items-center gap-2">
              <span style={{ color: "var(--blue_color)" }}>
                <i class="ri-star-s-fill"></i>
                <i class="ri-star-s-fill"></i>
                <i class="ri-star-s-fill"></i>
                <i class="ri-star-s-fill"></i>
                <i class="ri-star-s-fill"></i>
              </span>
              ({rating} ratings)
            </span>
          </div>
          <p className="section__description text_left">{data?.description}</p>

          <div
            className=" d-flex align-items-center mt-3"
            style={{ columnGap: "4rem" }}>
            <span className=" d-flex align-items-center gap-1 section__description">
              <i
                class="ri-roadster-line"
                style={{ color: "var(--blue_color)" }}></i>{" "}
              {data?.brand.name}
            </span>

            <span className=" d-flex align-items-center gap-1 section__description">
              <i
                class="ri-settings-2-line"
                style={{ color: "var(--blue_color)" }}></i>{" "}
              {data?.category.name}
            </span>

            <span className=" d-flex align-items-center gap-1 section__description">
              <i
                class="ri-timer-flash-line"
                style={{ color: "var(--blue_color)" }}></i>{" "}
              {data.speed}
            </span>
          </div>

          <div
            className=" d-flex align-items-center mt-3"
            style={{ columnGap: "2.8rem" }}>
            <span className=" d-flex align-items-center gap-1 section__description">
              <i
                class="ri-map-pin-line"
                style={{ color: "var(--blue_color)" }}></i>{" "}
              gps
            </span>

            <span className=" d-flex align-items-center gap-1 section__description">
              {data?.driver==="Sans conducteur"? (<PersonOffIcon style={{ color: "var(--blue_color)" }}/>):(<Person4Icon style={{ color: "var(--blue_color)" }}/>)}
              {data?.driver}
            </span>

            <span className=" d-flex align-items-center gap-1 section__description">
              <i
                class="ri-building-2-line"
                style={{ color: "var(--blue_color)" }}></i>{" "}
              {data?.brand.name}
            </span>
          </div>

          <div
            className=" d-flex align-items-center mt-3"
            style={{ columnGap: "2.8rem" }}>
            {disableButtons ? null : (
              <>
                <span className=" d-flex align-items-center gap-1 section__description">
                  <CalendarModal ref={ref} events={events} />
                </span>

                <span className=" d-flex align-items-center gap-1 section__description">
                  <COnditionModal />
                </span>

                <span className=" d-flex align-items-center gap-1 section__description">
                  {userId === null ? (
                    <Link to={"/register"}>
                      <button className="btn_secondary">Réserver</button>
                    </Link>
                  ) : null}
                </span>
              </>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
}
