import { createSlice, createAction } from "@reduxjs/toolkit";

export const setBrandError = createAction("setBrandError");
export const newBrandError = createAction("newBrandError");
export const editBrandError = createAction("editBrandError");
export const deleteBrandError = createAction("deleteBrandError");

export const brandSlice = createSlice({
  name: "brand",
  initialState: {
    brands: [],
    loading: false,
  },
  reducers: {
    setBrands: (state, action) => {
      return { ...state, brands: action.payload, loading: true };
    },
    newBrand: (state, action) => {
      return { ...state, brands: [...state.brands, action.payload] };
    },
    editBrand: (state, action) => {
      const brands = state.brands.map((brand) => {
        if (brand.id === action.payload.id) {
          brand = action.payload;
        }
        return brand;
      });
      return { ...state, brands: [...brands] };
    },
    deleteBrand: (state, action) => {
      const brands = state.brands.filter(
        (brand) => brand.id !== action.payload.id
      );
      return { ...state, brands: [...brands] };
    },
    setLoading: (state, action) => {
      return { ...state, loading: action.payload };
    },
  },
});

export const { setBrands, newBrand, editBrand, deleteBrand, setLoading } =
  brandSlice.actions;

export default brandSlice.reducer;
