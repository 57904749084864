import React, { useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "./theme";
// import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import CoPresentIcon from "@mui/icons-material/CoPresent";
// import PersonAddIcon from "@mui/icons-material/PersonAdd";
import HeaderDashboard from "./components/HeaderDashboard";
import LineChart from "./components/LineChart";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
// import GeographyChart from "./components/GeographyChart";
import PieChart from "./components/PieChart";
import BarChart from "./components/BarChart";
import StatBox from "./components/StatBox";
import ProgressCircle from "./components/ProgressCircle";
import MainChart from "./components/MainChart";
import { useSelector, useDispatch } from "react-redux";
import { GetTransaction } from "../../services/Transaction";
import dayjs from "dayjs";
import useFetchManager from "../../hooks/useFetchManager";
import { useCookies } from "react-cookie";


const TruncateText = ({ text }) => {
  if (!text) {
    return null;
  }

  const maxLength = 30;

  if (text.length <= maxLength) {
    return <span>{text}</span>;
  }

  const truncatedText = `${text.slice(0, maxLength)}...`;

  return <span>{truncatedText}</span>;
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const transactions = useSelector((state) => state.transaction.transactions);
  const [cookies] = useCookies(["user"]);

  useEffect(() => {
    GetTransaction(dispatch, cookies["user"]);
  }, [dispatch, cookies]);
  const { data } = useFetchManager(
    "/api/getStatBoxs/" + localStorage.getItem("userId")
  );

  return (
    <Box m="40px" sx={{ fontFamily: "montserrat_regular" }}>
      {/* HEADER */}
      <PieChart />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <HeaderDashboard subtitle="Tableau de Bord de Location de Véhicules" />

        <Box>
          {/* <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button> */}
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px">
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          borderRadius={"25px"}
          justifyContent="center">
          <StatBox
            title={data?.numberOfVehicles}
            subtitle="Véhicules"
            progress="0.75"
            increase="+14%"
            icon={
              <DirectionsCarIcon
                sx={{ color: colors.greenAccent[600], fontSize: "56px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          borderRadius={"25px"}
          justifyContent="center">
          <StatBox
            title={data?.numberOfReservations}
            subtitle="Réservations"
            progress="0.50"
            increase="+21%"
            icon={
              <EventAvailableIcon
                sx={{ color: colors.greenAccent[600], fontSize: "56px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          borderRadius={"25px"}
          justifyContent="center">
          <StatBox
            title={data?.numberOfClients}
            subtitle="Clients"
            progress="0.30"
            increase="+5%"
            icon={
              <CoPresentIcon
                sx={{ color: colors.greenAccent[600], fontSize: "56px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          borderRadius={"25px"}
          justifyContent="center">
          <StatBox
            title={data?.numberOfTransactions}
            subtitle="Transactions"
            progress="0.80"
            increase="+43%"
            icon={
              <PointOfSaleIcon
                sx={{ color: colors.greenAccent[600], fontSize: "56px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          borderRadius={"25px"}>
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center">
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}>
                Total des Crédits
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}>
                CFA{data?.credit ? data.credit : 0}
              </Typography>
            </Box>
            <Box>
              {/* <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton> */}
            </Box>
          </Box>
          <Box height="230px" m="-20px 0 0 0">
            <BarChart isDashboard={true} />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          borderRadius={"25px"}
          overflow="auto">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`3px solid ${colors.primary[900]}`}
            colors={colors.grey[100]}
            p="15px">
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Transactions Récentes
            </Typography>
          </Box>
          {transactions.slice(0, 6).map((transaction, i) => (
            <Box
              key={`${transaction.id}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`1px solid ${colors.primary[900]}`}
              p="15px">
              <Box>
                <Typography
                  color={
                    transaction.type === "credit"
                      ? colors.greenAccent[500]
                      : "#FF8C8B"
                  }
                  variant="h5"
                  fontWeight="600">
                  {transaction.type}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {dayjs(transaction.date).format("DD-MM-YYYY")}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>
              <TruncateText text={transaction.note} />
              </Box>
              <Box
                backgroundColor={
                  transaction.type === "credit"
                    ? colors.greenAccent[500]
                    : "#FF8C8B"
                }
                p="5px 10px"
                borderRadius="4px">
                CFA{transaction.cost}
              </Box>
            </Box>
          ))}
        </Box>

        {/* ROW 3 */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          borderRadius={"25px"}
          p="30px">
          <Typography variant="h5" fontWeight="600">
            Caisse
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px">
            <ProgressCircle
              size="125"
              revenueGenerated={data?.revenueGenerated}
              credit={data?.credit}
            />
            <Typography
              variant="h5"
              color={colors.greenAccent[500]}
              sx={{ mt: "15px" }}>
              CFA{data?.revenueGenerated} Revenu Généré
            </Typography>
            <Typography>
              Comprend diverses dépenses et coûts supplémentaires
            </Typography>
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          borderRadius={"25px"}>
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "20px 20px 10px 20px" }}>
            Réservations
          </Typography>
          <Box height="280px" mt="-20px">
            <LineChart isDashboard={true} />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          borderRadius={"25px"}
          padding="30px">
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ marginBottom: "15px" }}>
            Répartition des véhicules
          </Typography>
          <Box height="240px">
            <MainChart isDashboard={true} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
