import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "../../assets/styles/CarDetails.css";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../../components/Helmet/Helmet";
import { useParams } from "react-router-dom";
import BookingForm from "../../components/UI/BookingForm";
import Loader from "../../lib/Loader/Loader";
import Details from "./Details";
import config from "../../assets/data/config.json";
import useFetch from "../../hooks/useFetch";

const CarDetails = () => {
  const { slug } = useParams();
  const [data, setData] = useState({
    automatic: "",
    carName: "",
    model: "",
    brand: "",
    image: "",
    payPerDay: "",
    driver:"",
    speed: "",
    capacity: "",
    description: "",
    rents: "",
    events: "",
    _id: "",
  });
  // const [modal, setModal] = useState(false);
  // const toggle = () => setModal(!modal);

  const events = useFetch("/reservationByVehicle/" + slug);
  const [loading, setLoading] = useState(false);

  const calendarRef = useRef(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(`${config.url}/vehicle/${slug}`);
        setData(result.data);
        setLoading(true);
      } catch (err) {
        console.log("Error!");
      }
    };
    fetchData();
  }, [slug]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Helmet title={data["name"]}>
      <section className="mb-3">
        <Container>
          <Row>
            {loading ? (
              <div style={{ marginTop: "25px", marginBottom: "50px" }}>
                <Details
                  ref={calendarRef}
                  events={events}
                  data={data}
                  disableButtons={false}
                />
                <Col>
                  <BookingForm
                    carName={data["carName"]}
                    brand={data["brand"]}
                    prix={data["payPerDay"]}
                    id={data["id"]}
                  />
                </Col>
              </div>
            ) : (
              <Loader />
            )}
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default CarDetails;
