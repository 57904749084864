import { useState } from "react";
// import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Snackbar from "@mui/material/Snackbar";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import LogoutIcon from "@mui/icons-material/Logout";
import Alert from "@mui/material/Alert";
import dayjs from "dayjs";

function ZoomModal({ show, setShow, item }) {
  // const [response, setResponse] = useState(false);
  const response = false;

  // const handleDelete = async () => {
  //   setShow(false);
  //   const response = await axios.delete(`${config.url}post/${id}`).then((res) => {
  //     getPosts();
  //     setResponse(true)
  //     handleOpenSnakBar()
  //   }).catch(function (error) {
  //   setResponse(false);
  //   handleOpenSnakBar();
  //   });
  // };

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const [open, setOpen] = useState(false);
  const handleCloseSnakBar = () => setOpen(false);
  // const handleOpenSnakBar = () => setOpen(true);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Transaction </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text_color" width="20%">
            ID: {item?.id}
          </div>
          <div className="text_color" width="20%">
            Type de transaction: {item?.type}
            {item?.type === "credit" ? (
              <SaveAltIcon color="success" />
            ) : (
              <LogoutIcon color="error" />
            )}
          </div>
          <div className="text_color" width="20%">
            Date: {dayjs(item?.date).format("YYYY-MM-DD")}
          </div>
          <div className="text_color" width="20%">
            Montant: {item?.cost}
          </div>
          <div className="text_color" width="20%">
            Autre information: {item?.note}{" "}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleCloseSnakBar}>
        {response ? (
          <Alert
            onClose={handleCloseSnakBar}
            severity="success"
            sx={{ width: "100%" }}>
            L'opération s'est terminée avec succès
          </Alert>
        ) : (
          <Alert
            severity="error"
            onClose={handleCloseSnakBar}
            sx={{ width: "100%" }}>
            Opération échouée !
          </Alert>
        )}
      </Snackbar>
    </>
  );
}

export default ZoomModal;
