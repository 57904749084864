import React, { useEffect } from "react";
import Heading from "../../components/UI/Heading";
import FeaturedCard from "../../components/UI/featured/FeaturedCard";

const Vehicules = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="featured background">
      <div className="container">
        <Heading
          title="Trouvez le véhicule parfait à posséder avec nous."
          subtitle="Une vaste gamme vous attend, pour répondre à toutes vos envies de conduite"
        />
        <FeaturedCard fullData={true} />
      </div>
    </section>
  );
};

export default Vehicules;
