import {
  setPartners,
  newPartner,
  editPartner,
  deletePartner,
  setLoading,
} from "../features/partner";
import { setSnackbar } from "../features/snackbar";
import HttpForm from "../lib/http/httpForm";

const USER_ID_KEY = "userId";

const getUserId = () => {
  const userId = localStorage.getItem(USER_ID_KEY);
  if (!userId) {
    throw new Error("User ID not found in localStorage");
  }
  return userId;
};

const handleApiError = (dispatch, errorType, errorMessage) => {
  const action = {
    snackbarOpen: true,
    snackbarType: errorType,
    snackbarMessage: errorMessage,
  };
  dispatch(setSnackbar(action));
};

export const GetPartners = async (dispatch) => {
  try {
    const { data } = await HttpForm.get("/partners");
    dispatch(setPartners(data));
  } catch {
    handleApiError(
      dispatch,
      "error",
      "Erreur lors du chargement des partenaires"
    );
  }
};

export const NewPartner = async (dispatch, partner) => {
  try {
    dispatch(setLoading(false));
    const userId = getUserId();
    const { data } = await HttpForm.post("/api/addPartner/" + userId, partner);
    dispatch(newPartner(data));
    handleApiError(
      dispatch,
      "success",
      "Le partenaire a été soumise avec succès!"
    );
    dispatch(setLoading(true));
  } catch {
    handleApiError(
      dispatch,
      "error",
      "Erreur lors de la soumission de le partenaire!"
    );
  }
};

export const EditPartner = async (dispatch, partner) => {
  try {
    dispatch(setLoading(false));
    const userId = getUserId();
    const { data } = await HttpForm.post(
      "/api/updatePartner/" + userId + "/" + partner.get("id"),
      partner
    );
    dispatch(editPartner(data));
    handleApiError(
      dispatch,
      "success",
      "La modification a été soumise avec succès!"
    );
    dispatch(setLoading(true));
  } catch {
    handleApiError(
      dispatch,
      "error",
      "Erreur lors de la soumission de la modification!"
    );
  }
};

export const DeletePartner = async (dispatch, partner) => {
  try {
    dispatch(setLoading(false));
    const userId = getUserId();
    await HttpForm.delete("/api/deletePartner/" + userId + "/" + partner.id);
    dispatch(deletePartner(partner));
    handleApiError(
      dispatch,
      "success",
      "La suppression a été soumise avec succès!"
    );
    dispatch(setLoading(true));
  } catch {
    handleApiError(
      dispatch,
      "error",
      "Erreur lors de la soumission de la suppression!"
    );
  }
};
