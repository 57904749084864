import React from "react";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import config from "../../assets/data/config.json";
import { Row, Col, FormGroup } from "reactstrap";
import PhoneInput from 'react-phone-number-input';
// import { Button, Modal, Input } from "react-bootstrap";
// import axios from "axios";
// import Select, { SelectChangeEvent } from "@mui/material/Select";

export default function Step1({ data, setData }) {
  const handelChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangePhone=(e)=>{
    setData({
      ...data,
      phone: e,
    });
  }

  return (
    <div class="container ">
      <Row>
        <Col className="mt-5">
          <div className="booking-info mt-5">
            <h5 className="mb-4 fw-bold ">Informations Personnelle</h5>
            <FormGroup className="booking__form d-inline-block  text-left mr-1">
              <label className="text_color">Nom :</label>
              <input
                type="text"
                onChange={(e) => handelChange(e)}
                placeholder="taper votre nom"
                required
                name="firstName"
                defaultValue={data["firstName"]}
                className="input_placeholder"
              />
            </FormGroup>
            <FormGroup className="booking__form d-inline-block ms-1 mb-4 text-left">
              <label className="text_color">Prénom :</label>
              <input
                type="text"
                onChange={(e) => handelChange(e)}
                placeholder=" taper votre prénom"
                required
                name="lastName"
                defaultValue={data["lastName"]}
                className="input_placeholder"
              />
            </FormGroup>
            <FormGroup className="booking__form d-inline-block text-left mr-1">
              <label className="text_color">E-mail :</label>
              <input
                type="email"
                onChange={(e) => handelChange(e)}
                placeholder="taper votre adresse E-mail"
                required
                name="email"
                defaultValue={data["email"]}
              />
            </FormGroup>
            <FormGroup className="booking__form d-inline-block ms-1 mb-4 text-left">
              <label className="text_color">Numéro de téléphone :</label>
              <PhoneInput onChange={(e)=>handleChangePhone(e)}
                placeholder="taper votre Numéro de téléphone"
                required
                name="phone"
                defaultValue={data["phone"]}
              />
            </FormGroup>
            <FormGroup className="booking__form d-inline-block text-left mr-1">
              <label className="text_color"> Mot de passe</label>
              <input
                type="password"
                placeholder="taper votre mot de passe"
                onChange={(e) => handelChange(e)}
                className="input"
                id="password"
                name="password"
                required
              />
            </FormGroup>
            <FormGroup className="booking__form d-inline-block text-left ">
              <label className="text_color">Retaper mot de passe</label>
              <input
                type="password"
                name="repassword"
                placeholder="retaper votre mot de passe"
                onChange={(e) => handelChange(e)}
                className="input"
                id="rpassword"
                required
              />
            </FormGroup>
          </div>
        </Col>
      </Row>
    </div>
  );
}
