import * as React from "react";
import { useEffect, useState } from "react";
import TablePagination from "@mui/material/TablePagination";
// import axios from "axios";
import { Search } from "@mui/icons-material";
import { Box, IconButton, InputBase } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ProtectedRoute from "./ProtectedRoute";
import DeleteModal from "./DeleteModal";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import ZoomModal from "./ZoomModal";
import Loader from "../../lib/Loader/Loader";
import useFetchManager from "../../hooks/useFetchManager";
import { useSelector } from "react-redux";
import Http from "./../../lib/http/http";

export default function ParcTable() {
  const user = useSelector((state) => state.user.value);
  const { data, isPending } = useFetchManager(`/api/vehicles/${user?.id}`);
  const [cars,setCars] = useState(data);

  const [isLoded, setIsLoded] = React.useState(10);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [minIndex, setMinIndex] = React.useState(0);
  const maxIndex = minIndex + rowsPerPage;
  const [totalRows, setTotalRows] = React.useState(0);

  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setMinIndex(newPage * rowsPerPage);
    getChangeCars();
  };

  //api/min-1/max

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  // const getNumberOfCars = React.useCallback(async () => {
  
  // }, [user?.id]);

  const getChangeCars = async () => {
    const res = await Http.get(`/api/${user?.id}/min=${minIndex}/max=${maxIndex}`);
    setCars(res.data);
    const resNum = await Http.get(`/api/${user?.id}/numberOfVehicleByManager`); 
    setTotalRows(resNum.data);
    setIsLoded(true);
  };
  // useEffect(() => {
  //   getNumberOfCars();
  // }, [getNumberOfCars,user?.id]);

  useEffect(() => {
    setCars(data);
    
  }, [data,rowsPerPage, minIndex]);

  const [show, setShow] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0);
  const handleShow = (id) => {
    setIdToDelete(id);
    setShow(true);
  };

  const handleSearch = async (e) => {
    if (e.target.value) {
      try{
        const res= await Http.get(`/api/${user?.id}/min=${minIndex}/max=${maxIndex}/${e.target.name}=${e.target.value}`);
        setCars(res.data);
        setIsLoded(true);
      }catch(e){
          console.log(e);
      }
    } else {
      setCars(data);
    }
  };

  return (
    <ProtectedRoute>
      <DeleteModal
        show={show}
        setShow={setShow}
        id={idToDelete}
        refrechFunction={getChangeCars}
      />
      <div className="container_X text_center text_color shadow ">
        <div>
          <div style={{ overflow: "auto", margin: "20px" }}>
            <p
              className="big-title"
              style={{ float: "left", fontSize: "35px" }}>
              Parc
            </p>
            <p style={{ float: "right", color: "#004a9e" }}></p>
          </div>
        </div>
        <div
          style={{
            background: "var(--body_background)",
            margin: "20px 20px 0px 20px",
            borderBlockEnd: "1px #e4e4e4 solid",
          }}>
          <table style={{ margin: "0 auto" }}>
            <thead className="Thead">
              <tr>
                <td width="20%">ID</td>
                <td width="20%">Nom</td>
                <td width="20%">Type</td>
                <td width="20%">Matricule</td>
                <td width="20%">Action</td>
              </tr>
              <tr>
                <td className="td_X">
                  <Box
                    borderRadius="9px"
                    gap="3rem"
                    className="searchBar"
                    p="0.1rem 1.5rem">
                    <InputBase
                      placeholder="Recherche..."
                      className="col-11"
                      onChange={(e) => handleSearch(e)}
                      name="id"
                    />
                    <IconButton className="col-1">
                      <Search />
                    </IconButton>
                  </Box>
                </td>
                <td className="td_X">
                  <Box
                    borderRadius="9px"
                    gap="3rem"
                    className="searchBar"
                    p="0.1rem 1.5rem">
                    <InputBase
                      placeholder="Recherche..."
                      className="col-11"
                      onChange={(e) => handleSearch(e)}
                      name="name"
                    />
                    <IconButton className="col-1">
                      <Search />
                    </IconButton>
                  </Box>
                </td>
                <td className="td_X">
                  <Box
                    borderRadius="9px"
                    gap="3rem"
                    className="searchBar"
                    p="0.1rem 1.5rem">
                    <InputBase placeholder="Recherche..." className="col-11" />
                    <IconButton className="col-1">
                      <Search />
                    </IconButton>
                  </Box>
                </td>
                <td className="td_X">
                  <Box
                    borderRadius="9px"
                    gap="3rem"
                    className="searchBar"
                    p="0.1rem 1.5rem">
                    <InputBase placeholder="Recherche..." onChange={(e) => handleSearch(e)}
                      name="registration"className="col-11" />
                    <IconButton className="col-1">
                      <Search />
                    </IconButton>
                  </Box>
                </td>
                <td className="td_X">
                  <Link to="/newcar">
                    <button className="btn_secondary">Ajouter</button>
                  </Link>
                </td>
              </tr>
            </thead>

            {!isPending ? (
              <tbody>
                {cars?.map((item, index) => (
                  <tr key={index}>
                    <td className="td_X">{item.id}</td>
                    <td className="td_X">{item.name}</td>
                    <td className="td_X">{item.category.name}</td>
                    <td className="td_X">{item.registration}</td>
                    <td className="td_X">
                      <ZoomModal data={item} />
                      <Tooltip title="Modifier" placement="bottom">
                        <Link to={"/admin/cars/" + item.id}>
                          <IconButton aria-label="edit" color="success">
                            <EditIcon />
                          </IconButton>
                        </Link>
                      </Tooltip>
                      <Tooltip title="Supprimer" placement="bottom">
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleShow(item.id)}
                          color="error">
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <></>
            )}
          </table>
          {!isPending ? <></> : <Loader />}
          {!isLoded ? <></> : <></>}
          {cars?.length === 0 ? (
            <div style={{ height: "100px", padding: "70px 0" }}>
              Actuellement, aucune information n'est disponible
            </div>
          ) : null}
        </div>
        <div
          style={{
            background: "var(--body_background)",
            margin: "0px 0px 0px 0px",
          }}>
          <TablePagination
            className="text_color"
            labelRowsPerPage="Lignes par page :"
            component="div"
            localisation="fr"
            count={totalRows}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </ProtectedRoute>
  );
}
