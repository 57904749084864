import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import IconButton from "@mui/material/IconButton";
import Details from "../CarDetails/Details";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import Tooltip from "@mui/material/Tooltip";

function ZoomModal({ data }) {
  const [show, setShow] = useState(false);
  const keys = ["carName", "image", "brand"];
  // const keys=["type"];
  const dataVerification = (d) => {
    for (const key in d) {
      if (d.hasOwnProperty(key) && d[key] === "" && !(key in keys)) {
        return false;
      }
    }
    return true;
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Tooltip title="détails" placement="bottom">
        <IconButton
          aria-label="zoom"
          color="primary"
          onClick={handleShow}
          name="zoom">
          <ZoomInIcon />
        </IconButton>
      </Tooltip>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Details </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {dataVerification(data) ? (
            <Details data={data} disableButtons={true} />
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ borderRadius: "55px" }}
            onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ZoomModal;
