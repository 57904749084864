import React from "react";
import { Provider } from "react-redux";
import { Store } from "../Store.js";
import Navbar from "../components/Navbar/Navbar.js";
import Footer from "../components/Footer/Footer.jsx";
import Routers from "../router/Router.js";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

let persistor = persistStore(Store);

const Layout = () => {
  // const [showSideAndSideBar, setShowSideAndSideBar] = useState(true);
  // const [showSideAndNavBar, setShowSideAndNavBar] = useState(true);
  const showSideAndNavBar = true;
  return (
    <Provider store={Store}>
      <PersistGate persistor={persistor}>
        {showSideAndNavBar && <Navbar />}
        <div>
          <Routers />
        </div>
        <Footer />
      </PersistGate>
    </Provider>
  );
};

export default Layout;
