import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import { Form, FormGroup, Card, CardBody, CardTitle } from "reactstrap";
import Alert from "@mui/material/Alert";
import { Container, Row, Col } from "reactstrap";
import config from "../../assets/data/config.json";
import { useCookies } from "react-cookie";
import image from "../../assets/images/blue-envelope.png";
import Loader from "../../lib/Loader/Loader";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState();
  // const [password, setPassword] = useState();
  const [cookies] = useCookies(["user"]);
  const [response, setResponse] = useState(false);
  // const [loading, setLoading] = useState(false);
  const loading = false;

  //   const validate = (email) => {
  //     const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

  //     return expression.test(String(email).toLowerCase())
  // }

  useEffect(() => {
    if (cookies.user) {
      navigate("/");
    }
  });

  const [open, setOpen] = useState(false);
  const handleCloseSnakBar = () => setOpen(false);
  const handleOpenSnakBar = () => setOpen(true);
  const vertical = "top";
  const horizontal = "center";

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!username) {
      handleOpenSnakBar();
      return 0;
    }
    // if (!validate(username)) {
    //   handleOpenSnakBar();
    //     return 0;
    // }
    await axios
      .post(`${config.url}/reset-password`, {
        email: username,
      })
      .then((res) => {
        console.log(res);
        setResponse(true);
        handleOpenSnakBar();
        setTimeout(() => {
          navigate("/login");
        }, 10000);
      })
      .catch((error) => {
        handleOpenSnakBar();
        setResponse(false);
        console.log(error);
      });
  };

  return (
    <div className="login_container">
      <Container className="d-flex min-vh-100">
        <Row className="m-auto align-self-center ">
          <Col lg="15" className="mb-5 text-center">
            <Card
              className="body_color"
              style={{ border: "transparent", borderRadius: "75px" }}>
              {loading ? (
                <Loader />
              ) : (
                <CardBody
                  className="body_color"
                  style={{ border: "transparent", borderRadius: "75px" }}>
                  <CardTitle className="body_color"></CardTitle>
                  <Row>
                    <Col>
                      <Form
                        className="form body_color"
                        onSubmit={submitHandler}>
                        <h3 className="form-title text_color mb-3">
                          Trouver votre compte
                        </h3>
                        <FormGroup className="contact__form mt-3">
                          <div>
                            <label htmlFor="username" className="text_color">
                              Saisissez votre adresse e-mail
                            </label>
                          </div>
                          <input
                            type="text"
                            onChange={(e) => setUsername(e.target.value)}
                            className="input"
                            style={{ width: "75%" }}
                            id="username"
                            required
                          />
                        </FormGroup>
                        <FormGroup className="contact__form">
                          <button className=" btn_secondary mt-3">
                            Trouver
                          </button>
                        </FormGroup>
                        <FormGroup>
                          <p className="text_color">
                            Vous n'avez pas de compte ?{" "}
                            <Link
                              to="/register"
                              className="form-link"
                              style={{
                                textDecoration: "none",
                                color: "var(--blue_color)",
                              }}>
                              S'inscrire
                            </Link>
                          </p>
                        </FormGroup>
                      </Form>
                    </Col>
                    <Col xl="6">
                      <div
                        className="contact__info mt-3 text_color"
                        style={{ marginRight: "10px" }}>
                        <img
                          src={image}
                          loading="lazy"
                          className="w-100 h-100 p-3 mb-3 "
                          style={{ borderRadius: "15%" }}
                          alt={"contact_nous"}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>

      <Snackbar
        open={open}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={6000}
        onClose={handleCloseSnakBar}>
        {response ? (
          <Alert
            onClose={handleCloseSnakBar}
            severity="success"
            sx={{ width: "100%" }}>
            Veuillez consulter votre messagerie électronique afin de procéder à
            la réinitialisation de votre mot de passe
          </Alert>
        ) : (
          <Alert
            severity="error"
            onClose={handleCloseSnakBar}
            sx={{ width: "100%" }}>
            L'addresse mail est incorrect. Réessayez pour le réinitialiser
          </Alert>
        )}
      </Snackbar>
    </div>
  );
};

export default ForgetPassword;
