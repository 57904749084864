import React from "react";
import WifiTetheringErrorIcon from '@mui/icons-material/WifiTetheringError';

const NotFound = () => {
  return (
    <div style={{marginTop: "100px",marginBottom:"100px" }}>
      <WifiTetheringErrorIcon style={{color: "var(--blue_color)",fontSize:"150px"}} />
    <div className="text_color" style={{ height: "100px",marginTop:"25px"}}>
      Cette page n'est pas disponible pour le moment
    </div>
    </div>
  );
};

export default NotFound;
