import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Row, FormGroup } from "reactstrap";
import GradeIcon from '@mui/icons-material/Grade';
import config from "../../assets/data/config.json";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import { EditPartner } from "../../services/Partner";
import { setSnackbar } from "../../features/snackbar";
import CustomizedSnackbar from "../../services/snackbar";

function UpdateModal({ data }) {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [name, setName] = useState(data.name);

  const [file, setFile] = useState("");
  const [currentFile, setCurrentFile] = useState(
    data ? config.images_partner + "/" + data.image : null
  );
  const fileHandler = (e) => {
    setFile(e.target.files[0]);
    setCurrentFile(e.target.files[0]);
    const currentFile = e.target.files[0];
    if (currentFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCurrentFile(reader.result);
      };
      reader.readAsDataURL(currentFile);
    }
  };

  const handleSubmit = async (e) => {
    let formData = new FormData();
    formData.append("id", data?.id);
    formData.append("file", file);
    formData.append("name", name);
    formData.append("image", file.name);
    EditPartner(dispatch, formData);
    const action = {
      snackbarOpen: true,
      snackbarType: "success",
      snackbarMessage:
        "Your engineer application has been successfully submitted!",
    };
    dispatch(setSnackbar(action));
    handleClose();
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <CustomizedSnackbar />
      <IconButton aria-label="edit" onClick={handleShow} color="success">
        <EditIcon />
      </IconButton>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Partenaire </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <FormGroup className="booking__form d-inline-block text-left">
              <label className="text_color ">Nom de partenaire :</label>
              <input
                type="text"
                placeholder="Tapez le nom"
                required
                name="name"
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>
            { data.image===null ? (
              <GradeIcon
                style={{ fontSize: "200px", color: "var(--blue_color)" }}
                className="centered-element"
              />
            ) : (
              <img
                src={currentFile}
                alt="ville"
                className="centered-element"
                style={{ maxWidth: "500px", minWidth: "300px"}}
              />
            )}
            <div className=" d-flex align-items-center gap-5 mb-5 ">
              <input
                type="file"
                onChange={(e) => fileHandler(e)}
                accept="image/*"
                id="file"
                name="image"
                className="btn_secondary input_file w-100"
              />
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn_secondary h-50 p-2 "
            onClick={() => handleSubmit()}>
            Eregistrer
          </button>
          <Button
            variant="secondary"
            style={{ borderRadius: "55px" }}
            onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateModal;
