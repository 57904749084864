import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import "./hero.css";
import { useDispatch, useSelector } from "react-redux";
import { GetCities } from "../../../services/City";
import { GetCategory } from "../../../services/Category";
import TitleHero from "./TitleHero";

function getTodayDate(x) {
  const today = new Date();
  const futureDate = new Date(today);
  futureDate.setDate(today.getDate() + x);

  const year = futureDate.getFullYear();
  let month = (futureDate.getMonth() + 1).toString();
  let day = futureDate.getDate().toString();
  month = month.length === 1 ? `0${month}` : month;
  day = day.length === 1 ? `0${day}` : day;

  return `${year}-${month}-${day}`;
}

const Hero = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(getTodayDate(0));
  const [endDate, setEndDate] = useState(getTodayDate(7));
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedTypeVehicle, setSelectedTypeVehicle] = useState("");

  const cities = useSelector((state) => state.city.cities);
  const categorys = useSelector((state) => state.category.Category);

  useEffect(() => {
    GetCities(dispatch);
    GetCategory(dispatch);
  }, [dispatch]);

  const navigate = useNavigate();

  const submitHandler = async (e) => {
    let uri = "";
    if (selectedAddress) {
      uri = uri + selectedAddress;
    } else {
      uri = uri + "ALL";
    }
    if (selectedTypeVehicle) {
      uri = uri + "/" + selectedTypeVehicle;
    } else {
      uri = uri + "/ALL";
    }
    if (startDate) {
      uri = uri + "/" + startDate;
    }
    if (endDate) {
      uri = uri + "/" + endDate;
    }
    navigate("/cars/" + uri);
  };
  const handelChangeAddress = (e) => {
    setSelectedAddress(e.target.value);
  };

  const handelChangeTypeVehicle = (e) => {
    setSelectedTypeVehicle(e.target.value);
  };

  const handelChangeStartDate = (e) => {
    setStartDate(e.target.value);
  };
  const handelChangeEndDate = (e) => {
    setEndDate(e.target.value);
  };
  return (
    <section className="hero">
      <div className="container">
        <TitleHero
          title="LOUER EN TOUTE SIMPLICITÉ, CONDUIRE EN TOUTE CONFIANCE"
          subtitle="Recherchez, comparez et faites de vraies économies !"
        />

        <div className="flex body_color">
          <Row xl={5} xs={1}>
            <Col>
              <span className="text_color">Lieu de prise en charge</span>
              <select
                className="select_input"
                value={selectedAddress}
                onChange={(e) => handelChangeAddress(e)}>
                <option value={""} selected={true}>
                  Choisir la region
                </option>
                {cities.map((item, index) => (
                  <option value={item.name} key={index}>
                    {item.name}
                  </option>
                ))}
              </select>
            </Col>
            <Col>
              <span className="text_color">Type de vehicule</span>
              <select
                className="select_input"
                value={selectedTypeVehicle}
                onChange={(e) => handelChangeTypeVehicle(e)}>
                <option value={""} selected={true}>
                  Choisir le type
                </option>
                {categorys.map((item, index) => (
                  <option value={item.name} key={index}>
                    {item.name}
                  </option>
                ))}
              </select>
            </Col>
            <Col>
              <span className="text_color">Date de prise en charge</span>
              <input
                className="select_input"
                type="date"
                placeholder="Price Range"
                defaultValue={startDate}
                onChange={(e) => handelChangeStartDate(e)}
              />
            </Col>
            <Col>
              <span className="text_color">Date de restitution</span>
              <input
                className="select_input"
                type="date"
                placeholder="Price Range"
                defaultValue={endDate}
                onChange={(e) => handelChangeEndDate(e)}
              />
            </Col>
            <Col>
              <span className="text_color">Rechercher</span>
              <br />

              <button
                onClick={(e) => submitHandler(e)}
                className="btn_secondary mb-1">
                <SearchIcon />
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default Hero;
