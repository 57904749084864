import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import FormGroup from "react-bootstrap/esm/FormGroup";
import { useDispatch, useSelector } from "react-redux";
import { GetCities } from "../../../../services/City";
import { useParams } from "react-router-dom";
import Http from "../../../../lib/http/http";
import dayjs from "dayjs";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Tooltip from "@mui/material/Tooltip";

function AddEmplacementModal({refreshFunction}) {
  const toDay = dayjs(new Date()).format("YYYY-MM-DD");
  // const [response, setResponse] = useState(false);
  const response = false;
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { slug } = useParams();
  const cities = useSelector((state) => state.city.cities);
  useEffect(() => {
    GetCities(dispatch);
  }, [dispatch]);
  const [data, setData] = useState({
    region: 0,
    vehicle: slug,
    date: toDay,
  });
  const handelChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  const handleAdd = async () => {
    if (parseInt(data["region"]) !== 0) {
      setShow(false);
      const url = "/addRegionAvailability";
      await Http.post(url, data);
      refreshFunction();
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [open, setOpen] = useState(false);
  const handleCloseSnakBar = () => setOpen(false);
  // const handleOpenSnakBar = () => setOpen(true);

  return (
    <>
            <Tooltip title="Ajouter Zone" placement="bottom">
      <button className="btn_modal" onClick={handleShow}>
      <AddCircleIcon/>
      </button>
      </Tooltip>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter zone </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup className="booking__form d-inline-block text-left ">
            <label className="text_color">zone de disponibilité:</label>
            <select
              className="select_input"
              onChange={(e) => handelChange(e)}
              name="region">
              <option value={0}>Choisir le type du document</option>
              {cities.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn_modal" onClick={handleAdd}>
            Ajouter
          </button>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleCloseSnakBar}>
        {response ? (
          <Alert
            onClose={handleCloseSnakBar}
            severity="success"
            sx={{ width: "100%" }}>
            L'opération s'est terminée avec succès
          </Alert>
        ) : (
          <Alert
            severity="error"
            onClose={handleCloseSnakBar}
            sx={{ width: "100%" }}>
            Opération échouée !
          </Alert>
        )}
      </Snackbar>
    </>
  );
}

export default AddEmplacementModal;
