import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../assets/styles/about-section.css";
import aboutImg from "../../assets/images/banner1.jpg";

const AboutSection = ({ aboutClass }) => {
  return (
    <section
      className="about__section"
      style={
        aboutClass === "aboutPage"
          ? { marginTop: "0px" }
          : { marginTop: "280px" }
      }>
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="about__section-content text_color">
              <h3 className="section__title text-left mb-3">
                Bienvenue au service de location de véhicule
              </h3>
              <p className="section__description text-left">
                RANCAR vous propose une large gamme de services de location de
                véhicules. Nous voulons vous aider dans vos déplacements et vous
                donner accès aux véhicules correspondant à vos besoins pour tous
                vos déplacements. Retrouvez tous nos services, de la location en
                aller simple jusqu’à la réservation ainsi que les différentes
                options possibles et réservables .
              </p>
            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="about__img">
              <img src={aboutImg} loading="lazy" alt="" className="w-100" />
            </div>
          </Col>
          {/* <Col lg="12" md="6">
              <div className="about__section-content text_color">

              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> Plusieurs agences de
                  location à votre service.
                </p>

                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> Location voitures aux
                  aéroports
                </p>
              </div>

              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i>Nous vous garantissons
                  un service de qualité
                </p>

                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> Des prix plus que
                  promotionnels
                </p>
              </div>
            </div>
          </Col> */}
        </Row>
      </Container>
    </section>
  );
};

export default AboutSection;
