import React from "react";
import { Typography, Box } from "@mui/material";
// import { tokens } from "../theme";

const HeaderDashboard = ({ title, subtitle }) => {
  // const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  return (
    <Box mb="30px" sx={{ display: "inline", width: "100%" }}>
      <Typography
        variant="h2"
        fontWeight="bold"
        sx={{ m: "0 0 5px 0" }}
        className="text_color ">
        RANCAR{" "}
        <span
          style={{
            backgroundColor: "var(--blue_color)",
            color: "var(--white_color)",
            borderRadius: "15px",
          }}>
          BOARD
        </span>
      </Typography>
      <Typography variant="h5" className="text_color " sx={{ m: "0 0 5px 0" }}>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default HeaderDashboard;
