import React from "react";

const Heading = ({ title, subtitle }) => {
  return (
    <>
      <div className="heading  mb-3 ">
        <h1 className="title_heading">{title}</h1>
        <p className="text_color">{subtitle}</p>
      </div>
    </>
  );
};

export default Heading;
