import { createSlice, createAction } from "@reduxjs/toolkit";

export const setSubscriptionError = createAction("setSubscriptionError");
export const newSubscriptionError = createAction("newSubscriptionError");
export const editSubscriptionError = createAction("editSubscriptionError");
export const deleteSubscriptionError = createAction("deleteSubscriptionError");

export const SubscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    subscription: [],
    loading: false,
  },
  reducers: {
    setSubscription: (state, action) => {
      return { ...state, subscription: action.payload, loading: true };
    },
    newSubscription: (state, action) => {
      return {
        ...state,
        subscription: [...state.subscription, action.payload],
      };
    },
    editSubscription: (state, action) => {
      const subscription = state.subscription.map((subscription) => {
        if (subscription.id === action.payload.id) {
          subscription = action.payload;
        }
        return subscription;
      });
      return { ...state, subscription: [...subscription] };
    },
    deleteSubscription: (state, action) => {
      const subscription = state.subscription.filter(
        (subscription) => subscription.id !== action.payload.id
      );
      return { ...state, subscription: [...subscription] };
    },
  },
});

export const {
  setSubscription,
  newSubscription,
  editSubscription,
  deleteSubscription,
} = SubscriptionSlice.actions;

export default SubscriptionSlice.reducer;
