import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SnackbarInfo from "../../lib/Snackbar/SnackbarInfo";
import { Form, FormGroup, Card, CardBody, CardTitle } from "reactstrap";
import { Container, Row, Col } from "reactstrap";
import config from "../../assets/data/config.json";
import { useCookies } from "react-cookie";
import Loader from "../../lib/Loader/Loader";

const NewPassword = () => {
  const navigate = useNavigate();
  const [secondPassword, setSecondPassword] = useState();
  const [password, setPassword] = useState();
  const [cookies, setCookie] = useCookies(["user"]);
  const [response, setResponse] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleVerifyPasswords = () => {
    return password === secondPassword;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (handleVerifyPasswords) {
      axios
        .post(`${config.url}/api/login`, {
          password: password,
        })
        .then((res) => {
          console.log(res.data.token);
          setResponse(true);
          setOpen(true);
          setCookie("user", JSON.stringify(res.data.token));
          navigate("/login");
        })
        .catch((error) => {
          console.log(error);
          setOpen(true);
        });
    } else {
      setOpen(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (cookies.user) {
      navigate("/");
    }
  });
  const [open, setOpen] = useState(false);
  const vertical = "top";
  const horizontal = "center";

  return (
    <div className="login_container">
      <Container className="d-flex min-vh-100">
        <Row className="m-auto align-self-center ">
          <Col lg="15" className="mb-5 text-center">
            <Card
              className="body_color"
              style={{ border: "transparent", borderRadius: "75px" }}>
              {loading ? (
                <Loader />
              ) : (
                <CardBody
                  className="body_color"
                  style={{ border: "transparent", borderRadius: "75px" }}>
                  <CardTitle className="body_color"></CardTitle>
                  <Form className="form body_color" onSubmit={submitHandler}>
                    <h3 className="form-title text_color">
                      Réinitialiser votre mot de passe
                    </h3>
                    <FormGroup className="contact__form">
                      <div>
                        <label htmlFor="password" className="text_color">
                          Mot de passe
                        </label>
                      </div>
                      <input
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        className="input"
                        id="password"
                        required
                      />
                    </FormGroup>
                    <FormGroup className="contact__form">
                      <div>
                        <label htmlFor="password" className="text_color">
                          Retaper mot de passe
                        </label>
                      </div>
                      <input
                        type="password"
                        onChange={(e) => setSecondPassword(e.target.value)}
                        className="input"
                        id="password"
                        required
                      />
                    </FormGroup>
                    <FormGroup className="contact__form">
                      <button className=" btn_secondary mt-3">
                        Enregistrer
                      </button>
                    </FormGroup>
                  </Form>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
      <SnackbarInfo
        successMessage="L'opération s'est terminée avec succès"
        errorMessage="Les données sont incorrect. Réessayez pour le réinitialiser."
        open={open}
        setOpen={setOpen}
        response={response}
        setResponse={setResponse}
        vertical={vertical}
        horizontal={horizontal}
      />
    </div>
  );
};

export default NewPassword;
