import { createSlice } from "@reduxjs/toolkit";

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState: {
    snackbarOpen: false,
    snackbarType: "success",
    snackbarMessage: "",
  },
  reducers: {
    setSnackbar: (state, action) => {
      const { snackbarOpen, snackbarType, snackbarMessage } = action.payload;
      state.snackbarOpen = snackbarOpen;
      state.snackbarType = snackbarType;
      state.snackbarMessage = snackbarMessage;
    },
  },
});
export const { setSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
