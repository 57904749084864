import React, { useState, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../theme";

const ProgressCircle = ({ size = "40", revenueGenerated, credit }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [angle, setAngle] = useState(0);

  useEffect(() => {
    setAngle((credit / revenueGenerated) * 100);
  }, [credit, revenueGenerated]);

  return (
    <Box
      sx={{
        background: `radial-gradient(${colors.primary[400]} 55%, transparent 56%),
            conic-gradient(transparent 0deg ${angle}deg, #FF8C8B ${angle}deg 360deg),
            ${colors.greenAccent[500]}`,
        borderRadius: "50%",
        width: `${size}px`,
        height: `${size}px`,
      }}
    />
  );
};

export default ProgressCircle;
