import React from "react";
import { Link } from "react-router-dom";
import { carsType } from "../../../assets/data/Data";
import { Row, Col } from "reactstrap";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const FeaturedCard = ({ fullData }) => {
  const data = fullData ? carsType : carsType.slice(0, 5);
  return (
    <Row xl="3" className="mt-3 m-1 mb-3 text_color">
      {data.map((items) => (
        <Col className="mb-3">
          <div className="box w-100 h-100 featured_card rounded">
            {items.icon}
            <h4 className="mb-2">{items.name}</h4>
            <label>{items.desc}</label>
          </div>
        </Col>
      ))}
      {fullData ? (
        <></>
      ) : (
        <Col className="mb-3">
          <Link to="/vehicules" className="text_color text-decoration-none">
            <div className="box w-100 h-100 featured_card rounded">
              <MoreHorizIcon
                style={{ fontSize: "70px", color: "var(--blue_color)" }}
              />
              <h4 className="mb-2 text-decoration-none">Voir plus</h4>
              <label>
                Découvrez les multiples choix qui s'offrent à vous en explorant
                notre diversité de véhicules.
              </label>
            </div>
          </Link>
        </Col>
      )}
    </Row>
  );
};

export default FeaturedCard;
