import React, { useState, useEffect } from "react";
// import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
// import config from "../../assets/data/config.json";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import { AppBar, Box, Typography, Toolbar } from "@mui/material";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import NavDropdown from "react-bootstrap/NavDropdown";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useCookies } from "react-cookie";
import PersonIcon from "@mui/icons-material/Person";
import SwitchButton from "../UI/SwitchButton/SwitchButton";
import NavbarMenuIcon from "./NavbarMenuSidebar";
import LoginIcon from "@mui/icons-material/Login";
import { Role_Admin, Role_Manger, Role_Client } from "../../assets/data/Roles";
import { LogoutUser } from "../../services/User";
import { useDispatch, useSelector } from "react-redux";

const Navbar = () => {
  const theme = useSelector((state) => state.theme.value);
  const user = useSelector((state) => state.user.value);
  const matches = useMediaQuery("(min-width:1400px)");
  const [authenticated, setAuthenticated] = useState();
  // const [anchorEl, setAnchorEl] = useState(null);
  // const isOpen = Boolean(anchorEl);
  // const handleClick = (event) => setAnchorEl(event.currentTarget);
  // const handleClose = () => setAnchorEl(null);
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  // const [cookiesRole, setCookiesRole, removeRoleCookie] = useCookies(["role"]);
  // const [role, setRole] = useState(null);
  const dispatch = useDispatch();

  const logout = async () => {
    await removeCookie("user");
    // await removeRoleCookie("role");
    await localStorage.removeItem("userId");
    LogoutUser(dispatch);
    navigate("/access");
  };

  // async function getCurrentUser() {
  //   await axios
  //     .get(`${config.url}/api/getCurrentUser`, {
  //       headers: { Authorization: `Bearer ${cookies.user}` },
  //     })
  //     .then((res) => {
  //       setFirstName(res.data.firstName);
  //       setLastName(res.data.lastName);
  //       setRole(res.data.roles[0]);
  //       setCookiesRole("role", res.data.roles[0]);
  //       localStorage.setItem("userId", res.data.id);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }
  useEffect(() => {
    // getCurrentUser();
    if (cookies.user === undefined) {
      setAuthenticated(false);
    } else {
      setAuthenticated(true);
    }
    if (false) {
      setCookie("false");
    }
  }, [cookies.user, setCookie]);

  return (
    <>
      <AppBar
        className="Navbar"
        sx={{
          position: "static",
          background: theme.background,
          // color: theme.color,
          boxShadow: "none",
        }}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          {/* LEFT SIDE */}
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Box style={{ width: "150px" }} p="0.1rem 1.5rem">
              <Link to="/">
                <div className="logo"></div>
              </Link>
            </Box>
          </Box>

          {authenticated === true ? (
            <>
              {matches ? (
                <>
                  {/* RIGHT SIDE */}
                  <Box gap="1.5rem">
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}>
                        {user?.roles[0] ? (<>
                      {user?.roles[0] === Role_Client ? NavClient : <></>}
                      {user?.roles[0] === Role_Manger ? NavManger : <></>}
                      {user?.roles[0] === Role_Admin ? NavAdmin : <></>}
                      </>):null}
                      <Box
                        style={{ marginLeft: "auto" }}
                        borderRadius="9px"
                        gap="3rem"
                        p="0.1rem 1.5rem"></Box>
                      <NavDropdown
                        title={
                          <>
                            <PersonIcon />
                            {user?.firstName + " " + user?.lastName}
                          </>
                        }
                        id="collapsible-nav-dropdown"
                        className="link nav_link link_active"
                        size="lg">
                        <NavDropdown.Item className="text_color">
                          <Link
                            to="/profile"
                            className="link nav_link link_active">
                            <span className="text_color">Profile</span>
                          </Link>{" "}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          className="text_color"
                          style={{ overflow: "auto" }}
                          href="/">
                          <span className="text_color">Mode sombre</span>

                          <span style={{ float: "right" }}>
                            <SwitchButton />
                          </span>
                        </NavDropdown.Item>

                        <NavDropdown.Divider
                          style={{ backgroundColor: "var(--text_color)" }}
                        />
                        <NavDropdown.Item className="text_color">
                          <button
                            style={{
                              backgroundColor: "transparent",
                              border: "transparent",
                            }}
                            onClick={logout}>
                            <span className="text_color">Se déconnecter</span>
                          </button>
                        </NavDropdown.Item>
                      </NavDropdown>
                    </Box>
                  </Box>
                </>
              ) : (
                <NavbarMenuIcon
                  role={user?.roles[0]}
                  logout={logout}
                  firstName={user?.firstName}
                  lastName={user?.lastName}
                />
              )}
            </>
          ) : (
            <Box
              gap="1.5rem"
              className="p-0"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <SwitchButton />
              {matches ? (
                <Box
                  style={{ marginLeft: "auto" }}
                  borderRadius="9px"
                  gap="3rem"
                  p="0.1rem 1.5rem">
                  <Link to="/login" className="link nav_link link_active">
                    <Typography fontSize="1.05rem">
                      {" "}
                      Espace Abonnés
                      <LoginIcon />
                    </Typography>
                  </Link>
                </Box>
              ) : (
                <Box>
                  <Link
                    to="/login"
                    className="link nav_link link_active m-1 mr-3">
                    <Typography>
                      <LoginIcon />
                    </Typography>
                  </Link>
                </Box>
              )}
            </Box>
          )}
        </Toolbar>
      </AppBar>
      {/* <Breadcrumbs /> */}
    </>
  );
};

const NavManger = (
  <>
    <Box
      style={{ marginLeft: "auto" }}
      borderRadius="9px"
      gap="3rem"
      p="0.1rem 1.5rem">
      <Link to="/dashboard" className="link nav_link link_active">
        <Typography fontSize="1.05rem">
          {" "}
          <i className="ri-coin-line"></i> Dashboard{" "}
        </Typography>
      </Link>
    </Box>

    <Box
      style={{ marginLeft: "auto" }}
      borderRadius="9px"
      gap="3rem"
      p="0.1rem 1.5rem">
      <Link to="/parc" className="link nav_link link_active">
        <Typography fontSize="1.05rem">
          {" "}
          <i className="ri-parking-box-line"></i> Parc{" "}
        </Typography>
      </Link>
    </Box>

    <Box
      style={{ marginLeft: "auto" }}
      borderRadius="9px"
      gap="3rem"
      p="0.1rem 1.5rem">
      <Link to="/admin/calendar" className="link nav_link link_active">
        <Typography fontSize="1.05rem">
          {" "}
          <i className="ri-reserved-line"></i> Réservation{" "}
        </Typography>
      </Link>
    </Box>

    <Box
      style={{ marginLeft: "auto" }}
      borderRadius="9px"
      gap="3rem"
      p="0.1rem 1.5rem">
      <Link to="/admin/treasry" className="link nav_link link_active">
        <Typography fontSize="1.05rem">
          <i className="ri-git-repository-private-line"></i> Caisse{" "}
        </Typography>
      </Link>
    </Box>

    <Box
      style={{ marginLeft: "auto" }}
      borderRadius="9px"
      gap="3rem"
      p="0.1rem 1.5rem">
      <Link to="/admin/tools" className="link nav_link link_active">
        <Typography fontSize="1.05rem">
          {" "}
          <i className="ri-keyboard-line"></i> Outils{" "}
        </Typography>
      </Link>
    </Box>
  </>
);

const NavAdmin = (
  <>
    <Box
      style={{ marginLeft: "auto" }}
      borderRadius="9px"
      gap="3rem"
      p="0.1rem 1.5rem">
      <Link to="/superadmin/dashboard" className="link nav_link link_active">
        <Typography fontSize="1.05rem">
          {" "}
          <i className="ri-coin-line"></i> Dashboard{" "}
        </Typography>
      </Link>
    </Box>

    <NavDropdown
      title={
        <>
          <FolderSharedIcon />
          Comptes
        </>
      }
      id="collapsible-nav-dropdown"
      className="link nav_link link_active"
      size="lg">
      <NavDropdown.Item className="text_color">
        <Link to="/superadmin/users" className="link nav_link link_active">
          <Typography fontSize="1.05rem">
            {" "}
            <i className="fa-solid fa-id-badge"></i> Abonnés{" "}
          </Typography>
        </Link>
      </NavDropdown.Item>
      <NavDropdown.Item className="text_color" style={{ overflow: "auto" }}>
        <Link to="/superadmin/clients" className="link nav_link link_active">
          <Typography fontSize="1.05rem">
            {" "}
            <i className="fa-solid fa-person"></i> Clients{" "}
          </Typography>
        </Link>
      </NavDropdown.Item>
    </NavDropdown>

    <NavDropdown
      title={
        <>
          <DirectionsCarFilledIcon />
          Véhicules
        </>
      }
      id="collapsible-nav-dropdown"
      className="link nav_link link_active"
      size="lg">
      <NavDropdown.Item className="text_color">
        <Link
          to="/superadmin/maintenance"
          className="link nav_link link_active">
          <Typography fontSize="1.05rem">
            {" "}
            <i className="fa-solid fa-gear"></i> Entretient{" "}
          </Typography>
        </Link>
      </NavDropdown.Item>
      <NavDropdown.Item className="text_color" style={{ overflow: "auto" }}>
        <Link to="/superadmin/documents" className="link nav_link link_active">
          <Typography fontSize="1.05rem">
            {" "}
            <i className="ri-folder-line"></i> Documents{" "}
          </Typography>
        </Link>
      </NavDropdown.Item>
      <NavDropdown.Item className="text_color">
        <Link to="/superadmin/category" className="link nav_link link_active">
          <Typography fontSize="1.05rem">
            {" "}
            <i className="fa-sharp fa-solid fa-layer-group"></i> Catégorie{" "}
          </Typography>
        </Link>
      </NavDropdown.Item>

      <NavDropdown.Item className="text_color">
        <Link to="/superadmin/brands" className="link nav_link link_active">
          <Typography fontSize="1.05rem">
            {" "}
            <i className="ri-home-gear-fill"></i> Marques{" "}
          </Typography>
        </Link>
      </NavDropdown.Item>
    </NavDropdown>

    <Box
      style={{ marginLeft: "auto" }}
      borderRadius="9px"
      gap="3rem"
      p="0.1rem 1.5rem">
      <Link to="/superadmin/cities" className="link nav_link link_active">
        <Typography fontSize="1.05rem">
          {" "}
          <i className="fa-solid fa-city"></i> Villes{" "}
        </Typography>
      </Link>
    </Box>

    <Box
      style={{ marginLeft: "auto" }}
      borderRadius="9px"
      gap="3rem"
      p="0.1rem 1.5rem">
      <Link to="/superadmin/partner" className="link nav_link link_active">
        <Typography fontSize="1.05rem">
          {" "}
          <i className="ri-shake-hands-fill"></i> Partenaires{" "}
        </Typography>
      </Link>
    </Box>

    <Box
      style={{ marginLeft: "auto" }}
      borderRadius="9px"
      gap="3rem"
      p="0.1rem 1.5rem">
      <Link to="/superadmin/packages" className="link nav_link link_active">
        <Typography fontSize="1.05rem">
          {" "}
          <i className="ri-star-fill"></i> Forfaits{" "}
        </Typography>
      </Link>
    </Box>
  </>
);
const NavClient = <></>;
export default Navbar;
