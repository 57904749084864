import React from "react";
import Heading from "../Heading";
import { Row, Col } from "reactstrap";
import { awards } from "../../../assets/data/Data";
import "./awards.css";

const Awards = () => {
  return (
    <>
      <section className="awards padding">
        <div className="container_awards">
          <Heading
            title="RANCAR vous connecte aux plus grandes enseignes de location des Véhicules"
            subtitle=""
          />

          <Row className="content grid4 mb-3">
            {awards.map((val, index) => (
              <Col className="box mb-3" key={index}>
                <div className="icon">
                  <span>{val.icon}</span>
                </div>
                <h1>{val.num}</h1>
                <p className="text_color">{val.name}</p>
              </Col>
            ))}
          </Row>
        </div>
      </section>
    </>
  );
};

export default Awards;
