import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetPackages } from "../../services/Package";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import config from "../../assets/data/config.json";
import { Row, Col, FormGroup } from "reactstrap";
// import { Button, Modal, Input } from "react-bootstrap";
// import axios from "axios";
// import Select, { SelectChangeEvent } from "@mui/material/Select";

export default function Step2({ data, setData }) {
  const dispatch = useDispatch();
  const packages = useSelector((state) => state.package.packages);

  // const isPending = useSelector((state) => state.package.loading);

  useEffect(() => {
    GetPackages(dispatch);
  }, [dispatch]);
  const handelChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleSubmit = () => setShow(false);

  return (
    <div class="container ">
      <Row>
        <Col className="mt-5">
          <div className="booking-info mt-5">
            <h5 className="mb-4 fw-bold ">Informations d'abonnement</h5>
            <FormGroup className="booking__form d-inline-block text-left mr-1">
              <label className="text_color">Offre:</label>
              <select
                className="select_input"
                onChange={(e) => handelChange(e)}
                name="package">
                {packages.map((item, index) => {
                  return (
                    <option
                      value={item.name}
                      selected={data["package"] === item.name}
                      key={index}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </FormGroup>
            <FormGroup className="booking__form d-inline-block text-left ">
              <label className="text_color">Periode:</label>
              <select
                className="select_input"
                onChange={(e) => handelChange(e)}
                name="period">
                <option value={"6"} selected={data["period"] === "6"}>
                  6 mois :{" "}
                  <span style={{ color: "var(--blue_color)" }}>
                    {" "}
                    Économisez 10% par mois{" "}
                  </span>
                </option>
                <option value={"12"} selected={data["period"] === "12"}>
                  12 mois :{" "}
                  <span style={{ color: "var(--blue_color)" }}>
                    {" "}
                    Économisez 15% par mois{" "}
                  </span>
                </option>
              </select>
            </FormGroup>

            <FormGroup className="booking__form d-inline-block text-left mr-1">
              <label className="text_color">Methode de payment:</label>
              <select
                className="select_input"
                onChange={(e) => handelChange(e)}
                name="method">
                <option
                  value={"MOBILE_MONEY"}
                  selected={data["method"] === "MOBILE_MONEY"}>
                  MOBILE MONEY
                </option>
                <option
                  value={"CREDIT_CARD"}
                  selected={data["method"] === "CREDIT_CARD"}>
                  Carte bancaire
                </option>
                <option value={"WALLET"} selected={data["method"] === "WALLET"}>
                  WALLET
                </option>
              </select>
            </FormGroup>
            <FormGroup className="booking__form d-inline-block text-left ">
              <label className="text_color">Type d'exploration:</label>
              <select
                className="select_input"
                onChange={(e) => handelChange(e)}
                name="userType">
                <option
                  value={"PARTICULAR_USER"}
                  selected={data["userType"] === "PARTICULAR_USER"}>
                  Particulier
                </option>
                <option
                  value={"AGENCY"}
                  selected={data["userType"] === "AGENCY"}>
                  Agence
                </option>
              </select>
            </FormGroup>
            {data["userType"] === "AGENCY" ? (
              <>
                <FormGroup className="booking__form d-inline-block text-left mr-1">
                  <label className="text_color">Nom d'agence :</label>
                  <input
                    type="text"
                    onChange={(e) => handelChange(e)}
                    placeholder="taper le nom de votre agence"
                    required
                    name="agencyName"
                    defaultValue={data["agencyName"]}
                  />
                </FormGroup>
                <FormGroup className="booking__form d-inline-block text-left ">
                  <label className="text_color">Matricule fiscale :</label>
                  <input
                    type="text"
                    onChange={(e) => handelChange(e)}
                    placeholder="taper la matricule fiscale"
                    required
                    name="taxId"
                    defaultValue={data["taxId"]}
                  />
                </FormGroup>
              </>
            ) : (
              <>
                <FormGroup className="booking__form d-inline-block text-left mr-1">
                  <label className="text_color">Numéro CIN :</label>
                  <input
                    type="number"
                    onChange={(e) => handelChange(e)}
                    placeholder="taper votre cin"
                    required
                    name="cin"
                    defaultValue={data["cin"]}
                  />
                </FormGroup>
                <FormGroup className="booking__form d-inline-block text-left ">
                  <label className="text_color">Profession:</label>
                  <input
                    type="text"
                    onChange={(e) => handelChange(e)}
                    placeholder="taper la profession"
                    required
                    name="job"
                    defaultValue={data["job"]}
                  />
                </FormGroup>
              </>
            )}
          </div>
        </Col>
      </Row>
      {/* <Modal
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title style={{ width: "100%", color: "#004a9e" }}>
                                Ajouter une methodologie
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div class="form-group mt-3">
                            <div class="col-md-6 mb-3">
                                <label for="name">Nom du methodologie</label>
                                <input type="text" class="form-control" aria-label="Small" onChange={(e) => setNewMethodologie(e.target.value)} aria-describedby="inputGroup-sizing-sm" id="name" placeholder="Nom" required />
                            </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                            <Button style={{ backgroundColor: "#004a9e" }} onClick={handleSubmit}>
                                    Ajouter
                                </Button>
                                <Button variant='secondary' onClick={handleClose}>
                                    Fermer
                                </Button>

                            </Modal.Footer>
                        </Modal> */}
    </div>
  );
}
