import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { Container, Row, Col, Form, FormGroup, Input } from "reactstrap";
import Helmet from "../../components/Helmet/Helmet";
import CommonSection from "../../components/UI/CommonSection";
import image from "../../assets/images/man.jpg";
import { NewContact } from "../../services/Contact";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../features/snackbar";
import "../../assets/styles/contact.css";
import dayjs from "dayjs";

// const socialLinks = [
//   {
//     url: "https://www.facebook.com/AlWifakRentCar",
//     icon: "ri-facebook-line",
//   },
//   {
//     url: "https://www.instagram.com/alwifak_rent_a_car/",
//     icon: "ri-instagram-line",
//   },
//   {
//     url: "#",
//     icon: "ri-linkedin-line",
//   },
//   {
//     url: "#",
//     icon: "ri-twitter-line",
//   },
// ];

const Contact = () => {
  const toDay = new Date();
  const [data, setData] = useState({
    userName: "",
    userEmail: "",
    date: dayjs(toDay).format("DD-MM-YYYY"),
    description: "",
  });
  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  const validate = (data) => {
    for (const key in data) {
      if (data.hasOwnProperty(key) && !data[key]) {
        return false;
      }
    }
    return true;
  };

  const dispatch = useDispatch();
  const errorMessage =
    "Mot de passe incorrect. Réessayez ou cliquez sur 'Mot de passe oublié'pour le réinitialiser.";
  const successMessage = "L'opération s'est terminée avec succès";

  const handleSubmit = async () => {
    try {
      if (validate(data)) {
        await NewContact(data);
        const action = {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: successMessage,
        };
        dispatch(setSnackbar(action));
      } else {
        throw new Error("Veuillez remplir tous les champs !");
      }
    } catch (error) {
      console.error("Erreur lors de la soumission :", error);
      const action = {
        snackbarOpen: true,
        snackbarType: "error",
        snackbarMessage: errorMessage,
      };
      dispatch(setSnackbar(action));
    }
  };

  return (
    <Helmet title="Contact">
      <CommonSection title="Contactez-nous" />
      <section className="mb-3">
        <Container>
          <Row>
            <Col lg="6" md="7" className="p-3">
              <h2 className="text_color text-left ml-2 mt-2">
                Échangeons, Collaborons, Répondons
              </h2>
              <h4 className="text_color text-left ml-2 mb-0">
                Connectons-nous : Vos Questions, Nos Réponses
              </h4>
              <Form className="p-3" style={{ backgroundColor: "transparent" }}>
                <FormGroup className="contact__form">
                  <Input
                    placeholder="Nom et Prénom"
                    type="text"
                    onChange={(e) => handleChange(e)}
                    name="userName"
                  />
                </FormGroup>
                <FormGroup className="contact__form">
                  <Input
                    placeholder="E-mail"
                    type="email"
                    onChange={(e) => handleChange(e)}
                    name="userEmail"
                  />
                </FormGroup>
                <FormGroup className="contact__form">
                  <textarea
                    rows="6"
                    placeholder="Message"
                    className="textarea"
                    onChange={(e) => handleChange(e)}
                    name="description"></textarea>
                </FormGroup>
              </Form>
              <button className="btn_primary " onClick={handleSubmit}>
                Soumettre
              </button>
            </Col>

            <Col lg="6" md="9">
              <div className="contact__info mt-3 text_color">
                <img src={image} className="w-100 h-100" alt={"contact_nous"} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Contact;
