import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from 'react-phone-number-input';
import Http from "../../../lib/http/http";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
} from "reactstrap";
// import useMediaQuery from "@mui/material/useMediaQuery";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

export default function ProfileUpdateModel(props) {
  const [dataP, setDataP] = useState();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [response, setResponse] = useState(false);
  const [open, setOpen] = useState(false);
  const handleCloseSnakBar = () => setOpen(false);
  const handleOpenSnakBar = () => setOpen(true);

  const handelChange = (event) => {
    setDataP({
      ...dataP,
      [event.target.name]: event.target.value,
    });
  };
  
  const handleChangePhone=(e)=>{
    setDataP({
      ...dataP,
      phone: e,
    });
  }


  const handleSubmit = async () => {
    if (true) {
      setResponse(true);
      await Http.put(`/user/${localStorage.getItem("userId")}`, dataP)
    }
    handleOpenSnakBar();
    toggle();
    window.location.reload(true);
  };
  useEffect(() => {
    setDataP(props.data);
  }, [props.data]);

  return (
    <div>
      <button className="btn_secondary" onClick={toggle}>
        modifier
      </button>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader className="body_color text_color" toggle={toggle}>
          Mise à jour Profile{" "}
        </ModalHeader>
        <ModalBody className="body_color">
          <Row>
            <Col className="mt-5">
              <div className="booking-info mt-5">
                <FormGroup className="booking__form d-inline-block">
                  <label className="text_color">Nom :</label>
                  <input
                    type="text"
                    onChange={(e) => handelChange(e)}
                    placeholder="Taper votre nom"
                    required
                    name="firstName"
                    defaultValue={props.data?.firstName}
                  />
                </FormGroup>
                <FormGroup className="booking__form d-inline-block ms-1 mb-4 ">
                  <label className="text_color">Prénom :</label>
                  <input
                    type="text"
                    onChange={(e) => handelChange(e)}
                    placeholder=" Taper votre prénom"
                    required
                    name="lastName"
                    defaultValue={props.data?.lastName}
                  />
                </FormGroup>

                <FormGroup className="booking__form d-inline-block  ">
                  <label className="text_color">E-mail :</label>
                  <input
                    type="email"
                    onChange={(e) => handelChange(e)}
                    placeholder="Taper votre adresse E-mail"
                    required
                    name="email"
                    defaultValue={props.data?.email}
                  />
                </FormGroup>
                <FormGroup className="booking__form d-inline-block ms-1 mb-4 ">
                  <label className="text_color">
                    Numéro de téléphone:
                  </label>
                  <PhoneInput onChange={(e)=>handleChangePhone(e)} value={props.data?.phone} name="phone" />
                  {/* <input
                    type="text"
                    onChange={(e) => handelChange(e)}
                    placeholder="Taper votre Numéro de téléphone"
                    required
                    name="phone"
                    defaultValue={props.data?.phone}
                  /> */}
                </FormGroup>
                <FormGroup className="booking__form d-inline-block ms-1 mb-4 ">
                  <label className="text_color">Adresse :</label>
                  <input
                    type="text"
                    onChange={(e) => handelChange(e)}
                    placeholder="Tapez votre adresse"
                    required
                    name="address"
                    defaultValue={props.data?.address}
                  />
                </FormGroup>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="body_color">
          <button
            className="btn_secondary p-2"
            style={{ height: "40px", verticalAlign: "center" }}
            onClick={(e) => handleSubmit(e)}>
            Sauvgarder
          </button>
          <Button
            color="secondary"
            style={{ borderRadius: "50px" }}
            onClick={toggle}>
            Fermer
          </Button>
        </ModalFooter>
      </Modal>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleCloseSnakBar}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
        {response ? (
          <Alert
            onClose={handleCloseSnakBar}
            severity="success"
            color="info"
            sx={{ width: "100%" }}>
            L'opération s'est terminée avec succès
          </Alert>
        ) : (
          <Alert
            severity="error"
            onClose={handleCloseSnakBar}
            sx={{ width: "100%" }}>
            Opération échouée !
          </Alert>
        )}
      </Snackbar>
    </div>
  );
}
