import React from "react";
import axios from "axios";
import "../../../node_modules/flatpickr/dist/flatpickr.min.css";
// flatpickr/dist/flatpickr.min.css
import "../../assets/styles/booking-form.css";
import { useNavigate, useParams } from "react-router-dom";
import { Col, FormGroup, Row } from "reactstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useState, useRef } from "react";
import { FormControlLabel } from "@mui/material";
import config from "../../assets/data/config.json";
import "../../assets/styles/payment-method.css";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
// import flatpickr from "flatpickr";

const BookingForm = ({ carId }) => {
  const { slug } = useParams();
  const userId = localStorage.getItem("userId");
  const datePickerRef = useRef(null);
  const datePickerRefStart = useRef(null);
  // const currentDate = new Date().toISOString().split("T")[0];
  // const currentTime = new Date().toLocaleTimeString("en-US", { hour12: false });
  const [open, setOpen] = useState(false);
  const handleCloseSnakBar = () => setOpen(false);
  // const handleOpenSnakBar = () => setOpen(true);
  const [actif, setActif] = useState(false);
  const [dateprise, setdateprise] = useState(getTodayDate(0));
  const [datereprise, setdatereprise] = useState(getTodayDate(1));
  const [timeprise, settimeprise] = useState(getCustomTime(0));
  const [timereprise, settimereprise] = useState(getCustomTime(4));
  const [payment, setpayment] = useState("");
  const [modal, setModal] = useState(false);
  const [isvalid, setIsvalid] = useState(false);
  const toggle = () => setModal(!modal);
  // const handleOpenModal = () => setModal(true);
  const handleCloseModal = () => {
    setModal(false);
    navigate("/profile");
  };
  const navigate = useNavigate();
  // function getDisabledDates() {
  //   axios
  //     .get(`${config.url}/getDisabledDate/${carId}`)
  //     .then((res) => {
  //       console.warn(Object.values(res.data));
  //       pick(Object.values(res.data));
  //       pickStart(Object.values(res.data));
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  // async function pick(x) {
  //   if (datePickerRef.current) {
  //     flatpickr(datePickerRef.current, {
  //       minDate: "today",
  //       disable: x,
  //       dateFormat: "Y-m-d",
  //       onChange: (selectedDates) => {
  //         setdatereprise(selectedDates[0].toISOString().split("T")[0]);
  //       },
  //     });
  //   }
  // }
  // async function pickStart(x) {
  //   if (datePickerRefStart.current) {
  //     flatpickr(datePickerRefStart.current, {
  //       minDate: "today",
  //       disable: x,
  //       dateFormat: "Y-m-d",
  //       onChange: (selectedDates) => {
  //         setdateprise(selectedDates[0].toISOString().split("T")[0]);
  //       },
  //     });
  //   }
  // }
  // useEffect(() => {
  //   getDisabledDates();
  // }, [datePickerRef,getDisabledDates]);
  // const formatDate = (date) => {
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const day = String(date.getDate()).padStart(2, "0");
  //   return `${year}-${month}-${day}`;
  // };

  function getTodayDate(x) {
    const today = new Date();
    const futureDate = new Date(today);
    futureDate.setDate(today.getDate() + x);
    const year = futureDate.getFullYear();
    let month = (futureDate.getMonth() + 1).toString();
    let day = futureDate.getDate().toString();
    month = month.length === 1 ? `0${month}` : month;
    day = day.length === 1 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  }
  function getCustomTime(offset) {
    const now = new Date();
    now.setHours(now.getHours() + offset);

    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");

    // Concaténer l'heure et les minutes
    const formattedTime = `${hours}:${minutes}`;

    return formattedTime;
  }

  const submitHandler = async (event) => {
    event.preventDefault();
    if (dateprise < datereprise) {
      setIsvalid(true);
      axios
        .post(`${config.url}/reserveVehicle`, {
          userId: userId,
          vehicleId: slug,
          startDate: dateprise + " " + timeprise,
          endDate: datereprise + " " + timereprise,
          method: payment,
        })
        .then((res) => {
          console.warn(res);
        })
        .catch((error) => {
          console.log(error);
        });
      toggle();
    } else {
      alert("La date de début inférieur à la date de retour");
    }
  };

  // const handleSelected = (e) => {
  //   setpayment(e.target.value);
  // };
  return (
    <Row>
      {userId === null ? null : (
        <>
          <div className="form_book text_color">
            <Row>
              <Col className="mt-5">
                <div className="booking-info mt-5">
                  <h5 className="mb-4 fw-bold ">Informations de Réservation</h5>
                  <FormGroup className="booking__form d-inline-block  ">
                    <label className="text_color">Date de début</label>
                    <input
                      id="datepickerStart"
                      name="datepickerStart"
                      ref={datePickerRefStart}
                      type="date"
                      value={dateprise}
                      onChange={(e) => setdateprise(e.target.value)}
                      placeholder="Journey Date"
                      required
                    />
                  </FormGroup>
                  <FormGroup className="booking__form d-inline-block ms-1 mb-4 ">
                    <label className="text_color">Heure de début</label>
                    <input
                      type="time"
                      value={timeprise}
                      onChange={(e) => settimeprise(e.target.value)}
                      required
                      // className="time__picker"
                    />
                  </FormGroup>
                  <FormGroup className="booking__form d-inline-block">
                    <label className="text_color">Date de restitution</label>
                    <input
                      id="datepicker"
                      name="datepicker"
                      ref={datePickerRef}
                      type="date"
                      value={datereprise}
                      onChange={(e) => setdatereprise(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="booking__form d-inline-block ms-1 mb-4 ">
                    <label className="text_color">Heure de fin</label>
                    <input
                      type="time"
                      value={timereprise}
                      onChange={(e) => settimereprise(e.target.value)}
                      required
                      // className="time__picker2"
                    />
                  </FormGroup>

                  <FormGroup className="booking__form d-inline-block  ">
                    <label className="text_color">Méthode de paiement</label>
                    <select
                      className="select_input"
                      name="method"
                      required
                      onChange={(e) => setpayment(e.target.value)}>
                      <option value={""}>Choisir la méthode</option>
                      <option value={"Espèce"}>Espèce</option>
                      <option value={"Virment"}>
                        Virement bancaire direct
                      </option>
                      <option value={"Chéque"}>Chèque</option>
                      <option value={"Master Card"}>Master Card</option>
                    </select>
                  </FormGroup>
                  <FormGroup className=" booking__form d-inline-block ms-1 mb-4 ">
                    <FormControlLabel
                      className="w-100 "
                      control={
                        <input
                          type="checkbox"
                          onClick={() => setActif(!actif)}
                        />
                      }
                      label="J'accepte les conditions générales "
                      labelPlacement="end"
                    />
                  </FormGroup>
                  <FormGroup className=" booking__form d-inline-block ms-1 mb-4 "></FormGroup>
                  <FormGroup className="booking__form d-inline-block  ">
                    <button
                      onClick={submitHandler}
                      className="btn_secondary"
                      disabled={!actif}>
                      Réservez Maintenant
                    </button>
                  </FormGroup>
                </div>
              </Col>
            </Row>
          </div>

          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleCloseSnakBar}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
            {true ? (
              <Alert
                onClose={handleCloseSnakBar}
                severity="success"
                color="info"
                sx={{ width: "100%" }}>
                L'opération s'est terminée avec succès
              </Alert>
            ) : (
              <Alert
                severity="error"
                onClose={handleCloseSnakBar}
                sx={{ width: "100%" }}>
                Opération échouée !
              </Alert>
            )}
          </Snackbar>
          <div>
            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle}>Reserervation</ModalHeader>
              <ModalBody>
                {isvalid ? (
                  <>
                    {" "}
                    <h2>Bonjour !</h2>
                    Nous avons bien reçu votre demande et nous vous remercions
                    de l’intérêt que vous portez à notre service. Un membre de
                    notre équipe entrera en contact avec vous dans les plus
                    brefs délais
                  </>
                ) : (
                  <>
                    Les corrdonnées que vous avez saisi(e) n’est pas
                    complet.S'il vous plaît ,essayez de les compléter avant de
                    confirmer votre Reserervation
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={handleCloseModal}>
                  Fermer
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </>
      )}
    </Row>
  );
};

export default BookingForm;
