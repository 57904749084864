import React from "react";
// import { footer } from "../../assets/data/Data";
// import EmailIcon from "@mui/icons-material/Email";
// import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
// import MapIcon from "@mui/icons-material/Map";
// import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import { Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import "./footer.css";

const links = [
  {
    path: "/about",
    display: "Services",
  },

  {
    path: "/legalnotice",
    display: "Mentions Légales",
  },

  {
    path: "/cars",
    display: "Véhicules",
  },
  {
    path: "/contact",
    display: "Contactez-nous",
  },
];

const quickLinks = [
  {
    path: "/packages",
    display: "Offres de partenaires",
  },

  {
    path: "/outofservice",
    display: "Réclamation partenaires",
  },

  {
    path: "/outofservice",
    display: "Aide du Service Clients",
  },
  {
    path: "/outofservice",
    display: "Charte de confidentialité",
  },
];

const Footer = () => {
  return (
    <>
      <section className="footerContact">
        <div className="container">
          <Row className="send flex">
            <Col xl="8" className="text-left">
              <h1>Louez vos véhicules dès maintenant!</h1>
              <p>
                Consultez nos offres exclusives et profitez de tous les
                avantages qui vous sont réservés
              </p>
            </Col>
            <Col>
              <Link to="/packages">
                <button className="btn_primary mt-3">
                  Consultez nos offres
                </button>
              </Link>
            </Col>
          </Row>
        </div>
      </section>

      <footer>
        <div className="container">
          <div className="box p-0">
            <Row>
              <Col>
                <div className="logo"></div>
                <div className="mt-2 mr-2 h-100 text-left">
                  <h2 className="text_color">
                    Avez-vous besoin d'aide pour quoi que ce soit ?
                  </h2>
                  <p>
                    Recevez des mises à jour, des offres spéciales, des
                    tutoriels et des réductions directement dans votre boîte de
                    réception chaque mois
                  </p>
                </div>
              </Col>
            </Row>
            <div className="input flex p-2 ">
              <Row>
                <Col xs={9} className="p-0">
                  <input
                    type="text"
                    placeholder="Email"
                    className="input"
                    style={{ borderRadius: "25px" }}
                  />
                </Col>
                <Col xs={2} className="p-0 ">
                  <button className="btn_secondary mb-1 h-75 pt-0 pb-0 mr-3">
                    Envoyer
                  </button>
                </Col>
              </Row>
            </div>
          </div>

          <Row>
            <Col style={{ textAlign: "left" }}>
              <div className="mb-4">
                <h5 className="footer__link-title ">Acceuil</h5>
                <ListGroup className="mt-3">
                  {links.map((item, index) => (
                    <ListGroupItem
                      key={index}
                      className="p-0 quick__link mt-2"
                      style={{
                        border: "transparent",
                        background: "transparent",
                      }}>
                      <Link className=" link_active text_color" to={item.path}>
                        {item.display}
                      </Link>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <div style={{ textAlign: "left" }}>
              <div className="mb-4">
                <h5 className="footer__link-title">Privilège rapide</h5>
                <ListGroup className="mt-3">
                  {quickLinks.map((item, index) => (
                    <ListGroupItem
                      key={index}
                      className="p-0 quick__link mt-2"
                      style={{
                        border: "transparent",
                        background: "transparent",
                      }}>
                      <Link className=" link_active text_color" to={item.path}>
                        {item.display}
                      </Link>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </div>
            </div>
          </Row>
        </div>
      </footer>
      <div className="legal">
        <span>© 2024 RANCAR. www.ran-car.com</span>
      </div>
    </>
  );
};

export default Footer;
