import { createSlice, createAction } from "@reduxjs/toolkit";

export const setMaintenanceTypeError = createAction("setMaintenanceTypeError");
export const newMaintenanceTypeError = createAction("newMaintenanceTypeError");
export const editMaintenanceTypeError = createAction(
  "editMaintenanceTypeError"
);
export const deleteMaintenanceTypeError = createAction(
  "deleteMaintenanceTypeError"
);

export const MaintenanceTypeSlice = createSlice({
  name: "MaintenanceType",
  initialState: {
    MaintenanceType: [],
    loading: false,
  },
  reducers: {
    setMaintenanceType: (state, action) => {
      return { ...state, MaintenanceType: action.payload, loading: true };
    },
    newMaintenanceType: (state, action) => {
      return {
        ...state,
        MaintenanceType: [...state.MaintenanceType, action.payload],
      };
    },
    editMaintenanceType: (state, action) => {
      const MaintenanceType = state.MaintenanceType.map((MaintenanceType) => {
        if (MaintenanceType.id === action.payload.id) {
          MaintenanceType = action.payload;
        }
        return MaintenanceType;
      });
      return { ...state, MaintenanceType: [...MaintenanceType] };
    },
    deleteMaintenanceType: (state, action) => {
      const MaintenanceType = state.MaintenanceType.filter(
        (MaintenanceType) => MaintenanceType.id !== action.payload.id
      );
      return { ...state, MaintenanceType: [...MaintenanceType] };
    },
    setLoading: (state, action) => {
      return { ...state, loading: action.payload };
    },
  },
});

export const {
  setMaintenanceType,
  newMaintenanceType,
  editMaintenanceType,
  deleteMaintenanceType,
  setLoading,
} = MaintenanceTypeSlice.actions;

export default MaintenanceTypeSlice.reducer;
