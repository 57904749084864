import { createSlice, createAction } from "@reduxjs/toolkit";

export const setCityError = createAction("setCityError");
export const newCityError = createAction("newCityError");
export const editCityError = createAction("editCityError");
export const deleteCityError = createAction("deleteCityError");

export const citySlice = createSlice({
  name: "city",
  initialState: {
    cities: [],
    loading: false,
  },
  reducers: {
    setCities: (state, action) => {
      return { ...state, cities: action.payload, loading: true };
    },
    newCity: (state, action) => {
      return { ...state, cities: [...state.cities, action.payload] };
    },
    editCity: (state, action) => {
      const cities = state.cities.map((city) => {
        if (city.id === action.payload.id) {
          city = action.payload;
        }
        return city;
      });
      return { ...state, cities: [...cities] };
    },
    deleteCity: (state, action) => {
      const cities = state.cities.filter(
        (city) => city.id !== action.payload.id
      );
      return { ...state, cities: [...cities] };
    },
    setLoading: (state, action) => {
      return { ...state, loading: action.payload };
    },
  },
});

export const { setCities, newCity, editCity, deleteCity, setLoading } =
  citySlice.actions;

export default citySlice.reducer;
