import { useState } from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import Offcanvas from "react-bootstrap/Offcanvas";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import SwitchButton from "../UI/SwitchButton/SwitchButton";
import { Role_Admin, Role_Manger, Role_Client } from "../../assets/data/Roles";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

function NavbarMenuIcon({ role, logout, firstName, lastName }) {
  const [show, setShow] = useState(false);
  const user = useSelector((state) => state.user.value);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <MenuIcon onClick={handleShow} className="text_color" />

      <Offcanvas
        show={show}
        onHide={handleClose}
        scroll={false}
        placement={"end"}
        backdrop={true}
        style={{ backgroundColor: "var(--body_background)" }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ width: "100%" }}>
            <div style={{ overflow: "auto", marginTop: "15px" }}>
              <p
                className="big-title"
                style={{ float: "left", fontSize: "35px" }}>
                <PersonIcon />
                {firstName + " " + lastName}
              </p>
              <p
                style={{
                  float: "right",
                  marginTop: "5px",
                  marginRight: "15px",
                }}></p>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {user?.roles[0] === Role_Client ? NavClient : <></>}
          {user?.roles[0] === Role_Manger ? NavManger : <></>}
          {user?.roles[0] === Role_Admin ? NavAdmin : <></>}

          <hr style={{ backgroundColor: "var(--text_color)" }} />
          <div className="text_color">
            <Link to="/profile" className="link nav_link link_active">
              <span
                className="text_color"
                style={{
                  marginTop: "15px",
                  marginLeft: "15px",
                  fontSize: "20px",
                }}>
                Profile
              </span>
            </Link>{" "}
          </div>
          <div className="text_color">
            <div style={{ overflow: "auto" }}>
              <p
                className="big-title"
                style={{
                  float: "left",
                  marginTop: "15px",
                  marginLeft: "15px",
                  fontSize: "20px",
                }}>
                light mode
              </p>
              <p
                style={{
                  float: "right",
                  marginTop: "15px",
                  marginRight: "15px",
                }}>
                <SwitchButton />
              </p>
            </div>
          </div>
          <hr style={{ backgroundColor: "var(--text_color)" }} />
          <div className="text_color" href="#action/3.4">
            <button
              style={{
                backgroundColor: "transparent",
                border: "transparent",
              }}
              onClick={logout}>
              <span
                className="text_color"
                style={{
                  marginTop: "15px",
                  marginLeft: "15px",
                  fontSize: "20px",
                }}>
                Se déconnecter
              </span>
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default NavbarMenuIcon;

const NavManger = (
  <>
    <Box style={{ marginLeft: "auto" }} borderRadius="9px">
      <Link to="/dashboard" className="link nav_link link_active">
        <Typography
          style={{ marginTop: "15px", marginLeft: "15px", fontSize: "20px" }}>
          {" "}
          <i className="ri-coin-line"></i> Dashboard{" "}
        </Typography>
      </Link>
    </Box>

    <Box style={{ marginLeft: "auto" }} borderRadius="9px">
      <Link to="/parc" className="link nav_link link_active">
        <Typography
          style={{ marginTop: "15px", marginLeft: "15px", fontSize: "20px" }}>
          {" "}
          <i className="ri-parking-box-line"></i> Parc{" "}
        </Typography>
      </Link>
    </Box>

    <Box style={{ marginLeft: "auto" }} borderRadius="9px">
      <Link to="/admin/calendar" className="link nav_link link_active">
        <Typography
          style={{ marginTop: "15px", marginLeft: "15px", fontSize: "20px" }}>
          {" "}
          <i className="ri-reserved-line"></i> Réservation{" "}
        </Typography>
      </Link>
    </Box>

    <Box style={{ marginLeft: "auto" }} borderRadius="9px">
      <Link to="/admin/treasry" className="link nav_link link_active">
        <Typography
          style={{ marginTop: "15px", marginLeft: "15px", fontSize: "20px" }}>
          <i className="ri-git-repository-private-line"></i> Caisse{" "}
        </Typography>
      </Link>
    </Box>

    <Box style={{ marginLeft: "auto" }} borderRadius="9px">
      <Link to="/admin/tools" className="link nav_link link_active">
        <Typography
          style={{ marginTop: "15px", marginLeft: "15px", fontSize: "20px" }}>
          {" "}
          <i className="ri-keyboard-line"></i> Outils{" "}
        </Typography>
      </Link>
    </Box>
  </>
);

const NavAdmin = (
  <>
    <Box style={{ marginLeft: "auto" }} borderRadius="9px">
      <Link to="/superadmin/dashboard" className="link nav_link link_active">
        <Typography
          style={{ marginTop: "15px", marginLeft: "15px", fontSize: "20px" }}>
          {" "}
          <i className="ri-coin-line"></i> Dashboard{" "}
        </Typography>
      </Link>
    </Box>

    <Box style={{ marginLeft: "auto" }} borderRadius="9px">
      <Link to="/superadmin/partner" className="link nav_link link_active">
        <Typography
          style={{ marginTop: "15px", marginLeft: "15px", fontSize: "20px" }}>
          {" "}
          <i className="ri-shake-hands-line"></i> Partenaires{" "}
        </Typography>
      </Link>
    </Box>

    <Box style={{ marginLeft: "auto" }} borderRadius="9px">
      <Link to="/superadmin/packages" className="link nav_link link_active">
        <Typography
          style={{ marginTop: "15px", marginLeft: "15px", fontSize: "20px" }}>
          {" "}
          <i className="ri-star-fill"></i> Forfaits{" "}
        </Typography>
      </Link>
    </Box>

    <Box style={{ marginLeft: "auto" }} borderRadius="9px">
      <Link to="/superadmin/brands" className="link nav_link link_active">
        <Typography
          style={{ marginTop: "15px", marginLeft: "15px", fontSize: "20px" }}>
          {" "}
          <i className="ri-home-gear-fill"></i> Marques{" "}
        </Typography>
      </Link>
    </Box>
    <Box style={{ marginLeft: "auto" }} borderRadius="9px">
      <Link to="/superadmin/users" className="link nav_link link_active">
        <Typography
          style={{ marginTop: "15px", marginLeft: "15px", fontSize: "20px" }}>
          {" "}
          <i className="fa-solid fa-id-badge"></i> Abonnés{" "}
        </Typography>
      </Link>
    </Box>

    <Box style={{ marginLeft: "auto" }} borderRadius="9px">
      <Link to="/superadmin/clients" className="link nav_link link_active">
        <Typography
          style={{ marginTop: "15px", marginLeft: "15px", fontSize: "20px" }}>
          {" "}
          <i className="fa-solid fa-person"></i> Clients{" "}
        </Typography>
      </Link>
    </Box>

    <Box style={{ marginLeft: "auto" }} borderRadius="9px">
      <Link to="/superadmin/cities" className="link nav_link link_active">
        <Typography
          style={{ marginTop: "15px", marginLeft: "15px", fontSize: "20px" }}>
          {" "}
          <i className="fa-solid fa-city"></i> Villes{" "}
        </Typography>
      </Link>
    </Box>
    <Box style={{ marginLeft: "auto" }} borderRadius="9px">
      <Link to="/superadmin/documents" className="link nav_link link_active">
        <Typography
          style={{ marginTop: "15px", marginLeft: "15px", fontSize: "20px" }}>
          {" "}
          <i className="ri-folder-line"></i> Documents{" "}
        </Typography>
      </Link>
    </Box>
    <Box style={{ marginLeft: "auto" }} borderRadius="9px">
      <Link to="/superadmin/maintenance" className="link nav_link link_active">
        <Typography
          style={{ marginTop: "15px", marginLeft: "15px", fontSize: "20px" }}>
          {" "}
          <i className="fa-solid fa-gear"></i> Entretient{" "}
        </Typography>
      </Link>
    </Box>
    <Box style={{ marginLeft: "auto" }} borderRadius="9px">
      <Link to="/superadmin/category" className="link nav_link link_active">
        <Typography
          style={{ marginTop: "15px", marginLeft: "15px", fontSize: "20px" }}>
          {" "}
          <i className="fa-sharp fa-solid fa-layer-group"></i> Catégorie{" "}
        </Typography>
      </Link>
    </Box>
  </>
);
const NavClient = <></>;
