import {
  login,
  logout,
  loginUserError,
  logoutUserError,
} from "../features/user";
import axios from "axios";
import config from "../assets/data/config.json";

const axiosInstance = axios.create({
  baseURL: `${config.url}`,
  timeout: 1000,
});

// axiosInstance.interceptors.request.use((conf) => {
//     conf.headers = { authorization: 'Bearer ' + sessionStorage.getItem('token') };
//     return conf;
// });

export const LoginUser = async (dispatch, token) => {
  try {
    // api call
    const { data } = await axiosInstance.get(
      `${config.url}/api/getCurrentUser`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    localStorage.setItem("userId", data.id);
    dispatch(login(data));
  } catch {
    dispatch(loginUserError());
  }
};

export const LogoutUser = async (dispatch) => {
  try {
    // api call
    dispatch(logout());
  } catch {
    dispatch(logoutUserError());
  }
};
