import "./App.css";
import Layout from "./Layout/Layout";
// import { StrictMode } from "react";
import "remixicon/fonts/remixicon.css";
import 'animate.css';

function App() {
  return (
    // <StrictMode>
      <div className="App">
        <Layout />
      </div>
    // </StrictMode>
  );
}

export default App;
