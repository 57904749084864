import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Container,
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import Helmet from "../../components/Helmet/Helmet";
import "animate.css";
import { useCookies } from "react-cookie";
import Form from "react-bootstrap/Form";
import config from "../../assets/data/config.json";

const NewCar = () => {
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [data, setData] = useState({
    vehicleType: "CAR",
    image: "",
    name: "",
    description: "",
    registration: null,
    occupationCapacity: null,
    speed: null,
    payPerDay: null,
    categoryId: 0,
    brandId: 0,
    state: true,
    engine: "",
    yearOfManufacture: 0,
    chassisNumber: 0,
    vehicleWeight: 0,
    color: "",
    fuel: "essence",
    driver: "Avec ou sans conducteur",
  });
  const handelChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  // const dataVerification = (data) => {
  //   for (const key in data) {
  //     if (data.hasOwnProperty(key) && data[key] === "") {
  //       return false;
  //     }
  //   }
  //   return true;
  // };

  const [isvalid, setisvalid] = useState(false);
  const [modal, setModal] = useState(false);
  const [currentFile, setCurrentFile] = useState("");
  const [file, setFile] = useState("");
  const toggle = () => setModal(!modal);
  const [cookies] = useCookies(["user"]);

  const submitHandler = async () => {
    if (true) {
      setisvalid(true);
      let formData = new FormData();
      formData.append("file", file);
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          formData.append(key, data[key]);
        }
      }
      try {
        await axios({
          method: "post",
          url: `${config.url}/api/addVehicle/${localStorage.getItem("userId")}`,
          data: formData,
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
            Authorization: `Bearer ${cookies.user}`,
          },
        });
        toggle();
      } catch (error) {
        setisvalid(false);
        toggle();
      }
    } else {
      setisvalid(false);
      toggle();
    }
  };
  const fileHandler = (e) => {
    setFile(e.target.files[0]);
    setCurrentFile(e.target.files[0]);
    const currentFile = e.target.files[0];
    const file = e.target.files[0];
    setData({
      ...data,
      image: file.name,
    });
    if (currentFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCurrentFile(reader.result);
      };
      reader.readAsDataURL(currentFile);
    }
  };
  useEffect(() => {
    axios
      .get(`${config.url}/categories`)
      .then((res) => {
        setCategories(res.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${config.url}/brands`)
      .then((res) => {
        console.warn(res.data);
        setBrands(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
    window.scrollTo(0, 0);
  }, []);

  return (
    <Helmet title="New car">
      <section>
        <Container>
          <Row>
          <Col lg="12">
          {data["image"] === "" ? (
                <i
                  className="ri-roadster-fill"
                  style={{ fontSize: "110px", color: "var(--blue_color)" }}></i>
              ) : (
                <img
                  src={currentFile}
                  alt="véhicule"
                  style={{ maxWidth: "300px", Height: "300px", margin: "50px" }}
                />
              )}
              <div className=" d-flex align-items-center gap-5 mb-5 ">
                <input
                  type="file"
                  onChange={(e) => fileHandler(e)}
                  accept="image/*"
                  id="file"
                  name="image"
                  className="btn_secondary input_file w-100"
                />
              </div>
          </Col>
            <Col lg="8">
              <div className="car__info">
                <div className=" d-flex align-items-center gap-5 mb-4 mt-3">
                  <FormGroup className="booking__form d-inline-block text-left">
                    <label className="text_color ">Nom du véhiclule :</label>
                    <input
                      type="text"
                      placeholder="Tapez le nom"
                      required
                      name="name"
                      defaultValue={data["name"]}
                      onChange={(e) => handelChange(e)}
                    />
                  </FormGroup>
                  <FormGroup className="booking__form d-inline-block text-left">
                    <label className="text_color">Matricule : </label>
                    <input
                      type="text"
                      onChange={(e) => handelChange(e)}
                      placeholder="Taper la matricule"
                      required
                      name="registration"
                      defaultValue={data["registration"]}
                    />
                  </FormGroup>
                </div>

                <div className=" d-flex align-items-center gap-5 mb-4 mt-3">
                  <FormGroup className="booking__form d-inline-block text-left ">
                    <label className="text_color">
                      Catégorie du véhicule :
                    </label>
                    <select
                      className="select_input"
                      onChange={(e) => handelChange(e)}
                      name="categoryId">
                      <option value={0} selected={true}>
                        Choisir le type de véhicule
                      </option>
                      {categories.map((category, index) => {
                        return (
                          <option
                            key={index}
                            value={parseInt(category.id)}
                            selected={
                              data["categoryId"] === parseInt(category.id)
                            }>
                            {category.name}
                          </option>
                        );
                      })}
                    </select>
                  </FormGroup>
                  <FormGroup className="booking__form d-inline-block text-left ">
                    <label className="text_color">Marque :</label>
                    <select
                      className="select_input"
                      onChange={(e) => handelChange(e)}
                      name="brandId">
                      <option value={0} selected={true}>
                        Choisir la marque
                      </option>
                      {brands.map((brand, index) => {
                        return (
                          <option
                            key={index}
                            value={parseInt(brand.id)}
                            selected={data["brandId"] === parseInt(brand.id)}>
                            {brand.name}
                          </option>
                        );
                      })}
                    </select>
                  </FormGroup>
                </div>
                <div className=" d-flex align-items-center gap-5 mb-4 mt-3">
                  <FormGroup className="booking__form d-inline-block text-left">
                    <label className="text_color ">Prix par jour CFA/j :</label>
                    <input
                      placeholder="Tapez le prix"
                      type="number"
                      required
                      name="payPerDay"
                      defaultValue={data["payPerDay"]}
                      onChange={(e) => handelChange(e)}
                    />
                  </FormGroup>
                  <FormGroup className="booking__form d-inline-block text-left ">
                    <label className="text_color">Carburateur :</label>
                    <select
                      className="select_input"
                      onChange={(e) => handelChange(e)}
                      name="fuel">
                      <option
                        value={"essence"}
                        selected={data["fuel"] === "essence"}>
                        {" "}
                        Essence (essence/pétrole)
                      </option>
                      <option
                        value={"gasole"}
                        selected={data["fuel"] === "gasole"}>
                        {" "}
                        Gazole (diesel)
                      </option>
                      <option value={"gaz"} selected={data["fuel"] === "gaz"}>
                        {" "}
                        Gaz liquide (GPL)
                      </option>
                      <option
                        value={"electrique"}
                        selected={data["fuel"] === "electrique"}>
                        {" "}
                        Electrique
                      </option>
                    </select>
                  </FormGroup>
                </div>
                <div className=" d-flex align-items-center gap-5 mb-4 mt-3">
                  <FormGroup className="booking__form d-inline-block text-left">
                    <label className="text_color ">Nombre de place :</label>
                    <input
                      placeholder="Tapez le nombre de place"
                      type="number"
                      required
                      name="occupationCapacity"
                      slot="1"
                      defaultValue={data["occupationCapacity"]}
                      onChange={(e) => handelChange(e)}
                    />
                  </FormGroup>
                  <FormGroup className="booking__form d-inline-block text-left">
                    <label className="text_color ">Vitesse :</label>
                    <input
                      placeholder="Taper le vitesse"
                      required
                      type="number"
                      name="speed"
                      defaultValue={data["speed"]}
                      onChange={(e) => handelChange(e)}
                    />
                  </FormGroup>
                </div>
              </div>
            </Col>
            <Col lg="4">
            <FormGroup className="booking__form w-100 mt-2 mb-3 text-left ">
                    <label className="text_color">Conducteur :</label>
                    <select
                      className="select_input"
                      onChange={(e) => handelChange(e)}
                      name="driver">
                      <option
                        value={"Avec ou sans conducteur"}
                        selected={data["driver"] === "Avec ou sans conducteur"}>
                        {" "}
                        Avec ou sans conducteur
                      </option>
                      <option
                        value={"Avec conducteur"}
                        selected={data["driver"] === "Avec conducteur"}>
                        {" "}
                        Avec conducteur
                      </option>
                      <option value={"Sans conducteur"} selected={data["driver"] === "Sans conducteur"}>
                        {" "}
                        Sans conducteur
                      </option>
                    </select>
                  </FormGroup>
                  <FormGroup className="text-left">
                <label className="text_color " style={{marginTop: "25px"}}>Description :</label>
                <Form.Control
                  as="textarea"
                  placeholder="Autre informations"
                  name="description"
                  className="textarea"
                  defaultValue={data["description"]}
                  onChange={(e) => handelChange(e)}
                  style={{ height: "290px"}}
                />
              </FormGroup>

              <div className="booking-info mt-5 mb-4">
                <button
                  onClick={(e) => submitHandler(e)}
                  className="btn_secondary">
                  enregistrer
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Ajouter une véhicule</ModalHeader>
        <ModalBody>
          {isvalid ? (
            <>
              {" "}
              <h2>Succés</h2>
              La Véhicule a été enregistrée avec succès
            </>
          ) : (
            <>
              Les données que vous avez saisi(e) n’est pas complet.S'il vous
              plaît, essayez de les compléter !
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {isvalid ? (
            <Button
              style={{ backgroundColor: "#EB9929" }}
              onClick={() => {
                toggle();
                window.location.reload(true);
              }}>
              Fermer
            </Button>
          ) : (
            <Button
              style={{ backgroundColor: "#EB9929" }}
              onClick={() => {
                toggle();
              }}>
              Fermer
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </Helmet>
  );
};

export default NewCar;
