import React, { useEffect } from "react";
// import axios from "axios";
import Helmet from "../../components/Helmet/Helmet";
import { Container, Row } from "reactstrap";
import ServicesList from "../../components/UI/ServicesList";
import Hero from "../../components/UI/hero/Hero";
import Awards from "../../components/UI/awards/Awards";
import QuestionSection from "../../components/UI/QuestionSection/QuestionSection";
import PlacesCarousel from "../../components/UI/PlacesCarousel/PlacesCarousel";
import Partenaires from "../../components/UI/Partenaires/Partenaires";
import Heading from "../../components/UI/Heading";
import VehiculeTypes from "../../components/UI/VehiculeTypes/VehiculeTypes";

const Home = () => {
  // const [carData, setCarData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      //  try {
      //    const result = await axios.get(`api/cars/getall`);
      //    if(typeof(result.data)!='string'){
      //    setCarData(result.data)}
      //  } catch(err) {
      //    console.log("Error!");
      //  }
    };
    fetchData();
  }, []);
  return (
    <Helmet title="Home">
      {/* ============= hero section =========== */}
      <section>
        <Hero />
      </section>
      {/* ========== services section ============ */}
      <Awards />
      <section>
        <Container>
          <Row>
            <Heading
              title="Explorez le monde depuis RANCAR"
              subtitle="On Vous Offre Des Services"
            />
            <ServicesList />
          </Row>
        </Container>

        {/* <SliderSection/> */}
        <VehiculeTypes />

        <Partenaires />
        <PlacesCarousel />

        <QuestionSection />
      </section>
      {/* =========== car offer section ============= */}
      {/* <div class="curved-div"></div> */}
    </Helmet>
  );
};

export default Home;
