import { createSlice, createAction } from "@reduxjs/toolkit";
export const loginUserError = createAction("setUserError");
export const logoutUserError = createAction("newUserError");

export const userSlice = createSlice({
  name: "user",
  initialState: {
    value: {
      roles: ["NO_ROLE"],
    },
  },
  reducers: {
    login: (state, action) => {
      state.value = action.payload;
    },
    logout: (state) => {
      state.value = {
        roles: ["NO_ROLE"],
      };
    },
  },
});

export const { login, logout } = userSlice.actions;
export default userSlice.reducer;
