import React from "react";
import CommonSection from "../../components/UI/CommonSection";
import Helmet from "../../components/Helmet/Helmet";
import AboutSection from "../../components/UI/AboutSection";
import { Container, Row, Col } from "reactstrap";
import aboutImg from "../../assets/images/banner1.jpg";
import "../../assets/styles/about.css";

const About = () => {
  return (
    <Helmet title="About">
      <CommonSection title="Nos Services" />
      <AboutSection aboutClass="aboutPage" />

      <section className="about__page-section text_color">
        <Container>
          <Row className="mt-3 mb-3">
            <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={aboutImg} alt="" className="w-100 rounded-3" />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
                <h2 className="section__title">
                  Nous nous engageons à fournir des solutions de conduite sûres
                </h2>

                <p className="section__description text-left">
                  C'est très agréable de disposer d'une véhicule pour faire des
                  excursions en Tunisie. RANCAR est une des possibilités dont on
                  peut profiter. Effectivement RANCAR vous permet de faire ces
                  excursions en se déplaçant vers les plus beaux sites de la
                  Tunisie, visiter les musées ou les sites ou les monuments
                  historiques et autres, RANCAR vous offre cette opportunité.
                  Etre libre et se déplacer à sa guise n'importe quand et
                  n'importe où, est une offre qui vous est dotée par RANCAR où
                  vous pourrez disposer d'une véhicule adéquate pour ces
                  déplacements à plein temps. Mais en ce qui concerne les prix,
                  RANCAR vous propose des prix plus que promotionnels.
                </p>

                <p className="section__description text-left">
                  Oui, c'est une excellente initiative ! Le fait de décider de
                  disposer d'une véhicule de location en Tunisie chez RANCAR
                  vous permet de réaliser, vous assure un gain de dépenses très
                  important. Cette formule de location de véhicule en tunisie
                  vous aide à faire des économies. Certes c'est une faculté dont
                  vous devez en profiter auprès de RANCAR qui vous attend.
                </p>

                <div className=" d-flex align-items-center gap-3 mt-4">
                  <span className="fs-4"></span>

                  <div>
                    <h6 className="section__subtitle text-left">
                      Besoin d’aide?
                    </h6>
                    <button className="btn_secondary">
                      <i class="ri-phone-line"></i>Contactez-nous
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default About;
