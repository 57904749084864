import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import config from "../assets/data/config.json";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LogoutUser } from "../services/User";
import { login } from "../features/user";
import Loader from "./../lib/Loader/Loader";

const Access = () => {
  const [cookie, setCookie, removeCookie] = useCookies(["user"]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function getCurrentUser() {
    if (false) setCookie(false);
    try {
      const { data } = await axios.get(`${config.url}/api/getCurrentUser`, {
        headers: { Authorization: `Bearer ${cookie["user"]}` },
      });
      dispatch(login(data));
    } catch (error) {
      console.log("Token expiré. Déconnexion en cours...");
      removeCookie("user");
      localStorage.removeItem("userId");
      LogoutUser(dispatch);
      navigate("/login");
    }
  }

  useEffect(() => {
    getCurrentUser();
  });

  return (
  <div style={{marginTop:"100px", marginBottom:"100px"}}>
    <Loader/>
  </div>
  );
};

export default Access;
