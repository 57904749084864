import React from "react";

export default function ConditionText() {
  return (
    <div className="text_color">
      <h4 style={{color:"var(--blue_color)"}}>Article 1. Caution :</h4>
      <span>
        Une caution est obligatoire pour toute location de véhicule. Le montant
        de la caution sera déterminé en fonction du type de véhicule loué et de
        la durée de location (cela va dépendre des conditions de l’agence de
        location). Le montant de la caution sera clairement indiqué dans le
        contrat de location et doit être payé avant la prise en charge du
        véhicule.
      </span>

      <h4 style={{color:"var(--blue_color)"}}>Article 2. Paiement de la Caution :</h4>
      <span>
        La caution peut être réglée par carte de crédit ou tout autre moyen de
        paiement accepté par l'agence de location.
      </span>

      <h4 style={{color:"var(--blue_color)"}}>Article 3. Remboursement de la Caution :</h4>
      <span>
        La caution sera remboursée dans les meilleurs délais après la
        restitution du véhicule, sous réserve de l'absence de dommages, de
        retards ou de frais supplémentaires. En cas de dommages au véhicule, de
        retard dans la restitution ou de frais supplémentaires, l'agence de
        location se réserve le droit de retenir tout ou partie de la caution
        pour couvrir les coûts.
      </span>

      <h4 style={{color:"var(--blue_color)"}}>Article 4. Responsabilité pour Dommages :</h4>
      <span>
        Le locataire est responsable des dommages causés au véhicule pendant la
        période de location. En cas de dommages, le montant des réparations
        nécessaires sera déduit de la caution. Si les frais dépassent la
        caution, le locataire sera tenu responsable du paiement du montant
        restant.
      </span>

      <h4 style={{color:"var(--blue_color)"}}>Article 5. Conditions de Location :</h4>
      <span>
        Le locataire doit respecter les conditions spécifiées, notamment les
        limitations de kilométrage, les restrictions d'utilisation, les règles
        de conduite, etc. Tout manquement à ces conditions peut entraîner la
        perte partielle ou totale de la caution.
      </span>

      <h4 style={{color:"var(--blue_color)"}}>Article 6. Modification ou Annulation :</h4>
      <span>
        Le locataire a la possibilité de changer la date de réservation ou
        d'annuler la location avant 48 heures sans frais. La caution sera
        entièrement remboursée dans ce cas. En cas de modification ou
        d'annulation après 48 heures, des frais d'annulation de 30% du montant
        total de la location seront appliqués.
      </span>

      <h4 style={{color:"var(--blue_color)"}}>Article 7. Options Supplémentaires (siège bébé) :</h4>
      <span>
        Un siège bébé est disponible moyennant des frais supplémentaires par
        jour par l’agence de location. Le locataire peut demander la location
        d'un siège bébé lors de la réservation du véhicule.
      </span>

      <h4 style={{color:"var(--blue_color)"}}>Article 8. Refus de Location :</h4>
      <span>
        L'agence de location se réserve le droit de refuser la location du
        véhicule si le locataire ne fournit pas une caution valide ou ne
        respecte pas les conditions de location. En acceptant ce contrat, le
        locataire reconnaît avoir lu, compris et accepté l'ensemble des
        conditions de location énoncées ci-dessus.
      </span>
    </div>
  );
}
