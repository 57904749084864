import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import DeleteModal from "../../../../lib/Modal/DeleteModal";
import AddEmplacementModal from "./AddEmplacementModal";
import { useParams } from "react-router-dom";
import Http from "./../../../../lib/http/http";
import { useSelector } from "react-redux";

export default function AvaiblityAccordion({ item }) {
  const user = useSelector((state) => state.user.value);
  const { slug } = useParams();

  const handleChangeAvaibity = async () => {
    await Http.post( `/api/updateVehicleState/${user?.id}/${slug}`);
  };
  const [show, setShow] = React.useState(false);
  const [idToDelete, setIdToDelete] = React.useState(0);
  const handleShow = (id) => {
    setIdToDelete(id);
    setShow(true);
  };
  const [regions,setRegions] = React.useState();
  const getRegions = React.useCallback(async () => {
    const res = await Http.get("/regionAvailabilityByVehicle/" + slug);
    setRegions(res.data);
  }, [slug]);
  
  React.useEffect(() => {
    getRegions();
  }, [getRegions, slug]);

  const title = "Vous voulez supprimez ce emplacement ?";
  return (
    <div>
      <FormGroup className="booking__form d-inline-block text-left">
        <label className="text_color ">Statut du véhiclule :</label>
        <select
          className="select_input"
          name="state"
          onChange={(e) => handleChangeAvaibity(e)}>
          <option value={true} selected={item.state === true}>
            Exposer sur le marché
          </option>

          <option value={false} selected={item.state === false}>
            retirer du marché
          </option>
        </select>
      </FormGroup>
      <table className="w-100 text-left">
        <thead className="text-left">
          <tr className="p-3 m-3">
            <td width="25%">Les zones de disponibilité géographique</td>
            <td width="25%">Action</td>
            <td width="32%"></td>
            <td width="20%">
              <AddEmplacementModal regions={regions} refreshFunction={getRegions}/>
            </td>
          </tr>
        </thead>
        <tbody className="text-left">
          {regions?.map((item, index) => {
            return (
              <tr key={index} className="p-3 m-3">
                <td className="mr-3"> {item.region.name} </td>
                <td className="text-center">
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleShow(item.id)}
                    color="error">
                    <DeleteIcon />
                  </IconButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <DeleteModal
        show={show}
        setShow={setShow}
        update={getRegions}
        url={"/regionAvailability/" + idToDelete}
        title={title}
      />
    </div>
  );
}
