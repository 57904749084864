import { useState, useEffect } from "react";
import config from "../assets/data/config.json";
import { useCookies } from "react-cookie";

const useFetchManager = (url) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);
  const [cookies] = useCookies(["user"]);

  useEffect(() => {
    fetch(config.url + url, {
      headers: {
        Authorization: `Bearer ${cookies["user"]}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("could not fetch the data for that resource");
        }
        return res.json();
      })
      .then((data) => {
        setIsPending(false);
        setData(data);
        setError(null);
      })
      .catch((err) => {
        setData([]);
        setIsPending(false);
        setError(err.message);
      });
  }, [url, cookies]);

  return { data, isPending, error };
};

export default useFetchManager;
