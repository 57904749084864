import { useState } from "react";
// import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Snackbar from "@mui/material/Snackbar";
// import config from "../../assets/data/config.json";
// import { Row, Col, FormGroup, Form } from "reactstrap";
import Alert from "@mui/material/Alert";

function InfoClientModal({ show, setShow, item }) {
  const [response, setResponse] = useState(false);
  // const [data, setData] = useState({
  //   abonnement: item?.abonnement,
  //   period: 12,
  // });
  // const handelChange = (event) => {
  //   setData({
  //     ...data,
  //     [event.target.name]: event.target.value,
  //   });
  // };

  // const handleSubmit = async () => {
  //   setShow(false);
  //   const response = await axios.delete(`${config.url}post/${id}`).then((res) => {
  //     getPosts();
  //     setResponse(true)
  //     handleOpenSnakBar()
  //   }).catch(function (error) {
  //   setResponse(false);
  //   handleOpenSnakBar();
  //   });
  // };

  const handleClose = () => {
    setShow(false);
    setResponse(false);
  };
  // const handleShow = () => setShow(true);

  const [open, setOpen] = useState(false);
  const handleCloseSnakBar = () => setOpen(false);
  // const handleOpenSnakBar = () => setOpen(true);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Compte </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text_color" width="20%">
            ID: {item?.id}
          </div>
          <div className="text_color" width="20%">
            Nom du propriétaire: {item?.firstName + " " + item?.lastName}
          </div>
          <div className="text_color" width="20%">
            E-mail: {item?.email}
          </div>
          <div className="text_color" width="20%">
            Télèphone: {item?.phone}
          </div>
          <div className="text_color" width="20%">
            Adresse: {item?.address ? item?.address : "vide"}{" "}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={handleClose}
            style={{ borderRadius: "55px" }}>
            Supprimer ce compte
          </Button>
          <Button
            variant="secondary"
            onClick={handleClose}
            style={{ borderRadius: "55px" }}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleCloseSnakBar}>
        {response ? (
          <Alert
            onClose={handleCloseSnakBar}
            severity="success"
            sx={{ width: "100%" }}>
            L'opération s'est terminée avec succès
          </Alert>
        ) : (
          <Alert
            severity="error"
            onClose={handleCloseSnakBar}
            sx={{ width: "100%" }}>
            Opération échouée !
          </Alert>
        )}
      </Snackbar>
    </>
  );
}

export default InfoClientModal;
