import React from "react";
import useFetchManager from "../../../hooks/useFetchManager";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function Example() {
  const { data } = useFetchManager(
    "/api/getReservationByVehicleByUser/" + localStorage.getItem("userId")
  );

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        layout="vertical"
        width={500}
        height={400}
        data={data}
        margin={{
          top: 20,
          right: 40,
          bottom: 20,
          left: 35,
        }}>
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis type="number" />
        <YAxis dataKey="name" type="category" scale="band" />
        <Tooltip />
        <Legend />
        {/* <Area dataKey="nombre" fill="#8884d8" stroke="#8884d8" /> */}
        <Bar dataKey="nombre" barSize={20} fill="var(--blue_color)" />
        {/* <Line dataKey="nombre" stroke="#ff7300" /> */}
      </ComposedChart>
    </ResponsiveContainer>
  );
}
