import React, { useState } from "react";
import { Col, FormGroup, Row } from "reactstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { EditPackage } from "../../services/Package";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../features/snackbar";
// import CustomizedSnackbar from "../../services/snackbar";

export default function UpdateModal({ data }) {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [item, setItem] = useState(data);
  const handleChange = (event) => {
    if (event.target.name === "priceA" || event.target.name === "priceS") {
      setItem({
        ...item,
        [event.target.name]: event.target.value,
      });
    } else {
      setItem({
        ...item,
        [event.target.name]: Boolean(Number(event.target.value)),
      });
    }
  };

  const handleSubmit = async () => {
    EditPackage(dispatch, item);
    const action = {
      snackbarOpen: true,
      snackbarType: "success",
      snackbarMessage: "L'opération s'est terminée avec succès",
    };
    dispatch(setSnackbar(action));
    handleClose();
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <IconButton aria-label="edit" onClick={handleShow} color="success">
        <EditIcon />
      </IconButton>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Forfait </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div className=" text_color" style={{ borderRadius: "55px" }}>
                <h5>Nom du forfait:</h5>
                <ul>
                  <FormGroup className="booking__form d-inline-block text-left w-100">
                    <input
                      type="text"
                      placeholder="taper le nom"
                      required
                      name="name"
                      defaultValue={item.name}
                      onChange={(e) => handleChange(e, item.id)}
                    />
                  </FormGroup>

                  <FormGroup className="booking__form d-inline-block text-left w-50">
                    <label className="text_color w-100 ">
                      prix du six mois:
                    </label>
                    <input
                      type="text"
                      placeholder="taper le nom"
                      required
                      name="priceS"
                      defaultValue={item.priceS}
                      onChange={(e) => handleChange(e, item.id)}
                    />
                  </FormGroup>
                  <FormGroup className="booking__form d-inline-block text-left w-50">
                    <label className="text_color w-100 ">prix par ans:</label>
                    <input
                      type="text"
                      placeholder="taper le nom"
                      required
                      name="priceA"
                      defaultValue={item.priceA}
                      onChange={(e) => handleChange(e, item.id)}
                    />
                  </FormGroup>
                  <FormGroup className="booking__form d-inline-block text-left w-50">
                    <label className="text_color w-100 ">
                      Parking Sécurisé:
                    </label>
                    <select
                      className="select_input"
                      onChange={(e) => handleChange(e)}
                      name="parc">
                      <option value={1} selected={item.parc === true}>
                        Autorisée
                      </option>

                      <option value={0} selected={item.parc === false}>
                        Bloquée
                      </option>
                    </select>
                  </FormGroup>
                  <FormGroup className="booking__form d-inline-block text-left w-50">
                    <label className="text_color w-100 ">
                      Gestion Facile des Réservations:
                    </label>
                    <select
                      className="select_input"
                      onChange={(e) => handleChange(e)}
                      name="reservation">
                      <option value={1} selected={item.reservation === true}>
                        Autorisée
                      </option>
                      <option value={0} selected={item.reservation === false}>
                        Bloquée
                      </option>
                    </select>
                  </FormGroup>
                  <FormGroup className="booking__form d-inline-block text-left w-50">
                    <label className="text_color w-100 ">
                      Notification Instantanée:
                    </label>
                    <select
                      className="select_input"
                      onChange={(e) => handleChange(e)}
                      name="notification">
                      <option value={1} selected={item.notification === true}>
                        Autorisée
                      </option>
                      <option value={0} selected={item.notification === false}>
                        Bloquée
                      </option>
                    </select>
                  </FormGroup>
                  <FormGroup className="booking__form d-inline-block text-left w-50">
                    <label className="text_color w-100 ">Caisse Rapide:</label>
                    <select
                      className="select_input"
                      onChange={(e) => handleChange(e)}
                      name="caisse">
                      <option value={1} selected={item.caisse === true}>
                        Autorisée
                      </option>
                      <option value={0} selected={item.caisse === false}>
                        Bloquée
                      </option>
                    </select>
                  </FormGroup>
                  <FormGroup className="booking__form d-inline-block text-left w-50">
                    <label className="text_color w-100 ">
                      Garage d'Entretien Professionnel:
                    </label>
                    <select
                      className="select_input"
                      onChange={(e) => handleChange(e)}
                      name="garage">
                      <option value={1} selected={item.garage === true}>
                        Autorisée
                      </option>
                      <option value={0} selected={item.garage === false}>
                        Bloquée
                      </option>
                    </select>
                  </FormGroup>
                </ul>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn_secondary h-50 p-2 "
            onClick={() => handleSubmit()}>
            Eregistrer
          </button>
          <Button
            variant="secondary"
            style={{ borderRadius: "55px" }}
            onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
