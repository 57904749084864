import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { useSelector } from "react-redux";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import { Theme, useTheme } from "@mui/material/styles";
// import MenuItem from "@mui/material/MenuItem";
// import config from "../../assets/data/config.json";
// import InputLabel from "@mui/material/InputLabel";
// import { Button, Modal, Input } from "react-bootstrap";
import { Row, Col, FormGroup } from "reactstrap";

export default function Step4({ data }) {
  const packages = useSelector((state) => state.package.packages);
  const plan = packages.find((item) => item.name === data["package"]);
  const price = parseInt(data["period"]) === "6" ? plan.priceS : plan.priceA;
  const taxe = 19;
  const totalHT = price * data["period"];
  const t = totalHT * (1 + taxe / 100);
  const total = t.toFixed(2);

  return (
    <div className="container">
      <Row>
        <Col className="mt-5 text_color">
          <div className="booking-info mt-5 ml-2">
            <h5 className="mb-4 fw-bold  ">Récapitulatif de votre commande</h5>
            <div className="text-left ml-1 font-weight-bold">
              <h6>
                Cette page est le récapitulatif détaillé de votre commande. Elle
                ne constitue pas une facture.
              </h6>
            </div>
            <div className="text-left">
              <FormGroup className="booking__form d-inline-block  ">
                <label className="text_color m-3 ">Nom :</label>
                <span>{data["firstName"]}</span>
              </FormGroup>
              <FormGroup className="booking__form d-inline-block ms-1 mb-4 ">
                <label className="text_color m-3">Prénom :</label>
                <span>{data["lastName"]}</span>
              </FormGroup>

              <FormGroup className="booking__form d-inline-block  ">
                <label className="text_color m-3">E-mail :</label>
                <span>{data["email"]}</span>
              </FormGroup>
              <FormGroup className="booking__form d-inline-block ms-1 mb-4 ">
                <label className="text_color m-3">Numéro de téléphone :</label>
                <span>{data["phone"]}</span>
              </FormGroup>
              <FormGroup className="booking__form d-inline-block  ">
                <label className="text_color m-3 ">Offre :</label>
                <span>{data["package"]}</span>
              </FormGroup>
              <FormGroup className="booking__form d-inline-block ms-1 mb-4 ">
                <label className="text_color m-3">Période :</label>
                <span>{data["period"]}</span>
              </FormGroup>

              <FormGroup className="booking__form d-inline-block  ">
                <label className="text_color m-3">Methode de payement :</label>
                <span>{data["method"]}</span>
              </FormGroup>
              <FormGroup className="booking__form d-inline-block ms-1 mb-4 ">
                <label className="text_color m-3">
                  Montant total hors TVA :
                </label>
                <span>{totalHT} $</span>
              </FormGroup>
              <FormGroup className="booking__form d-inline-block  ">
                <label className="text_color m-3">Taxe TVA :</label>
                <span>{taxe}%</span>
              </FormGroup>
              <FormGroup className="booking__form d-inline-block ms-1 mb-4 ">
                <label className="text_color m-3">Total (TVA incluse) :</label>
                <span>
                  {total} ${" "}
                  <span style={{ color: "var(--blue_color)" }}>
                    {" "}
                    ( Remises appliquées ){" "}
                  </span>
                </span>
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
