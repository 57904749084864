import { createSlice, createAction } from "@reduxjs/toolkit";

export const setTransactionError = createAction("setTransactionError");
export const newTransactionError = createAction("newTransactionError");
export const editTransactionError = createAction("editTransactionError");
export const deleteTransactionError = createAction("deleteTransactionError");

export const TransactionSlice = createSlice({
  name: "transaction",
  initialState: {
    transactions: [],
    loading: false,
  },
  reducers: {
    setTransaction: (state, action) => {
      return { ...state, transactions: action.payload, loading: true };
    },
    newTransaction: (state, action) => {
      return {
        ...state,
        transactions: [action.payload, ...state.transactions],
      };
    },
    editTransaction: (state, action) => {
      const transaction = state.transactions.map((transaction) => {
        if (transaction.id === action.payload.id) {
          transaction = action.payload;
        }
        return transaction;
      });
      return { ...state, transactions: [...transaction] };
    },
    deleteTransaction: (state, action) => {
      const transaction = state.transactions.filter(
        (transaction) => transaction.id !== action.payload.id
      );
      return { ...state, transactions: [...transaction] };
    },
    setLoading: (state, action) => {
      return { ...state, loading: action.payload };
    },
  },
});

export const {
  setTransaction,
  newTransaction,
  editTransaction,
  deleteTransaction,
  setLoading,
} = TransactionSlice.actions;

export default TransactionSlice.reducer;
