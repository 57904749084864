import { createSlice, createAction } from "@reduxjs/toolkit";

export const setPartnerError = createAction("setPartnerError");
export const newPartnerError = createAction("newPartnerError");
export const editPartnerError = createAction("editPartnerError");
export const deletePartnerError = createAction("deletePartnerError");

export const partnerSlice = createSlice({
  name: "partner",
  initialState: {
    partners: [],
    loading: false,
  },
  reducers: {
    setPartners: (state, action) => {
      return { ...state, partners: action.payload, loading: true };
    },
    newPartner: (state, action) => {
      return { ...state, partners: [...state.partners, action.payload] };
    },
    editPartner: (state, action) => {
      const partners = state.partners.map((partner) => {
        if (partner.id === action.payload.id) {
          partner = action.payload;
        }
        return partner;
      });
      return { ...state, partners: [...partners] };
    },
    deletePartner: (state, action) => {
      const partners = state.partners.filter(
        (partner) => partner.id !== action.payload.id
      );
      return { ...state, partners: [...partners] };
    },
    setLoading: (state, action) => {
      return { ...state, loading: action.payload };
    },
  },
});

export const {
  setPartners,
  newPartner,
  editPartner,
  deletePartner,
  setLoading,
} = partnerSlice.actions;

export default partnerSlice.reducer;
