import React from "react";
import { Puff } from "react-loader-spinner";

export default function Loader() {
  return (
    <div className="centered-element">
      <Puff
        height="80"
        width="80"
        radius="9"
        color="var(--blue_color)"
        ariaLabel="loading"
        wrapperStyle
        wrapperClass
      />
    </div>
  );
}
