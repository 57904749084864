import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

const TitleHero = ({ title, subtitle }) => {
  const matches = useMediaQuery("(min-width:1400px)");

  return (
    <>
      <div className={matches ? "heading mb-3 " : "heading mb-3"}>
        <h1 className="title_hero" style={{ color: "050505" }}>
          {title}
        </h1>
        <p className="text_color">{subtitle}</p>
      </div>
    </>
  );
};

export default TitleHero;
