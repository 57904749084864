import { createSlice } from "@reduxjs/toolkit";

export const themeSlice = createSlice({
  name: "theme",
  initialState: { value: { background: "transparent", color: "red" } },
  // initialState:{value:{background:"#FBFBFB",color:"red"}},
  reducers: {
    light: (state) => {
      state.value = { background: "white", color: "black" };
    },
    dark: (state) => {
      state.value = { background: "black", color: "white" };
    },
  },
});

export const { light, dark } = themeSlice.actions;
export default themeSlice.reducer;
