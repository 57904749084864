import { createSlice } from "@reduxjs/toolkit";

export const managerSlice = createSlice({
  name: "manager",
  initialState: {
    managers: [],
    loading: false,
  },
  reducers: {
    setManagers: (state, action) => {
      return { ...state, managers: action.payload, loading: true };
    },
    newManger: (state, action) => {
      return { ...state, managers: [...state.managers, action.payload] };
    },
    editManager: (state, action) => {
      const managers = state.managers.map((manager) => {
        if (manager.id === action.payload.id) {
          manager = action.payload;
        }
        return manager;
      });
      return { ...state, managers: [...managers] };
    },
    deleteManager: (state, action) => {
      const managers = state.managers.filter(
        (manager) => manager.id !== action.payload.id
      );
      return { ...state, managers: [...managers] };
    },
    setLoading: (state, action) => {
      return { ...state, loading: action.payload };
    },
  },
});

export const {
  setManagers,
  newManager,
  editManager,
  deleteManager,
  setLoading,
} = managerSlice.actions;

export default managerSlice.reducer;
