import * as React from "react";
import { useEffect, useState } from "react";
import TablePagination from "@mui/material/TablePagination";
import axios from "axios";
import { IconButton } from "@mui/material";
import config from "../../assets/data/config.json";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomModal from "./ZoomModal";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import LogoutIcon from "@mui/icons-material/Logout";
import Loader from "../../lib/Loader/Loader";
import AddTransaction from "./AddTransaction";
import Chip from "@mui/material/Chip";
import { useDispatch, useSelector } from "react-redux";
import { GetTransaction } from "../../services/Transaction";
import dayjs from "dayjs";
import ProtectedRoute from "./ProtectedRoute";
import { useCookies } from "react-cookie";
import Tooltip from "@mui/material/Tooltip";

const TruncateText = ({ text }) => {
  if (!text) {
    return null;
  }

  const maxLength = 30;

  if (text.length <= maxLength) {
    return <span>{text}</span>;
  }

  const truncatedText = `${text.slice(0, maxLength)}...`;

  return <span>{truncatedText}</span>;
};

export default function TreasuryTable() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.transaction.transactions);
  const isPending = useSelector((state) => state.transaction.loading);
  const [cookies] = useCookies(["user"]);

  useEffect(() => {
    GetTransaction(dispatch, cookies["user"]);
  }, [dispatch, cookies]);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [minIndex, setMinIndex] = React.useState(0);
  const maxIndex = minIndex + rowsPerPage;
  const [totalRows, setTotalRows] = React.useState(0);

  const [page, setPage] = React.useState(0);

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setMinIndex(newPage * rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getPosts = async () => {
    await axios.get(`${config.url}post/numberOfPost`).then((res) => {
      setTotalRows(res.data);
    });
    await axios.get(`${config.url}post/row=${minIndex}/page=${maxIndex}`);
  };
  // useEffect(() => {
  //   // getPosts();
  // }, [rowsPerPage, minIndex]);

  const [show, setShow] = useState(false);
  const [itemSelected, setItemSelected] = useState();
  const handleShow = (item) => {
    setItemSelected(item);
    setShow(true);
  };

  return (
    <ProtectedRoute>
      <ZoomModal
        show={show}
        setShow={setShow}
        item={itemSelected}
        getPosts={getPosts}
      />
      <div className="container_X text_center text_color shadow ">
        <div>
          <div style={{ overflow: "auto", margin: "20px" }}>
            <p
              className="big-title"
              style={{ float: "left", fontSize: "35px" }}>
              Caisse
            </p>
            <p style={{ float: "right", color: "#004a9e" }}></p>
          </div>
        </div>
        <div
          style={{
            background: "var(--body_background)",
            margin: "20px 20px 0px 20px",
            borderBlockEnd: "1px #e4e4e4 solid",
          }}>
          <table style={{ margin: "0 auto" }}>
            <thead className="Thead">
              <tr>
                <td width="10%">ID</td>
                <td width="20%">Type de transaction</td>
                <td width="10%">Date</td>
                <td width="30%">Note</td>
                <td width="15%">Montant</td>
                <td width="15%">Action</td>
              </tr>
              <tr>
                <td className="td_X"></td>
                <td className="td_X"></td>
                <td className="td_X"></td>
                <td className="td_X"></td>
                <td className="td_X"></td>
                <td className="td_X">
                  <AddTransaction />
                </td>
              </tr>
            </thead>

            {isPending ? (
              <tbody>
                {data?.map((item, index) => (
                  <tr key={index}>
                    <td className="td_X">{item?.id}</td>
                    <td className="td_X">
                      {" "}
                      <Chip
                        label={item?.type}
                        color={item?.type === "credit" ? "success" : "error"}
                        icon={
                          item?.type === "credit" ? (
                            <SaveAltIcon color="success" />
                          ) : (
                            <LogoutIcon color="error" />
                          )
                        }
                      />{" "}
                    </td>
                    {/* <td className='td_X'>{item?.type} <Chip variant="outlined" color={item?.type==="debit"?"succes":"error"} icon={item?.type==="debit"? <SaveAltIcon color="success"/>:<LogoutIcon color="error"/>} ></Chip> </td> */}
                    <td className="td_X">
                      {dayjs(item?.date).format("YYYY-MM-DD")}
                    </td>
                    <td className="td_X">
                    <TruncateText text={item?.note} />
                    </td>
                    <td className="td_X">{item?.cost}</td>
                    <td className="td_X">
                      <Tooltip title="détails" placement="bottom">
                        <IconButton
                          aria-label="zoom"
                          color="primary"
                          onClick={() => handleShow(item)}
                          name="zoom">
                          <ZoomInIcon />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : null}
          </table>
          {isPending ? <></> : <Loader />}
          {data?.length === 0 && isPending ? (
            <div style={{ height: "100px", padding: "70px 0" }}>
              Actuellement, aucune information n'est disponible
            </div>
          ) : (
            <></>
          )}
        </div>
        <div
          style={{
            background: "var(--body_background)",
            margin: "0px 0px 0px 0px",
          }}>
          <TablePagination
            className="text_color"
            labelRowsPerPage="Lignes par page :"
            component="div"
            localisation="fr"
            count={totalRows}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </ProtectedRoute>
  );
}
