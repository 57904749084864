import { createSlice, createAction } from "@reduxjs/toolkit";

export const setCalendarEventError = createAction("setCalendarEventError");
export const newCalendarEventError = createAction("newCalendarEventError");
export const editCalendarEventError = createAction("editCalendarEventError");
export const deleteCalendarEventError = createAction(
  "deleteCalendarEventError"
);

export const CalendarEventSlice = createSlice({
  name: "calendarEvent",
  initialState: {
    calendarEvents: [],
    loading: false,
    event: { user: {}, vehicle: { brand: {}, category: {} } },
  },
  reducers: {
    setEvent: (state, action) => {
      return { ...state, event: action.payload, loading: true };
    },
    setCalendarEvent: (state, action) => {
      return { ...state, calendarEvents: action.payload, loading: true };
    },
    newCalendarEvent: (state, action) => {
      return {
        ...state,
        calendarEvents: [...state.calendarEvents, action.payload],
      };
    },
    editCalendarEvent: (state, action) => {
      const calendarEvent = state.calendarEvents.map((event) => {
        if (event.id === action.payload.id) {
          event = action.payload;
        }
        return event;
      });
      return { ...state, calendarEvents: [...calendarEvent] };
    },
    deleteCalendarEvent: (state, action) => {
      const calendarEvents = state.calendarEvents.filter(
        (event) => event.id !== action.payload.id
      );
      return { ...state, calendarEvents: [...calendarEvents] };
    },
    setLoading: (state, action) => {
      return { ...state, loading: action.payload };
    },
  },
});

export const {
  setCalendarEvent,
  newCalendarEvent,
  editCalendarEvent,
  deleteCalendarEvent,
  setEvent,
  setLoading,
} = CalendarEventSlice.actions;

export default CalendarEventSlice.reducer;
