import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Row, FormGroup } from "reactstrap";
import { setSnackbar } from "../../features/snackbar";
import { useDispatch } from "react-redux";
import { NewMaintenanceType } from "../../services/MaintenanceType";

function AddModal() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [data, setData] = useState({
    name: "",
    description: "",
  });
  const handelChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleEmptyData=()=>{
    setData(
      {
        name: "",
        description: "",
      }
    );
  }
  const handleSubmit = async (e) => {
    NewMaintenanceType(dispatch, data);
    const action = {
      snackbarOpen: true,
      snackbarType: "success",
      snackbarMessage: "L'opération s'est terminée avec succès",
    };
    dispatch(setSnackbar(action));
    handleClose();
    handleEmptyData();
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <button className="btn_secondary" onClick={handleShow}>
        Ajouter
      </button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Entretien périodique  </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <FormGroup className="booking__form d-inline-block text-left">
              <label className="text_color ">Nom de l'entretien périodique :</label>
              <input
                type="text"
                placeholder="Tapez le nom"
                required
                name="name"
                defaultValue={data["name"]}
                onChange={(e) => handelChange(e)}
              />
            </FormGroup>
            <FormGroup className="booking__form d-inline-block text-left">
              <label className="text_color ">Description de l'entretien périodique </label>
              <input
                type="textarea"
                placeholder="Autre informations"
                name="description"
                defaultValue={data["description"]}
                onChange={(e) => handelChange(e)}
                rows="6"
                className="textarea w-100"></input>
            </FormGroup>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn_secondary h-50 p-2"
            onClick={() => handleSubmit()}>
            Eregistrer
          </button>
          <Button
            variant="secondary"
            style={{ borderRadius: "55px" }}
            onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddModal;
