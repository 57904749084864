import * as React from "react";
import { useEffect, useState } from "react";
import TablePagination from "@mui/material/TablePagination";
// import axios from "axios";
import { Search } from "@mui/icons-material";
import { Box, IconButton, InputBase } from "@mui/material";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import config from "../../assets/data/config.json";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import InfoClientModal from "./InfoClientModal";
// import { Link } from "react-router-dom";
import Loader from "../../lib/Loader/Loader";
// import CancelIcon from "@mui/icons-material/Cancel";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch, useSelector } from "react-redux";
import { GetCustomers } from "../../services/Customer";

export default function Clients() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.customer.customers);

  const isPending = useSelector((state) => state.customer.loading);

  useEffect(() => {
    GetCustomers(dispatch);
  }, [dispatch]);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [minIndex, setMinIndex] = React.useState(0);
  // const maxIndex = minIndex + rowsPerPage;
  // const [totalRows, setTotalRows] = React.useState(0);
  const totalRows = 0;

  const [page, setPage] = React.useState(0);

  const handleChangePage = (newPage) => {
    setPage(newPage);
    setMinIndex(newPage * rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getPosts = async () => {
    // await axios.get(`${config.url}post/numberOfPost`).then((res) => {
    //   setTotalRows(res.data);
    // });
    // await axios
    //   .get(`${config.url}post/row=${minIndex}/page=${maxIndex}`)
    //   .then((res) => {
    //     setData(res.data);
    //   });
  };
  useEffect(() => {
    // getPosts();
  }, [rowsPerPage, minIndex]);

  const [show, setShow] = useState(false);
  const [idItemSelected, setIdItemSelected] = useState();
  const handleShow = (item) => {
    setIdItemSelected(item);
    setShow(true);
  };

  const handleSearch = async (e) => {
    // if (e.target.value) {
    //   await axios
    //     .get(
    //       `${config.url}post/row=${minIndex}/page=${maxIndex}/${e.target.name}=${e.target.value}`
    //     )
    //     .then((res) => {
    //       setData(res.data);
    //       setisPending(true);
    //     });
    // } else {
    //   getPosts();
    // }
  };

  return (
    <>
      <InfoClientModal
        show={show}
        setShow={setShow}
        item={idItemSelected}
        getPosts={getPosts}
      />
      <div className="container_X text_center text_color shadow ">
        <div>
          <div style={{ overflow: "auto", margin: "20px" }}>
            <p
              className="big-title"
              style={{ float: "left", fontSize: "35px" }}>
              Clients
            </p>
            <p style={{ float: "right", color: "#004a9e" }}></p>
          </div>
        </div>
        <div
          style={{
            background: "var(--body_background)",
            margin: "20px 20px 0px 20px",
            borderBlockEnd: "1px #e4e4e4 solid",
          }}>
          <table style={{ margin: "0 auto" }}>
            <thead className="Thead">
              <tr>
                <td width="20%">ID</td>
                <td width="20%">Nom et prénom</td>
                <td width="20%">E-mail</td>
                <td width="20%">télèphone</td>
                <td width="20%">Action</td>
              </tr>
              <tr>
                <td className="td_X">
                  <Box
                    borderRadius="9px"
                    gap="3rem"
                    className="searchBar"
                    p="0.1rem 1.5rem">
                    <InputBase
                      placeholder="Recherche..."
                      className="col-11"
                      onChange={(e) => handleSearch(e)}
                      name="id"
                    />
                    <IconButton className="col-1">
                      <Search />
                    </IconButton>
                  </Box>
                </td>
                <td className="td_X">
                  <Box
                    borderRadius="9px"
                    gap="3rem"
                    className="searchBar"
                    p="0.1rem 1.5rem">
                    <InputBase
                      placeholder="Recherche..."
                      className="col-11"
                      onChange={(e) => handleSearch(e)}
                      name="name"
                    />
                    <IconButton className="col-1">
                      <Search />
                    </IconButton>
                  </Box>
                </td>
                <td className="td_X">
                  <Box
                    borderRadius="9px"
                    gap="3rem"
                    className="searchBar"
                    p="0.1rem 1.5rem">
                    <InputBase placeholder="Recherche..." className="col-11" />
                    <IconButton className="col-1">
                      <Search />
                    </IconButton>
                  </Box>
                </td>
                <td className="td_X">
                  <Box
                    borderRadius="9px"
                    gap="3rem"
                    className="searchBar"
                    p="0.1rem 1.5rem">
                    <InputBase placeholder="Recherche..." className="col-11" />
                    <IconButton className="col-1">
                      <Search />
                    </IconButton>
                  </Box>
                </td>
                <td className="td_X">
                  {/* <Link to="/newcar">
                                <button className='btn_secondary'>Ajouter</button>
                                </Link> */}
                </td>
              </tr>
            </thead>

            {isPending ? (
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td className="td_X">{item.id}</td>
                    <td className="td_X">
                      {item.firstName + " " + item.lastName}
                    </td>
                    <td className="td_X">{item.email}</td>
                    <td className="td_X">{item.phone} </td>
                    <td className="td_X">
                      <IconButton
                        onClick={(e) => handleShow(item)}
                        aria-label="edit">
                        <ZoomInIcon style={{ color: "var(--blue_color)" }} />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <></>
            )}
          </table>
          {isPending ? <></> : <Loader />}
        </div>
        <div
          style={{
            background: "var(--body_background)",
            margin: "0px 0px 0px 0px",
          }}>
          <TablePagination
            className="text_color"
            labelRowsPerPage="Lignes par page :"
            component="div"
            localisation="fr"
            count={totalRows}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
}
