import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/user";
import themeReducer from "./features/theme";
import cityReducer from "./features/city";
import snackbarReducer from "./features/snackbar";
import documentTypeReducer from "./features/documentType";
import maintenanceTypeReducer from "./features/maintenanceType";
import CategoryReducer from "./features/category";
import CalendarEventReducer from "./features/calendarEvent";
import brandReducer from "./features/brand";
import partnerReducer from "./features/partner";
import transactionReducer from "./features/transaction";
import packageReducer from "./features/package";
import subscriptionReducer from "./features/subscription";
import customerReducer from "./features/customer";
import managerReducer from "./features/manager";

import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["user", "theme"],
};
const reducer = combineReducers({
  user: userReducer,
  theme: themeReducer,
  city: cityReducer,
  snackbar: snackbarReducer,
  document: documentTypeReducer,
  maintenances: maintenanceTypeReducer,
  category: CategoryReducer,
  calendarEvent: CalendarEventReducer,
  brand: brandReducer,
  transaction: transactionReducer,
  partner: partnerReducer,
  package: packageReducer,
  subscription: subscriptionReducer,
  customer: customerReducer,
  manager: managerReducer,
});
const persistedReducer = persistReducer(persistConfig, reducer);

export const Store = configureStore({
  reducer: persistedReducer,
});
