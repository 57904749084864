import {
  setMaintenanceType,
  newMaintenanceType,
  editMaintenanceType,
  deleteMaintenanceType,
  setLoading,
} from "../features/maintenanceType";
import { setSnackbar } from "../features/snackbar";
import Http from "../lib/http/http";

// const API_BASE_URL = '/api';
const USER_ID_KEY = "userId";

const getUserId = () => {
  const userId = localStorage.getItem(USER_ID_KEY);
  if (!userId) {
    throw new Error("User ID not found in localStorage");
  }
  return userId;
};

const handleApiError = (dispatch, errorType, errorMessage) => {
  const action = {
    snackbarOpen: true,
    snackbarType: errorType,
    snackbarMessage: errorMessage,
  };
  dispatch(setSnackbar(action));
};

export const GetMaintenanceType = async (dispatch) => {
  try {
    // api call
    const { data } = await Http.get("/maintenanceTypes");
    dispatch(setMaintenanceType(data));
  } catch {
    handleApiError(
      dispatch,
      "error",
      "Erreur lors du chargement des entretiens periodiques"
    );
  }
};

export const NewMaintenanceType = async (dispatch, MaintenanceType) => {
  try {
    dispatch(setLoading(false));
    const userId = getUserId();
    const { data } = await Http.post(
      "/api/addMaintenanceType/" + userId,
      MaintenanceType
    );
    dispatch(newMaintenanceType(data));
    handleApiError(dispatch, "success", "La ville a été soumise avec succès!");
    dispatch(setLoading(true));
  } catch {
    handleApiError(
      dispatch,
      "error",
      "Erreur lors de la soumission de l'entretien!"
    );
  }
};

export const EditMaintenanceType = async (dispatch, MaintenanceType) => {
  try {
    dispatch(setLoading(false));
    const userId = getUserId();
    const { data } = await Http.post(
      "/api/updateMaintenanceType/" + userId + "/" + MaintenanceType.id,
      MaintenanceType
    );
    dispatch(editMaintenanceType(data));
    handleApiError(
      dispatch,
      "success",
      "La modification a été soumise avec succès!"
    );
    dispatch(setLoading(true));
  } catch {
    handleApiError(
      dispatch,
      "error",
      "Erreur lors de la soumission de la modification!"
    );
  }
};

export const DeleteMaintenanceType = async (dispatch, MaintenanceType) => {
  try {
    dispatch(setLoading(false));
    const userId = getUserId();
    await Http.delete(
      "/api/maintenanceType/" + userId + "/" + MaintenanceType.id
    );
    dispatch(deleteMaintenanceType(MaintenanceType));
    handleApiError(
      dispatch,
      "success",
      "La suppression a été soumise avec succès!"
    );
    dispatch(setLoading(true));
  } catch {
    handleApiError(
      dispatch,
      "error",
      "Erreur lors de la soumission de la suppression!"
    );
  }
};
