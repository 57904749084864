import * as React from "react";
import AddMaintenanceModal from "./AddMaintenanceModal";
import Http from "./../../../../lib/http/http"
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

export default function MaintenanceAccordion() {

  const { slug } = useParams();
  const toDay = new Date();

  const calculateDateDifference = (date1, date2) => {
    const firstDate = dayjs(date1);
    const secondDate = dayjs(date2);
    const differenceInDays = secondDate.diff(firstDate, 'day');
    return differenceInDays;
  };

  const [maintenances,setMaintenances] = React.useState();
  const getMaintenances = React.useCallback(async () => {
    const res = await Http.get('/maintenanceByVehicle/' + slug);
    setMaintenances(res.data);
  }, [slug]);
  
  React.useEffect(() => {
    getMaintenances();
  }, [getMaintenances, slug]);
  return (
    <div>
      <table className="w-100 text-left">
        <thead className="text-left">
          <tr className="p-3 m-3">
          <td width="10%"></td>
          <td width="25%">L'entretien périodique</td>
            <td className="text-left" width="15%">
              Date opérationnelle
            </td>
            <td className="text-left" width="15%">
              Date de validité
            </td>
            <td className="text-left" width="15%">
              Frais associés
            </td>
            <td width="35%">Note</td>
            <td width="15%">
              <AddMaintenanceModal refreshFunction={getMaintenances}/>
            </td>
          </tr>
        </thead>
        <tbody className="text-left">
          {maintenances?.map((item, index) => {
            return (<>
             {
              item.notificationEnabled===true && calculateDateDifference(toDay,item.nextDate)<=0? (<>
               <tr className="p-3 m-3 " style={{color :"red"}} key={index}>
               <td className="mr-3 animate__animated animate__swing  animate__infinite"> {item.notificationEnabled===true? <NotificationsActiveIcon/>:null} </td>
                <td className="mr-3"> {item.type.name} </td>
                <td className="text-left">
                  {dayjs(item.date).format("DD/MM/YYYY")}
                </td>
                <td className="text-left">
                  {dayjs(item.nextDate).format("DD/MM/YYYY")}
                </td>
                <td className="mr-3"> {item.cost} </td>
                <td className="mr-3"> {item.note} </td>
              </tr>
              </>):(<>
               <tr className="p-3 m-3" key={index}>
               <td className="mr-3"> {item.notificationEnabled===true? <NotificationsNoneIcon/>:<NotificationsOffIcon/>} </td>
                <td className="mr-3"> {item.type.name} </td>
                <td className="text-left">
                  {dayjs(item.date).format("DD/MM/YYYY")}
                </td>
                <td className="text-left">
                  {dayjs(item.nextDate).format("DD/MM/YYYY")}
                </td>
                <td className="mr-3"> {item.cost} </td>
                <td className="mr-3"> {item.note} </td>
              </tr>
              </>)}
             
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
