import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import "./PlacesCarousel.css";
// import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../assets/data/config.json";
import { GetCities } from "../../../services/City";

const ImageCard = () => {
  // const matches = useMediaQuery("(min-width:1400px)");

  const dispatch = useDispatch();
  const cities = useSelector((state) => state.city.cities);

  // const isPending = useSelector((state) => state.city.loading);
  const slides = cities.slice(0, 6);

  useEffect(() => {
    GetCities(dispatch);
  }, [dispatch]);
  return (
    <Row xl="3" xs="1" className="mt-3 m-1 mb-3 p-5 text_color">
      {slides.map((item, index) => (
        <Col className="mb-4 container_places">
          <div className="box w-100 h-100 featured_card rounded">
            <img
              src={config.images_city + "/" + item.image}
              alt={item.image}
              loading="lazy"
              key={index}
              className="w-100 image_places"
            />
            <div className="middle_places w-100">
              <div className="text_places w-100">{item.name}</div>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default ImageCard;
