import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.value);
  const status = user.subscription ? user.subscription.status : false;
  const caisse = user.subscription ? user.subscription.package.caisse : false;

  useEffect(() => {
    if (!status || !caisse) {
      navigate("/outofservice");
    }
  }, [status, caisse, navigate]);

  return status && caisse ? children : null;
};

export default ProtectedRoute;
