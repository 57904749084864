import React, { useState } from "react";
import { Button, Row, Col } from "reactstrap";
import Modal from "react-bootstrap/Modal";
import { FormGroup } from "reactstrap";
import { useDispatch } from "react-redux";
import { NewTransaction } from "../../services/Transaction";
import { useCookies } from "react-cookie";

export default function AddTransaction() {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    user: localStorage.getItem("userId"),
    type: "debit",
    date: "",
    cost: 0,
    note: "",
  });
  const handelChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const [modal, setModal] = useState(false);
  const handleClose = () => setModal(false);
  const handleOpen = () => setModal(true);
  const [cookies] = useCookies(["user"]);

  const handleSubmit = () => {
    NewTransaction(dispatch, data, cookies["user"]);
    handleClose();
  };

  return (
    <div>
      <button className="btn_secondary" onClick={handleOpen}>
        Ajouter
      </button>
      <Modal show={modal} onHide={handleClose} size="lg">
        <Modal.Header closeButton className="body_color text_color">
          <Modal.Title>Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body className="body_color">
          <Row>
            <Col>
              <FormGroup className="booking__form d-inline-block w-100">
                <label className="text_color">Type:</label>
                <select
                  className="select_input"
                  onChange={(e) => handelChange(e)}
                  name="type">
                  <option value={"debit"} selected={data["type"] === "debit"}>
                    Debit
                  </option>
                  <option value={"credit"} selected={data["type"] === "credit"}>
                    Credit
                  </option>
                </select>
              </FormGroup>

              <FormGroup className="booking__form d-inline-block w-50 ">
                <label className="text_color">Montant d'operation:</label>
                <input
                  type="number"
                  name="cost"
                  onChange={(e) => handelChange(e)}
                />
              </FormGroup>
              <FormGroup className="booking__form d-inline-block w-50 ">
                <label className="text_color">Date d'operation:</label>
                <input
                  type="date"
                  name="date"
                  onChange={(e) => handelChange(e)}
                />
              </FormGroup>

              <FormGroup className="booking__form d-inline-block w-100 ">
                <label className="text_color">Autre information:</label>
                <input
                  type="textarea"
                  name="note"
                  onChange={(e) => handelChange(e)}
                />
              </FormGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "var(--body_background)" }}>
          <button className="btn_modal" onClick={handleSubmit}>
            Sauvgarder
          </button>
          <Button
            color="secondary"
            style={{ borderRadius: "55px" }}
            onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
