import React, { useState } from "react";
// import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Snackbar from "@mui/material/Snackbar";
// import config from "../../assets/data/config.json";
import { FormGroup } from "reactstrap";
import Alert from "@mui/material/Alert";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import dayjs from "dayjs";

function UpdateModal({ show, setShow, item }) {
  const [response, setResponse] = useState(false);
  const [data, setData] = useState({
    abonnement: item?.abonnement,
    period: 12,
  });
  const handelChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    setShow(false);
    // const response = await axios.delete(`${config.url}post/${id}`).then((res) => {
    //   getPosts();
    //   setResponse(true)
    //   handleOpenSnakBar()
    // }).catch(function (error) {
    setResponse(false);
    handleOpenSnakBar();
    // });
  };

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const [open, setOpen] = useState(false);
  const handleCloseSnakBar = () => setOpen(false);
  const handleOpenSnakBar = () => setOpen(true);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Compte </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text_color" width="20%">
            ID: {item?.id}
          </div>
          <div className="text_color" width="20%">
            Nom du propriétaire: {item?.firstName + " " + item?.lastName}
          </div>
          <div className="text_color" width="20%">
            E-mail: {item?.email}
          </div>
          <div className="text_color" width="20%">
            Abonnement: {item?.subscription.package.name}
          </div>
          <div className="text_color" width="20%">
            Date de validite:{" "}
            {dayjs(item?.subscription.endDate).format("DD/MM/YYYY")}{" "}
            {item?.subscription.status ? (
              <CheckCircleIcon color="success" />
            ) : (
              <CancelIcon color="error" />
            )}
          </div>

          <h5 className="text_color mt-3"> Renouvellement d'abonnement</h5>
          <FormGroup className="booking__form d-inline-block  ">
            <label className="text_color">Offre:</label>
            <select
              className="select_input"
              onChange={(e) => handelChange(e)}
              name="abonnement">
              <option
                value={"Bronze"}
                selected={data["abonnement"] === "Bronze"}>
                Bronze
              </option>
              <option
                value={"Silver"}
                selected={data["abonnement"] === "Silver"}>
                Silver
              </option>
              <option value={"Gold"} selected={data["abonnement"] === "Gold"}>
                Gold
              </option>
            </select>
          </FormGroup>
          <FormGroup className="booking__form d-inline-block  ">
            <label className="text_color">Periode:</label>
            <select
              className="select_input"
              onChange={(e) => handelChange(e)}
              name="period">
              <option value={6} selected={data["period"] === 6}>
                semester
              </option>
              <option value={12} selected={data["period"] === 12}>
                annee
              </option>
            </select>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Bloquer l'abonnement
          </Button>
          <button className="btn_modal" onClick={handleSubmit}>
            Renouveler
          </button>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleCloseSnakBar}>
        {response ? (
          <Alert
            onClose={handleCloseSnakBar}
            severity="success"
            sx={{ width: "100%" }}>
            L'opération s'est terminée avec succès
          </Alert>
        ) : (
          <Alert
            severity="error"
            onClose={handleCloseSnakBar}
            sx={{ width: "100%" }}>
            Opération échouée !
          </Alert>
        )}
      </Snackbar>
    </>
  );
}

export default UpdateModal;
