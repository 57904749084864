import React from "react";
// import Loader from "../../lib/Loader/Loader";
import { Chip } from "@mui/material";
import dayjs from "dayjs";

export default function HistoryPackage({ data }) {
  return (
    <div className="col-md-12 mb-3">
      <div className="card h-100">
        <div className="card-body body_color">
          <h6 className="d-flex align-items-center mb-3 text_color">
            Historique abonnement
          </h6>
          {data?.subscription ? (
            <>
              <table width={"100%"}>
                <thead>
                  <td width={"25%"}>Nom</td>
                  <td width={"25%"}>Date d'achat</td>
                  <td width={"25%"}>Date de validite</td>
                  <td width={"25%"}>Status</td>
                </thead>
                <tbody>
                  <tr>
                    <td>{data?.subscription?.package?.name}</td>
                    <td>
                      {dayjs(data?.subscription?.startDate).format(
                        "YYYY-MM-DD"
                      )}
                    </td>
                    <td>
                      {dayjs(data?.subscription?.endDate).format("YYYY-MM-DD")}
                    </td>
                    <td>
                      {data?.subscription?.status === true ? (
                        <Chip label="Active" color="success" />
                      ) : (
                        <Chip label="Bloquée" color="error" />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : (
            <>Actuellement, aucune information n'est disponible</>
          )}
        </div>
      </div>
    </div>
  );
}
