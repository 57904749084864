import React from "react";
import { Col } from "reactstrap";
import "../../assets/styles/car-item.css";
import { useNavigate } from "react-router-dom";
import config from "../../assets/data/config.json";
const CarItem = (props) => {
  const { name, brand, uriPicture, payPerDay, speed, fuel, id } = props.item;
  const navigate = useNavigate();

  const HandleNavigate = () => {
    navigate(`/cars/${id}`);
  };
  return (
    <Col lg="4" md="4" sm="6" className="mb-5 text_color">
      <div className="car__item">
        <div className="car__img">
          <img
            src={`${config.url}/uploads/cars/${uriPicture}`}
            alt=""
            loading="lazy"
            className="w-100"
            style={{ maxHeight: "180px" }}
          />
        </div>

        <div className="car__item-content mt-4">
          <h4 className="section__title text-center">{name}</h4>
          <h6 className="rent__price text-center mt-">
            A partir {payPerDay}.00 CFA <span>/ Jour</span>
          </h6>
          <div className="car__item-info d-flex align-items-center justify-content-between mt-4 mb-1">
            <span className=" d-flex align-items-center gap-1">
              <i className="ri-car-line"></i> {brand.name}
            </span>
            <span className=" d-flex align-items-center gap-1">
              <i className="ri-settings-2-line"></i> {fuel}
            </span>
            <span className=" d-flex align-items-center gap-1">
              <i className="ri-timer-flash-line"></i> {speed} km/h
            </span>
          </div>
          {/* <div>{description}</div> */}
          <center>
            <button onClick={HandleNavigate} className="btn_secondary w-100">
              Réservez
            </button>
          </center>
        </div>
      </div>
    </Col>
  );
};

export default CarItem;
//  <button className=" w-50 car__item-btn car__btn-details">
// <Link to={`/cars/${carName}`}>Details</Link>
//  </button>
