import React from "react";
import { Col } from "reactstrap";
import "../../assets/styles/services-list.css";
import { servicesData } from "../../assets/data/Data.js";

const ServicesList = () => {
  return (
    <>
      {servicesData.map((item) => (
        <ServiceItem item={item} key={item.id} />
      ))}
    </>
  );
};

const ServiceItem = ({ item }) => (
  <Col lg="4" md="4" sm="6" className="mb-3">
    <div className="service__item text_color">
      <center>
        <span className="mb-3 d-inline-block">{item.icon}</span>

        <h6>{item.title}</h6>
        <p className="section__description">{item.desc}</p>
      </center>
    </div>
  </Col>
);

export default ServicesList;
