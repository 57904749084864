import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../pages/Dashboard/Dashboard";
import Home from "../pages/Home/Home";
import About from "../pages/About/About";
import Login from "../components/Login/Login";
import CarListing from "../pages/CarListing/CarListing";
import NotFound from "../pages/NotFound/NotFound";
import Contact from "../pages/Contact/Contact";
import Register from "../components/Login/Register";
import CarDetails from "../pages/CarDetails/CarDetails";
import NewCar from "../pages/NewCar/NewCar";
import TableCars from "../components/UI/TableCars";
import CarAdmin from "../pages/CarAdmin/CarAdmin";
import Packages from "../pages/Packages/Packages";
import Vehicules from "../pages/Vehicules/Vehicules";
import AddSubscribe from "../pages/AddSubscribe/AddProject";
import Profile from "../pages/Profile/Profile";
import ParcTable from "../pages/Parc/ParcTable";
import RentCalendar from "../pages/RentCalendar/RentCalendar";
import TreasuryTable from "../pages/Treasury/TreasuryTable";
import Subscribers from "../pages/Comptes/Subscribers";
import Clients from "../pages/Comptes/Clients";
import LegalNotice from "../pages/LegalNotice/LegalNotice";
import ManagePackages from "../pages/Packages/ManagePackages";
import OutOfService from "../pages/OutOfService/OutOfService";
import AllCars from "../pages/AllCars/CarListing";
import ForgetPassword from "../components/Login/ForgetPassword";
import NewPassword from "../components/Login/NewPassword";
import CityTable from "../pages/Cities/CityTable";
import DocumentType from "../pages/DocumentType/DocumentTypeTable";
import MaintenanceTypeTable from "../pages/MaintenanceType/MaintenanceTypeTable";
import CustomizedSnackbar from "../services/snackbar";
import CategorysTable from "../pages/Categorys/CategorysTable";
import BrandsTable from "../pages/Brands/BrandsTable";
import PartnerTable from "../pages/Partner/PartnerTable";
import Access from "../hooks/Access";

export default function Router() {
  // const [showSideAndSideBar, setShowSideAndSideBar] = useState(true);
  // const [showSideAndNavBar, setShowSideAndNavBar] = useState(true);
  return (
    <div className="app">
      {/* {showSideAndSideBar && <SideBar />} */}
      <main className="content">
        {/* {showSideAndNavBar && <Navbar />} */}
        <CustomizedSnackbar />
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/home" element={<Home />} />
          <Route path="/legalnotice" element={<LegalNotice />} />
          <Route path="/subscribe/:plan" element={<AddSubscribe />} />
          <Route path="/vehicules" element={<Vehicules />} />
          <Route path="/about" element={<About />} />
          <Route path="/packages" element={<Packages />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/outofservice" element={<OutOfService />} />
          <Route path="/newpassword" element={<NewPassword />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/cars/:address/:vehicleType/:startDate/:endDate"
            element={<CarListing />}
          />
          <Route path="/cars" element={<AllCars />} />
          <Route path="/cars/:slug" element={<CarDetails />} />

          <Route path="/access" element={<Access />} />

          <Route path="/admin/cars" element={<TableCars />} />
          <Route path="/admin/calendar" element={<RentCalendar />} />
          <Route path="/admin/treasry" element={<TreasuryTable />} />
          <Route path="/newcar" element={<NewCar />} />
          <Route path="/parc" element={<ParcTable />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/admin/cars/:slug" element={<CarAdmin />} />
          <Route path="/superadmin/users" element={<Subscribers />} />
          <Route path="/superadmin/cities" element={<CityTable />} />
          <Route path="/superadmin/clients" element={<Clients />} />
          <Route path="/superadmin/documents" element={<DocumentType />} />
          <Route
            path="/superadmin/maintenance"
            element={<MaintenanceTypeTable />}
          />
          <Route path="/superadmin/packages" element={<ManagePackages />} />
          <Route path="/superadmin/category" element={<CategorysTable />} />
          <Route path="/superadmin/brands" element={<BrandsTable />} />
          <Route path="/superadmin/partner" element={<PartnerTable />} />
        </Routes>
      </main>
    </div>
  );
}
