import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
// import Alert from "@mui/material/Alert";
import { Form, FormGroup, Card, CardBody, CardTitle } from "reactstrap";
import { Container, Row, Col } from "reactstrap";
import config from "../../assets/data/config.json";
// import Loader from "../../lib/Loader/Loader";
import PhoneInput from 'react-phone-number-input';
import SnackbarInfo from "../../lib/Snackbar/SnackbarInfo";
import image from "../../assets/images/register.png";
import "./Login.css";
// import Snackbar from "@mui/material/Snackbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";

const Register = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.value);

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfrimPassword] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phone, setPhone] = useState();
  const [address, setAddress] = useState();
  const [date, setDate] = useState();
  // const [typeUser, setTypeUser] = useState("PARTICULAR_USER");
  const [response, setResponse] = useState(false);
  const [loading, setLoading] = useState(false);

  const matches = useMediaQuery("(min-width:1400px)");

  // const handelChange = (e) => {
  //   setTypeUser(e.target.value);
  // };
  const handleChangePhone=(e)=>{
    setPhone(e);
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (password !== confirmPassword) {
      setOpen(true);
      return;
    }

    try {
      await axios.post(
        `${config.url}/addUser`,
        {
          email,
          password,
          userType: "PARTICULAR_USER",
          date: date,
          firstName: firstName,
          lastName: lastName,
          roles: "CUSTOMER",
          address: address,
          phone: phone,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer ",
          },
        }
      );
      setResponse(true);
      setOpen(true);
      navigate("/login");
    } catch (error) {
      console.log(error);
      setOpen(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (user.id === null) {
      navigate("/");
    }
  });

  const [open, setOpen] = useState(false);
  const vertical = "top";
  const horizontal = "center";

  return (
    <div className="login_container">
      <Container className="d-flex min-vh-100">
        <Row className="m-auto align-self-center ">
          <Col className="mb-5 text-center">
            <Card
              className="body_color"
              style={{ border: "transparent", borderRadius: "55px" }}>
              <CardBody
                className="body_color"
                style={{ border: "transparent", borderRadius: "55px" }}>
                <CardTitle className="body_color"></CardTitle>
                <Row>
                  <Col>
                    <Form className="form body_color" onSubmit={submitHandler}>
                      <h3 className="form-title text_color text-center">
                        S’inscrire
                      </h3>
                      <Row xl="2" sm="1" xs="1">
                        <Col>
                          <FormGroup className="contact__form">
                            <div>
                              <label htmlFor="lastName" className="text_color">
                                Nom
                              </label>
                            </div>
                            <input
                              type="text"
                              onChange={(e) => setLastName(e.target.value)}
                              className="input"
                              id="lastName"
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup className="contact__form">
                            <div>
                              <label htmlFor="firstName" className="text_color">
                                Prénom
                              </label>
                            </div>
                            <input
                              type="text"
                              onChange={(e) => setFirstName(e.target.value)}
                              className="input"
                              id="firstName"
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup className="contact__form">
                            <div>
                              <label htmlFor="email" className="text_color">
                                E-mail
                              </label>
                            </div>
                            <input
                              type="text"
                              onChange={(e) => setEmail(e.target.value)}
                              className="input"
                              id="email"
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup className="contact__form">
                            <div>
                              <label htmlFor="email" className="text_color">
                                Date de naissance
                              </label>
                            </div>
                            <input
                              type="date"
                              onChange={(e) => setDate(e.target.value)}
                              className="input"
                              name="date"
                              id="email"
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup className="contact__form">
                            <div>
                              <label htmlFor="address" className="text_color">
                                Adresse
                              </label>
                            </div>
                            <input
                              type="text"
                              onChange={(e) => setAddress(e.target.value)}
                              className="input"
                              id="address"
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup className="contact__form">
                            <div>
                              <label htmlFor="phone" className="text_color">
                                Tél
                              </label>
                            </div>
                            <PhoneInput
                              onChange={(e) => handleChangePhone(e)}
                              className="input"
                              id="phone"
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup className="contact__form">
                            <div>
                              <label htmlFor="password" className="text_color">
                                Mot de passe
                              </label>
                            </div>
                            <input
                              type="password"
                              onChange={(e) => setPassword(e.target.value)}
                              className="input"
                              id="password"
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup className="contact__form">
                            <div>
                              <label htmlFor="rpassword" className="text_color">
                                Retaper mot de passe
                              </label>
                            </div>
                            <input
                              type="password"
                              onChange={(e) =>
                                setConfrimPassword(e.target.value)
                              }
                              className="input"
                              id="rpassword"
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup className="contact__form w-100">
                        <button
                          className=" btn_secondary mt-3"
                          style={{ color: "white" }}>
                          Registre
                        </button>
                      </FormGroup>
                      <FormGroup className="contact__form">
                        <p className="text_color">
                          Vous avez un compte?{" "}
                          <Link
                            to="/login"
                            className="form-link"
                            style={{
                              textDecoration: "none",
                              color: "var(--blue_color)",
                            }}>
                            connecter
                          </Link>
                        </p>
                      </FormGroup>
                    </Form>
                  </Col>
                  {matches ? (
                    <Col xl="6">
                      <div
                        className="contact__info mt-3 text_color"
                        style={{ marginRight: "10px" }}>
                        <img
                          src={image}
                          loading="lazy"
                          className="w-75 h-75 p-3 mb-3 "
                          style={{ borderRadius: "15%" }}
                          alt={"contact_nous"}
                        />
                      </div>
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {loading ? <></> : null}
      <SnackbarInfo
        successMessage="L'opération s'est terminée avec succès"
        errorMessage="Les données sont incorrect. Réessayez pour le réinitialiser."
        open={open}
        setOpen={setOpen}
        response={response}
        setResponse={setResponse}
        vertical={vertical}
        horizontal={horizontal}
      />
    </div>
  );
};

export default Register;
