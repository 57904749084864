import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.value);
  const status = user.subscription ? user.subscription.status : false;
  const parc = user.subscription ? user.subscription.package.parc : false;

  useEffect(() => {
    if (!status || !parc) {
      navigate("/outofservice");
    }
  }, [status, parc, navigate]);

  return status && parc ? children : null;
};

export default ProtectedRoute;
