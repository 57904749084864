import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { setSnackbar } from "../../features/snackbar";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SnackbarInfo from "../../lib/Snackbar/SnackbarInfo";
import { useDispatch } from "react-redux";
import { DeleteMaintenanceType } from "../../services/MaintenanceType";

function Example({ item }) {
  const dispatch = useDispatch();
  const [response, setResponse] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleDelete = async () => {
    setShow(false);
    DeleteMaintenanceType(dispatch, item);
    const action = {
      snackbarOpen: true,
      snackbarType: "success",
      snackbarMessage: "L'opération s'est terminée avec succès",
    };
    dispatch(setSnackbar(action));
  };

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const [open, setOpen] = useState(false);
  // const handleOpenSnakBar = () => setOpen(true);

  return (
    <>
      <IconButton
        aria-label="delete"
        onClick={() => handleShow()}
        color="error">
        <DeleteIcon />
      </IconButton>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Attention ! </Modal.Title>
        </Modal.Header>
        <Modal.Body> Êtes-vous sûr de vouloir supprimer ce entretien  ?</Modal.Body>
        <Modal.Footer>
          <button className="btn_modal" onClick={handleDelete}>
            Supprimer
          </button>
          <Button
            variant="secondary"
            style={{ borderRadius: "55px" }}
            onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>

      <SnackbarInfo
        successMessage="L'opération s'est terminée avec succès"
        errorMessage="L'opération échoue"
        open={open}
        setOpen={setOpen}
        response={response}
        setResponse={setResponse}
        vertical={"bottom"}
        horizontal={"left"}
      />
    </>
  );
}

export default Example;
