import GroupIcon from "@mui/icons-material/Group";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import FlagIcon from "@mui/icons-material/Flag";
import WorkIcon from "@mui/icons-material/Work";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import SpeedIcon from "@mui/icons-material/Speed";
import image1 from "../images/vehicules/image_c1.jpg";
import image2 from "../images/vehicules/image_c2.jpg";
import image3 from "../images/vehicules/image_c3.jpg";
import image4 from "../images/vehicules/image_c4.jpg";
import image5 from "../images/vehicules/image_c5.jpg";
import image6 from "../images/vehicules/image_c6.jpg";
import image7 from "../images/vehicules/image_c7.jpg";
import image8 from "../images/vehicules/image_c8.jpg";
import image9 from "../images/vehicules/image_c9.jpg";
import carousel1 from "../images/places/carousel1.jpg";
import carousel2 from "../images/places/carousel2.jpg";
import carousel3 from "../images/places/carousel3.jpg";
import carousel4 from "../images/places/carousel4.jpg";
import carousel5 from "../images/places/carousel5.jpg";
import carousel6 from "../images/places/carousel6.jpg";

export const servicesData = [
  {
    id: 3,
    title: "Les meilleures offres de véhicules",
    icon: (
      <DirectionsCarIcon
        style={{ fontSize: "70px", color: "var(--blue_color)" }}
      />
    ),
    desc: "Chez RANCAR, nous mettons à votre disposition une flotte de véhicules modernes et bien entretenus, pour que votre voyage soit confortable et sécurisé.",
  },

  {
    id: 4,
    title: "Les meilleures Prix",
    icon: (
      <PriceCheckIcon
        style={{ fontSize: "70px", color: "var(--blue_color)" }}
      />
    ),
    desc: "Louez une véhicule chez RANCAR et profitez d'un service professionnel, d'un large choix de véhicules et de tarifs compétitifs - votre satisfaction est notre priorité.",
  },

  {
    id: 6,
    title: " location la plus rapide",
    icon: (
      <SpeedIcon style={{ fontSize: "70px", color: "var(--blue_color)" }} />
    ),
    desc: "Les contacts sont permanents à toute heure, et assistent régulièrement tous les mouvements d'arrivées et de départs des vols, dans tous les aéroports 24 h/7 j.",
  },
];

export const awards = [
  {
    icon: <GroupIcon style={{ fontSize: "60px" }} />,
    num: "1.5 K",
    name: "Client dans le monde",
  },
  {
    icon: <FlagIcon style={{ fontSize: "60px" }} />,
    num: "10",
    name: "Pays",
  },
  {
    icon: <DirectionsCarIcon style={{ fontSize: "60px" }} />,
    num: "500+",
    name: "Véhicules",
  },
  {
    icon: <WorkIcon style={{ fontSize: "60px" }} />,
    num: "2",
    name: "Ans d'experience",
  },
];

export const questions = [
  {
    key: 0,
    question: "Pourquoi nos tarifs sont-ils si accessibles?",
    response:
      "Nous collaborons avec les meilleures sociétés de location de véhicules.Aussi, notre système vérifie les tarifs de la concurrence 24 heures/24 pour s'assurer bénéficiez toujours des meilleurs tarifs.",
  },
  {
    key: 1,
    question: "Comment modifier ou annuler ma réservation?",
    response:
      "En général, vous pouvez modifier ou annuler votre réservation gratuitement. Consultez toutefois les conditions d'utilisation correspondantes, car certains fournissent appliquent des restrictions.",
  },
  {
    key: 2,
    question: "De quoi ai-je besoin pour louer une véhicule ?",
    response: `Vous n'avez besoin que d'une carte de crédit ou de débit pour réserver une véhicule.<br/>

    Une fois à l'agence de location, vous aurez besoin de :<br/>
    
    -Votre passeport <br/>
    -Votre bon de réservation <br/>
    -Le permis de conduire de chaque conducteur <br/>
    -La carte de crédit du conducteur principal (seulement quelques sociétés acceptent les cartes de débit)<br/>`,
  },
  {
    key: 3,
    question: "Comment choisir la bonne véhicule ?",
    response: `Pensez à votre destination. Un SUV est idéal pour les autoroutes du Texas, mais peut-être qu'à Rome, vous opterez pour une véhicule plus petite.
    Découvrez ce qu'en pensent les autres voyageurs. Notre site regorge de notes et d'avis clients afin que vous puissiez savoir ce que les voyageurs ont aimé (ou non) à propos de chaque société de location.
    Pensez à la boîte de vitesse. Dans certains pays, les véhicules manuelles sont beaucoup plus répandues que les automatiques, alors que dans d'autres, c'est l'inverse. Pensez bien à vérifier avant de louer une véhicule !`,
  },
  {
    key: 4,
    question: "Tous les frais sont-ils inclus dans le prix de location ?",
    response: `Les tarifs affichés comprennent la location du véhicule, l'assurance obligatoire (par ex. la protection en cas de vol et la couverture partielle en cas de collision) ainsi que des frais qui, s'ils s'appliquent, doivent généralement être payés lors de la prise en charge du véhicule (par ex. les frais d'abandon, les taxes d'aéroport et les taxes locales).<br/>
    Nos tarifs comprennent également les services supplémentaires que vous avez déjà ajoutés (par ex. la location d'un GPS ou d'un siège enfant).
    Ils ne comprennent pas les assurances complémentaires auxquelles vous pourrez souscrire une fois à l'agence.<br/>
    Astuce : vous pourrez consulter tous les détails du tarif à payer sur la page de paiement.`,
  },
];

export const footer = [
  {
    title: "LAYOUTS",
    text: [
      { list: "Home Page" },
      { list: "About Page" },
      { list: "Service Page" },
      { list: "Property Page" },
      { list: "Contact Page" },
      { list: "Single Blog" },
    ],
  },
  {
    title: "ALL SECTIONS",
    text: [
      { list: "Headers" },
      { list: "Features" },
      { list: "Attractive" },
      { list: "Testimonials" },
      { list: "Videos" },
      { list: "Footers" },
    ],
  },
  {
    title: "COMPANY",
    text: [
      { list: "About" },
      { list: "Blog" },
      { list: "Pricing" },
      { list: "Affiliate" },
      { list: "Login" },
      { list: "Changelog" },
    ],
  },
];

export const plans = [
  {
    id: 1,
    plan: "Gold",
    priceA: "25",
    priceS: "29",
    ptext: " HT/mois pendant six mois",
    list: [
      {
        icon: <i className="fa-solid fa-check"></i>,
        text: "Parking Sécurisé",
      },
      {
        icon: <i className="fa-solid fa-check"></i>,
        text: "Gestion Facile des Réservations",
      },
      {
        icon: <i className="fa-solid fa-check"></i>,
        text: "Notification Instantanée",
      },
      {
        change: "color",
        icon: <i className="fa-solid fa-x"></i>,
        text: "Caisse Rapide",
      },
      {
        change: "color",
        icon: <i className="fa-solid fa-x"></i>,
        text: "Garage d'Entretien Professionnel",
      },
    ],
  },
  {
    id: 2,
    plan: "Premium",
    priceA: "45",
    priceS: "49",
    ptext: "HT/mois pendant six mois",
    list: [
      {
        icon: <i className="fa-solid fa-check"></i>,
        text: "Parking Sécurisé ",
      },
      {
        icon: <i className="fa-solid fa-check"></i>,
        text: "Gestion Facile des Réservations",
      },
      {
        icon: <i className="fa-solid fa-check"></i>,
        text: "Notification Instantanée ",
      },
      {
        icon: <i className="fa-solid fa-check"></i>,
        text: "Caisse Rapide",
      },
      {
        change: "color",
        icon: <i className="fa-solid fa-x"></i>,
        text: "Garage d'Entretien Professionnel",
      },
    ],
  },
  {
    id: 3,
    plan: "Business",
    priceA: "75",
    priceS: "79",
    ptext: "HT/mois pendant six mois",
    list: [
      {
        icon: <i className="fa-solid fa-check"></i>,
        text: "Parking Sécurisé",
      },
      {
        icon: <i className="fa-solid fa-check"></i>,
        text: "Gestion Facile des Réservations",
      },
      {
        icon: <i className="fa-solid fa-check"></i>,
        text: "Notification Instantanée",
      },
      {
        icon: <i className="fa-solid fa-check"></i>,
        text: "Caisse Rapide",
      },
      {
        icon: <i className="fa-solid fa-check"></i>,
        text: "Garage d'Entretien Professionnel",
      },
    ],
  },
];

export const carsType = [
  {
    id: 1,
    name: "véhicules de tourisme",
    desc: "Les véhicules automobiles conçus pour le transport de passagers, allant des petites véhicules aux berlines de luxe.",
    image: image1,
  },
  {
    id: 2,
    name: "Camionnettes",
    desc: " Des véhicules utilitaires légers conçus pour le transport de marchandises, de matériel ou de passagers.",
    image: image2,
  },
  {
    id: 3,
    name: "Motos à quatre roues",
    desc: 'Également appelées "quads" ou "VTT quad", ce sont des véhicules tout-terrain avec quatre roues au lieu de deux.',
    image: image3,
  },
  {
    id: 4,
    name: "Camions",
    desc: "Utilisés pour le transport de marchandises, il existe différents types de camions, notamment des camions légers, des camions lourds, des camions-citernes, des camions-bennes, etc.",
    image: image4,
  },
  {
    id: 5,
    name: "Véhicules utilitaires sport",
    desc: "Des véhicules plus grands et plus robustes, adaptés à la conduite hors route, idéaux pour les familles et le transport de cargaisons.",
    image: image5,
  },
  {
    id: 6,
    name: "Véhicules tout-terrain",
    desc: "De petits véhicules tout-terrain motorisés à quatre roues, conçus pour une utilisation récréative en plein air.",
    image: image6,
  },
  {
    id: 7,
    name: "Véhicules électriques à quatre roues",
    desc: "Comme les véhicules électriques, les véhicules utilitaires électriques (VUE) et les véhicules électriques légers (VEL) à quatre roues.",
    image: image7,
  },
  {
    id: 8,
    name: "Camions légers",
    desc: "Des véhicules utilitaires légers, généralement utilisés pour le transport de marchandises sur de courtes distances.",
    image: image9,
  },
  {
    id: 9,
    name: "Véhicules tout-terrain",
    desc: "Des véhicules tout-terrain à quatre roues, souvent utilisés pour l'agriculture, la chasse ou les loisirs.",
    image: image1,
  },
  {
    id: 10,
    name: "Go-karts à quatre roues",
    desc: "De petits véhicules de course à quatre roues utilisés pour les loisirs et la compétition.",
    image: image2,
  },
  {
    id: 11,
    name: "Tracteurs ",
    desc: "Des véhicules agricoles à quatre roues utilisés pour labourer, semer, cultiver et d'autres tâches agricoles.",
    image: image3,
  },
  {
    id: 12,
    name: "Quads de sport",
    desc: "Des véhicules tout-terrain sportifs conçus pour les courses et les compétitions tout-terrain.",
    image: image5,
  },
  {
    id: 13,
    name: "Véhicules tout-terrain militaires",
    desc: "Utilisés par les forces armées pour le transport de troupes, de matériel et pour d'autres tâches militaires.",
    image: image6,
  },
  {
    id: 14,
    name: "Véhicules récréatifs (VR)",
    desc: "Des véhicules de loisirs, notamment des camping-cars, des caravanes et des camping-cars à quatre roues.",
    image: image8,
  },
  {
    id: 15,
    name: "Remorques à quatre roues",
    desc: "Des remorques qui sont généralement attachées à un véhicule tracteur à quatre roues pour le transport de marchandises ou d'autres charges.",
    image: image9,
  },
];

export const places = [
  {
    id: 1,
    name: "Abidjan",
    img: <img src={carousel6} alt="6" key={5} className="w-100 image_places" />,
  },
  {
    id: 2,
    name: "Grand-Bassam",
    img: <img src={carousel1} alt="1" key={0} className="w-100 image_places" />,
  },
  {
    id: 3,
    name: "Gagnoa",
    img: <img src={carousel2} alt="2" key={1} className="w-100 image_places" />,
  },
  {
    id: 4,
    name: "Anyama",
    img: <img src={carousel3} alt="3" key={2} className="w-100 image_places" />,
  },
  {
    id: 5,
    name: "Daloa",
    img: <img src={carousel4} alt="4" key={3} className="w-100 image_places" />,
  },
  {
    id: 6,
    name: "Bouaké",
    img: <img src={carousel5} alt="5" key={4} className="w-100 image_places" />,
  },
];
