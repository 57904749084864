import * as React from "react";
import { useEffect } from "react";
import TablePagination from "@mui/material/TablePagination";
import { Search } from "@mui/icons-material";
import { Box, IconButton, InputBase } from "@mui/material";
import DeleteModal from "./DeleteModal";
import UpdateModal from "./UpdateModal";
import Loader from "../../lib/Loader/Loader";
import AddModal from "./AddModal";
import { useDispatch, useSelector } from "react-redux";
import { GetPartners } from "../../services/Partner";
// import { isAllOf } from "@reduxjs/toolkit";

export default function PartnerTable() {
  const dispatch = useDispatch();
  const partners = useSelector((state) => state.partner.partners);

  const isPending = useSelector((state) => state.partner.loading);

  // const [isPending,setIsPending]=useState(true);
  useEffect(() => {
    GetPartners(dispatch);
  }, [dispatch]);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const [minIndex, setMinIndex] = React.useState(0);
  // const maxIndex = minIndex + rowsPerPage;
  // const [totalRows, setTotalRows] = React.useState(0);
  const totalRows = 0;
  const [page, setPage] = React.useState(0);

  const handleChangePage = (newPage) => {
    setPage(newPage);
    // setMinIndex(newPage * rowsPerPage);
  };

  //api/min-1/max

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = async (e) => {
    // if (e.target.value) {
    //   await axios
    //     .get(
    //       `${config.url}post/row=${minIndex}/page=${maxIndex}/${e.target.name}=${e.target.value}`
    //     )
    //     .then((res) => {
    //       setCars(res.data);
    //       setIsLoded(true);
    //     });
    // } else {
    //   getCars();
    // }
  };

  return (
    <div className="container_X text_center text_color shadow ">
      <div>
        <div style={{ overflow: "auto", margin: "20px" }}>
          <p className="big-title" style={{ float: "left", fontSize: "35px" }}>
            Partenaires
          </p>
          <p style={{ float: "right", color: "#004a9e" }}></p>
        </div>
      </div>
      <div
        style={{
          background: "var(--body_background)",
          margin: "20px 20px 0px 20px",
          borderBlockEnd: "1px #e4e4e4 solid",
        }}>
        <table style={{ margin: "0 auto" }}>
          <thead className="Thead">
            <tr>
              <td width="20%">ID</td>
              <td width="50%">Nom</td>
              <td width="30%">Action</td>
            </tr>
            <tr>
              <td className="td_X">
                <Box
                  borderRadius="9px"
                  gap="3rem"
                  className="searchBar"
                  p="0.1rem 1.5rem">
                  <InputBase
                    placeholder="Recherche..."
                    className="col-11"
                    onChange={(e) => handleSearch(e)}
                    name="id"
                  />
                  <IconButton className="col-1">
                    <Search />
                  </IconButton>
                </Box>
              </td>
              <td className="td_X">
                <Box
                  borderRadius="9px"
                  gap="3rem"
                  className="searchBar"
                  p="0.1rem 1.5rem">
                  <InputBase
                    placeholder="Recherche..."
                    className="col-11"
                    onChange={(e) => handleSearch(e)}
                    name="name"
                  />
                  <IconButton className="col-1">
                    <Search />
                  </IconButton>
                </Box>
              </td>

              <td className="td_X">
                <AddModal />
              </td>
            </tr>
          </thead>

          {isPending ? (
            <tbody>
              {partners?.map((item, index) => (
                <tr key={index}>
                  <td className="td_X">{item.id}</td>
                  <td className="td_X">{item.name}</td>
                  <td className="td_X">
                    <UpdateModal data={item} />
                    <DeleteModal item={item} />
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <></>
          )}
        </table>
        {isPending ? <></> : <Loader />}
        {partners?.length === 0 && isPending ? (
          <div style={{ height: "100px", padding: "70px 0" }}>
            Actuellement, aucune information n'est disponible
          </div>
        ) : (
          <></>
        )}
      </div>
      <div
        style={{
          background: "var(--body_background)",
          margin: "0px 0px 0px 0px",
        }}>
        <TablePagination
          className="text_color"
          labelRowsPerPage="Lignes par page :"
          component="div"
          localisation="fr"
          count={totalRows}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}
