import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../../components/Helmet/Helmet";
import FullCalendar from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import multiMonthPlugin from "@fullcalendar/multimonth";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import AddEvent from "../../components/UI/AddEvent";
import InfoEvent from "../../components/UI/InfoEvent";
import frLocale from "@fullcalendar/core/locales/fr";
import { useDispatch, useSelector } from "react-redux";
import { GetCalendarEvent, GetEvent } from "../../services/CalendarEvent";
import ProtectedRoute from "./ProtectedRoute";

function transformedData(data) {
  const res = data.map((item) => {
    return {
      ...item,
      id: item.id,
      color:
        item.confirmed === null
          ? "var(--blue_color)"
          : item.confirmed
            ? "#89c7bc"
            : "#FF8C8B",
      start: item.startDate,
      end: item.endDate,
      title: item.vehicle ? item.vehicle.name : "",
    };
  });
  return res;
}

export default function RentCalendar() {
  const dispatch = useDispatch();

  // const navigate = useNavigate();
  // useEffect(() => {

  //   if (!localStorage.getItem("userInfo")) {
  //     navigate('/');
  //   }
  //   if (localStorage.getItem("userInfo")) {
  //     if (!JSON.parse(localStorage.getItem("userInfo")).admin) {
  //       navigate('/');
  //     }
  //   }
  // }, [navigate])

  const [event, setevent] = useState([]);

  const handleDateClick = (arg) => {
    setevent(arg.event._def.publicId);
    GetEvent(dispatch, arg.event._def.publicId);
    handleInfo();
  };

  // const loading = useSelector((state) => state.calendarEvent.loading);
  const events = useSelector((state) => state.calendarEvent.calendarEvents);

  const calendarRef = useRef(null);
  // const onEventAdded = (event) => {
  //   let calendarApi = calendarRef.current.getApi();
  //   calendarApi.refetchEvents();
  // };
  async function handleDatesSet(data) {}
  const [datePlan, setdatePlan] = useState("");
  const dateClick = (arg) => {
    setdatePlan(arg.dateStr);
    handleModel();
  };

  const [modelopen, setmodelopen] = useState(false);
  const handleModel = () => setmodelopen(!modelopen);
  const [infoopen, setInfoopen] = useState(false);
  const handleInfo = () => setInfoopen(!infoopen);

  const dayHeaderContent = (args) => {
    return args.date.toLocaleDateString("fr-FR", { weekday: "long" }); // Utilisez la localisation qui convient à vos besoins
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    GetCalendarEvent(dispatch);
  }, [infoopen, modelopen, dispatch]);

  return (
    <Helmet title={"Reservation"}>
      <ProtectedRoute>
        <section>
          <Container>
            <Row>
              <Col lg="12" className="mt-5 mb-5 text-color">
                <FullCalendar
                  ref={calendarRef}
                  headerToolbar={{
                    left: "dayGridMonth,multiMonthYear,listMonth,timeGridWeek,timeGridDay",
                    center: "title",
                    right: "prev,today,next",
                  }}
                  nowIndicator={true}
                  views={{
                    multiMonth: {
                      duration: { months: 12 },
                      type: "dayGridMonth",
                      dayHeaderContent: (args) =>
                        args.date.toLocaleDateString("fr-FR", {
                          weekday: "long",
                        }),
                      buttonText: "Année", // Le texte du bouton pour cette vue
                    },
                  }}
                  events={transformedData(events)}
                  plugins={[
                    dayGridPlugin,
                    interactionPlugin,
                    multiMonthPlugin,
                    listPlugin,
                    timeGridPlugin,
                  ]}
                  initialView="listMonth"
                  dateClick={dateClick}
                  datesSet={(date) => handleDatesSet(date)}
                  eventClick={(arg) => handleDateClick(arg)}
                  locale={frLocale}
                  dayHeaderContent={dayHeaderContent}
                />
                {/* <div className="booking-info mt-5 mb-4">
                <button onClick={(e) => submitHandler(e)} className="btn_secondary">
                  enregistrer
                </button>
              </div> */}
              </Col>
            </Row>
          </Container>
        </section>

        <AddEvent
          date={datePlan}
          modelopen={modelopen}
          handleModel={() => handleModel()}
        />
        <InfoEvent
          date={datePlan}
          modelopen={infoopen}
          handleModel={() => handleInfo()}
          eventID={event}
        />
      </ProtectedRoute>
    </Helmet>
  );
}

//              plugins={[ resourceTimelinePlugin ,]}
// initialView='resourceTimeline'
