import React, { useEffect } from "react";
import Heading from "../../components/UI/Heading.jsx";
import "./Package.css";
import PackageCard from "./PackageCard";

const Packages = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="price padding ">
      <div className="container">
        <Heading
          title="Sélectionnez votre forfait"
          subtitle="Libérez la route à votre manière - Louez vos véhicules dès maintenant!"
        />
        <PackageCard />
      </div>
    </section>
  );
};

export default Packages;
