import React, { useEffect } from "react";
import Slider from "react-slick";
import useMediaQuery from "@mui/material/useMediaQuery";
import Heading from "../Heading";
// import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../assets/data/config.json";
import { GetPartners } from "../../../services/Partner";

// const Item = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: calc(100% / 5);
//   height: 100px;
//   font-family: Signika;
//   font-weight: bold;
//   font-size: 1.5em;

//   @media (max-width: 1150px) {
//     width: 100%;
//   }
// `;
export default function Partenaires() {
  const matches = useMediaQuery("(min-width:1400px)");
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: matches ? 4 : 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    rtl: false,
    autoplaySpeed: 3000,
    cssEase: "linear",
    customPaging: () => <></>,
  };
  const dispatch = useDispatch();
  const partners = useSelector((state) => state.partner.partners);

  // const isPending = useSelector(state => state.partner.loading);

  useEffect(() => {
    GetPartners(dispatch);
  }, [dispatch]);
  return (
    <div>
      <Heading
        title="Partenaires Officiels"
        subtitle="Ensemble, pour mieux vous épauler"
      />
      <Slider {...settings}>
        {partners.map((item, index) => (
          <img
            src={config.images_partner + "/" + item.image}
            loading="lazy"
            alt={item.name}
            key={index}
            className="h-50 w-50"
          />
        ))}
      </Slider>
    </div>
  );
}
