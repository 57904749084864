import {
  setDocumentType,
  newDocumentType,
  editDocumentType,
  deleteDocumentType,
  setLoading,
} from "../features/documentType";
import { setSnackbar } from "../features/snackbar";
import Http from "../lib/http/http";

const USER_ID_KEY = "userId";

const getUserId = () => {
  const userId = localStorage.getItem(USER_ID_KEY);
  if (!userId) {
    throw new Error("User ID not found in localStorage");
  }
  return userId;
};

const handleApiError = (dispatch, errorType, errorMessage) => {
  const action = {
    snackbarOpen: true,
    snackbarType: errorType,
    snackbarMessage: errorMessage,
  };
  dispatch(setSnackbar(action));
};

export const GetDocumentType = async (dispatch) => {
  try {
    // api call
    const { data } = await Http.get("/documentTypes");
    dispatch(setDocumentType(data));
  } catch {
    handleApiError(
      dispatch,
      "error",
      "Erreur lors du chargement les documents"
    );
  }
};

export const NewDocumentType = async (dispatch, DocumentType) => {
  try {
    dispatch(setLoading(false));
    const userId = getUserId();
    const { data } = await Http.post(
      "/api/addDocumentType/" + userId,
      DocumentType
    );
    dispatch(newDocumentType(data));
    handleApiError(
      dispatch,
      "success",
      "Le document a été soumise avec succès!"
    );
    dispatch(setLoading(true));
  } catch {
    handleApiError(
      dispatch,
      "error",
      "Erreur lors de la soumission du document!"
    );
  }
};

export const EditDocumentType = async (dispatch, DocumentType) => {
  try {
    dispatch(setLoading(false));
    const userId = getUserId();
    const { data } = await Http.post(
      "/api/updateDocumentType/" + userId + "/" + DocumentType.id,
      DocumentType
    );
    dispatch(editDocumentType(data));
    handleApiError(
      dispatch,
      "success",
      "La modification a été soumise avec succès!"
    );
    dispatch(setLoading(true));
  } catch {
    handleApiError(
      dispatch,
      "error",
      "Erreur lors de la soumission de la modification!"
    );
  }
};

export const DeleteDocumentType = async (dispatch, DocumentType) => {
  try {
    dispatch(setLoading(false));
    const userId = getUserId();
    await Http.delete("/api/documentType/" + userId + "/" + DocumentType.id);
    dispatch(deleteDocumentType(DocumentType));
    handleApiError(
      dispatch,
      "success",
      "La suppression a été soumise avec succès!"
    );
    dispatch(setLoading(true));
  } catch {
    handleApiError(
      dispatch,
      "error",
      "Erreur lors de la soumission de la suppression!"
    );
  }
};
