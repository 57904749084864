import axios from "axios";
import config from "../../assets/data/config.json";
import CookieHelper from "./../Utils/helper";

/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/rules-of-hooks */

const getUserCookies = () => {
  return CookieHelper.getUserCookies("user").slice(3, -3);
};

const instance = axios.create({
  baseURL: `${config.url}`,
  timeout: 60000,
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
    Authorization: `Bearer ${getUserCookies()}`,
  },
});
// Add a request interceptor to update Authorization header
instance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${getUserCookies()}`;
  return config;
});

instance.interceptors.response.use(null, (err) => {
  console.log(err)
  window.location.href = config.urlFrontEnd + "access";
  // if (AxiosUtil.isAuthenticationError(err)) return AxiosUtil.logoutIfUnauthorizedOrForbidden()
  // return Promise.reject(err)
});

const HttpForm = {
  get: instance.get,
  post: instance.post,
  put: instance.put,
  delete: instance.delete,
  patch: instance.patch,
};

export default HttpForm;
