import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { setSnackbar } from "../features/snackbar";

const CustomizedSnackbar = () => {
  const dispatch = useDispatch();
  const snackbarOpen = useSelector((state) => state.snackbar.snackbarOpen);
  const snackbarType = useSelector((state) => state.snackbar.snackbarType);
  const snackbarMessage = useSelector(
    (state) => state.snackbar.snackbarMessage
  );
  const vertical = "top";
  const horizontal = "center";
  const handleClose = (reason) => {
    const snackbarOpen = false;
    dispatch(setSnackbar({ snackbarOpen, snackbarType, snackbarMessage }));
    if (reason === "clickaway") {
      return;
    }
  };

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={1000}
      onClose={handleClose}
      anchorOrigin={{ vertical, horizontal }}>
      <Alert variant="filled" onClose={handleClose} color={snackbarType}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
};

export default CustomizedSnackbar;
