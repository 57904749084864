import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Http from "../../lib/http/http";
import { useSelector } from "react-redux";

function Example({ show, setShow, id,refrechFunction }) {
  const [response, setResponse] = useState(false);
  const user = useSelector((state)=> state.user.value);

  const handleDelete = async () => {
    setShow(false);
    try{
      await Http.delete(`/api/vehicle/${user.id}/${id}`);
      setResponse(true);
      handleOpenSnakBar();
      refrechFunction();
    }catch(e){
      setResponse(false);
      handleOpenSnakBar();
    }
  };

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const [open, setOpen] = useState(false);
  const handleCloseSnakBar = () => setOpen(false);
  const handleOpenSnakBar = () => setOpen(true);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Attention ! </Modal.Title>
        </Modal.Header>
        <Modal.Body> Êtes-vous sûr de vouloir supprimer ce poste ?</Modal.Body>
        <Modal.Footer>
          <button className="btn_modal" onClick={handleDelete}>
            Supprimer
          </button>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleCloseSnakBar}>
        {response ? (
          <Alert
            onClose={handleCloseSnakBar}
            severity="success"
            sx={{ width: "100%" }}>
            L'opération s'est terminée avec succès
          </Alert>
        ) : (
          <Alert
            severity="error"
            onClose={handleCloseSnakBar}
            sx={{ width: "100%" }}>
            Opération échouée !
          </Alert>
        )}
      </Snackbar>
    </>
  );
}

export default Example;
