import AccordionGroup from "@mui/joy/AccordionGroup";
import Accordion from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionSummary from "@mui/joy/AccordionSummary";
import { questions } from "../../../assets/data/Data";
import { Row } from "reactstrap";
import Heading from "../Heading";
import "./QuestionSection.css";

function QuestionSection() {
  return (
    <section className="question_section mb-5 mt-5">
      <div className="container_awards">
        <Heading title="Questions fréquentes" subtitle="" />
        <Row className="content grid4 mtop">
          <AccordionGroup size="lg" className="accordion_container">
            {questions.map((val, index) => (
              <Accordion
                key={index}
                variant="plain"
                className="accordion_container">
                <AccordionSummary>
                  <div className="text_color ">{val.question}</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="text_color text-left">
                    <mark
                      dangerouslySetInnerHTML={{ __html: val.response }}></mark>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </AccordionGroup>
        </Row>
      </div>
    </section>
  );
}

export default QuestionSection;
