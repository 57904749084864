import { createSlice, createAction } from "@reduxjs/toolkit";

export const setCategoryError = createAction("setCategoryError");
export const newCategoryError = createAction("newCategoryError");
export const editCategoryError = createAction("editCategoryError");
export const deleteCategoryError = createAction("deleteCategoryError");

export const CategorySlice = createSlice({
  name: "Category",
  initialState: {
    Category: [],
    loading: false,
  },
  reducers: {
    setCategory: (state, action) => {
      return { ...state, Category: action.payload, loading: true };
    },
    newCategory: (state, action) => {
      return { ...state, Category: [...state.Category, action.payload] };
    },
    editCategory: (state, action) => {
      const Category = state.Category.map((Category) => {
        if (Category.id === action.payload.id) {
          Category = action.payload;
        }
        return Category;
      });
      return { ...state, Category: [...Category] };
    },
    deleteCategory: (state, action) => {
      const Category = state.Category.filter(
        (Category) => Category.id !== action.payload.id
      );
      return { ...state, Category: [...Category] };
    },
    setLoading: (state, action) => {
      return { ...state, loading: action.payload };
    },
  },
});

export const {
  setCategory,
  newCategory,
  editCategory,
  deleteCategory,
  setLoading,
} = CategorySlice.actions;

export default CategorySlice.reducer;
