import React from "react";

export default function ClientConditionText() {
  return (
    <div className="text_color text-left">
      <h2>Article 1 :</h2>
      Ce contrat vise à établir les conditions de collaboration entre Rancar,
      représenté par vous, ci-après désigné sous le terme "le Site", et les
      agences de location de véhicules, désignées ci-après sous le terme "les
      Agences".
      <br />
      <h2>Article 2 :</h2>
      Les Agences s'engagent à fournir avec précision des informations sur les
      véhicules disponibles à la location, incluant les tarifs, les
      caractéristiques des véhicules, les conditions de location, ainsi que
      toute autre information pertinente. De plus, les Agences garantissent la
      disponibilité des véhicules aux dates convenues avec les clients via le
      site de Rancar. <br />
      <h2>Article 3 :</h2>
      Rancar s'engage à présenter de manière précise et transparente les
      informations fournies par les Agences, en veillant à promouvoir
      équitablement les offres de location. De plus, Rancar s'engage à faciliter
      le processus de réservation en ligne, assurant ainsi une expérience fluide
      pour les utilisateurs.
      <br />
      <h2>Article 4 :</h2>
      Les modalités financières de la collaboration seront déterminées
      individuellement avec chaque Agence en fonction du type de véhicule par
      abonnement, et seront détaillées dans un accord financier distinct.
      <br />
      <h2>Article 5 :</h2>
      Rancar est responsable de la gestion des réservations en ligne, de la
      transmission des informations des clients aux Agences, et de la promotion
      des services de location de véhicules. Les Agences sont responsables de la
      mise à jour régulière de leurs offres sur le site Rancar, de la
      disponibilité des véhicules, et du respect des conditions convenues avec
      les clients.
      <br />
      <h2>Article 6 :</h2>
      Les deux parties s'engagent à traiter de manière confidentielle toutes les
      informations commerciales sensibles échangées dans le cadre de cette
      collaboration.
      <br />
      <h2>Article 7 :</h2>
      Chaque partie peut résilier le contrat en cas de non-respect des termes et
      conditions par l'autre partie. La résiliation doit être notifiée par écrit
      avec un préavis de 30 jours.
      <br />
      <h2>Article 8 :</h2>
      Ce contrat est régi par les lois en vigueur en Côte d'Ivoire. Tout litige
      découlant de ce contrat sera résolu par voie de négociation de bonne foi.
      En cas d'échec, les parties conviennent de recourir à la médiation
      conformément aux lois applicables.
      <br />
    </div>
  );
}
