import {
  setPackages,
  newPackage,
  editPackage,
  deletePackage,
  setLoading,
  setPackageError,
} from "../features/package";
import { setSnackbar } from "../features/snackbar";
import Http from "../lib/http/http";

const USER_ID_KEY = "userId";

const getUserId = () => {
  const userId = localStorage.getItem(USER_ID_KEY);
  if (!userId) {
    throw new Error("User ID not found in localStorage");
  }
  return userId;
};

const handleApiError = (dispatch, errorType, errorMessage) => {
  const action = {
    snackbarOpen: true,
    snackbarType: errorType,
    snackbarMessage: errorMessage,
  };
  dispatch(setSnackbar(action));
};

// axiosInstance.interceptors.request.use((conf) => {
//     conf.headers = { authorization: 'Bearer ' + sessionStorage.getItem('token') };
//     return conf;
// });

export const GetPackages = async (dispatch) => {
  try {
    const { data } = await Http.get("/packages");
    dispatch(setPackages(data));
  } catch {
    dispatch(setPackageError());
  }
};

export const NewPackage = async (dispatch, packageNew) => {
  try {
    dispatch(setLoading(false));
    const userId = getUserId();
    const { data } = await Http.post("/api/addPackage/" + userId, packageNew);
    dispatch(newPackage(data));
    handleApiError(dispatch, "success", "L'offre a été soumise avec succès!");
    dispatch(setLoading(true));
  } catch {
    handleApiError(dispatch, "error", "Erreur lors de la soumission du offre!");
  }
};

export const EditPackage = async (dispatch, packageToEdit) => {
  try {
    dispatch(setLoading(false));
    const userId = getUserId();
    const { data } = await Http.post(
      "/api/updatePackage/" + userId + "/" + packageToEdit.id,
      packageToEdit
    );
    dispatch(editPackage(data));
    handleApiError(
      dispatch,
      "success",
      "La modification a été soumise avec succès!"
    );
    dispatch(setLoading(true));
  } catch (e) {
    handleApiError(
      dispatch,
      "error",
      "Erreur lors de la soumission de la modification!"
    );
  }
};

export const DeletePackage = async (dispatch, packageToDelete) => {
  try {
    dispatch(setLoading(false));
    const userId = getUserId();
    await Http.delete(
      "/api/deletePackage/" + userId + "/" + packageToDelete.id
    );
    dispatch(deletePackage(packageToDelete));
    handleApiError(
      dispatch,
      "success",
      "La suppression a été soumise avec succès!"
    );
    dispatch(setLoading(true));
  } catch {
    handleApiError(
      dispatch,
      "error",
      "Erreur lors de la soumission de la suppression!"
    );
  }
};
