/**
 *
 * @param {*} cname
 * @param {string}
 * @returns
 */
function getUserCookies(cname) {
  const nameEquals = cname + "=";
  const cookies = document.cookie.split(";").map((cookie) => cookie.trim());
  for (const cookie of cookies) {
    if (cookie.indexOf(nameEquals) === 0) {
      return cookie.substring(nameEquals.length);
    }
  }
  return "";
}

const CookieHelper = {
  getUserCookies,
};

export default CookieHelper;
