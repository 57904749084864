import React from "react";
import Heading from "../Heading";
import ImageCard from "./ImageCard";

export default function PlacesCarousel() {
  return (
    <div className="m-3 mb-5">
      <Heading
        title="Villes tendances"
        subtitle="Les villes les plus recherchées sur RANCAR"
      />
      <ImageCard />
    </div>
  );
}
