import {
  setCalendarEvent,
  newCalendarEvent,
  editCalendarEvent,
  deleteCalendarEvent,
  setLoading,
  setCalendarEventError,
  deleteCalendarEventError,
  setEvent,
} from "../features/calendarEvent";
import { setSnackbar } from "../features/snackbar";
import Http from "../lib/http/http";

// const API_BASE_URL = '/api';
const USER_ID_KEY = "userId";

const getUserId = () => {
  const userId = localStorage.getItem(USER_ID_KEY);
  if (!userId) {
    throw new Error("User ID not found in localStorage");
  }
  return userId;
};

const handleApiError = (dispatch, errorType, errorMessage) => {
  const action = {
    snackbarOpen: true,
    snackbarType: errorType,
    snackbarMessage: errorMessage,
  };
  dispatch(setSnackbar(action));
};


export const GetCalendarEvent = async (dispatch) => {
  try {
    dispatch(setLoading(false));
    const userId = getUserId();
    const response = await Http.get("/api/reservationByManager/"+userId);
    dispatch(setCalendarEvent(response.data));
    dispatch(setLoading(true));
  } catch (e) {
    handleApiError(
      dispatch,
      "error",
      "Erreur lors de la chargement des réservations!"
    );
    console.log(e);
  }
};

export const GetEvent = async (dispatch, id) => {
  try {
    // api call
    const { data } = await Http.get("/reservation/" + id);
    dispatch(setEvent(data));
  } catch {
    dispatch(setCalendarEventError());
  }
};

export const NewCalendarEvent = async (dispatch, reservation) => {
  try {
    dispatch(setLoading(false));
    const userId = getUserId();
    const { data } = await Http.post(
      "/api/reserveVehicleByManager/" + userId,
      reservation
    );
    dispatch(newCalendarEvent(data));
    handleApiError(
      dispatch,
      "success",
      "La reservation a été soumise avec succès!"
    );
    dispatch(setLoading(true));
  } catch (e) {
    console.log(e);
    handleApiError(
      dispatch,
      "error",
      "Erreur lors de la soumission de la modification!"
    );
  }
};

export const EditCalendarEvent = async (dispatch, event) => {
  try {
    dispatch(setLoading(false));
    const userId = getUserId();
    const { data } = await Http.post(
      "/api/updateReservation/" + userId + "/" + event.id,
      event
    );
    dispatch(editCalendarEvent(data));
    handleApiError(
      dispatch,
      "success",
      "La modification a été soumise avec succès!"
    );
    dispatch(setLoading(true));
  } catch (e) {
    console.log(e);
    handleApiError(
      dispatch,
      "error",
      "Erreur lors de la soumission de la modification!"
    );
  }
};

export const DeleteCalendarEvent = async (dispatch, event) => {
  try {
    // api call
    await Http.delete("/reservation/" + event.id);
    dispatch(deleteCalendarEvent(event));
  } catch {
    dispatch(deleteCalendarEventError());
  }
};
