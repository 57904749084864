import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.value);
  const status = user.subscription ? user.subscription.status : false;
  const reservation = user.subscription
    ? user.subscription.package.reservation
    : false;

  useEffect(() => {
    if (!status || !reservation) {
      navigate("/outofservice");
    }
  }, [status, reservation, navigate]);

  return status && reservation ? children : null;
};

export default ProtectedRoute;
