import React, { useEffect, useState } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import Helmet from "../../components/Helmet/Helmet";
import { useParams, useNavigate } from "react-router-dom";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { useSelector, useDispatch } from "react-redux";
import AccordionGroup from "@mui/joy/AccordionGroup";
import Accordion from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionSummary from "@mui/joy/AccordionSummary";
import Form from "react-bootstrap/Form";
import AvaiblityAccordion from "../../components/UI/CarMaintenance/Avaiblity/AvaiblityAccordion";
import DocumentsAccordion from "../../components/UI/CarMaintenance/Documents/DocumentsAccordion";
import MaintenanceAccordion from "../../components/UI/CarMaintenance/Maintenance/MaintenanceAccordion";
import config from "../../assets/data/config.json";
import Http from "./../../lib/http/http";
import HttpForm from "../../lib/http/httpForm";
import { GetBrands } from "./../../services/Brand";
import { GetCategory } from "./../../services/Category";

const CarAdmin = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const categories = useSelector((state) => state.category.Category);
  const brands = useSelector((state) => state.brand.brands);

  const status = user.subscription ? user.subscription.status : false;
  const garage = user.subscription ? user.subscription.package.garage : false;

  const navigate = useNavigate();
  const [isValid, setisvalid] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [currentFile, setCurrentFile] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [data, setData] = useState({
    image: "",
    name: "",
    type: "",
    payPerDay: "",
    speed: "",
    capacity: "",
    description: "",
    fuel: "",
    uriPicture:"",
    registration: "",
    occupancyCapacity: "",
    categoryId: 0,
    brandId: 0,
    driver:"",
  });
  const handelChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
    if (event.target.name === "categoryId") {
      setSelectedCategory(event.target.value);
    }
    if (event.target.name === "brandId") {
      setSelectedBrand(event.target.value);
    }
  };
  const dataVerification = (data) => {
    for (const key in data) {
      if (data.hasOwnProperty(key) && data[key] === "") {
        return false;
      }
    }
    return true;
  };

  const [file, setFile] = useState(data["image"]);

  const submitHandler = async (e) => {
    if (dataVerification(data)) {
      setisvalid(true);
      let formData = new FormData();
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          formData.append(key, data[key]);
        }
      }
      formData.append("file", file);
      await HttpForm.post(`/api/updateVehicle/${user?.id}/${slug}`,formData);
      toggle();
    } else {
      setisvalid(false);
      toggle();
    }
  };
  const fileHandler = (e) => {
    setFile(e.target.files[0]);
    setCurrentFile(e.target.files[0]);
    const currentFile = e.target.files[0];
    const file = e.target.files[0];
    setData({
      ...data,
      image: file.name,
      uriPicture:file.name,
    });
    if (currentFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCurrentFile(reader.result);
      };
      reader.readAsDataURL(currentFile);
    }
  };

 

  useEffect(() => {
    GetBrands(dispatch);
    GetCategory(dispatch);
  }, [dispatch]);

  useEffect(() => {
     const fetchData = async () => {
    try {
      const result = await Http.get(`/api/getVehicleByManager/${user?.id}/${slug}`);
      setData(result.data);
      setSelectedCategory(result.data.category.id);
      setSelectedBrand(result.data.brand.id);
      setCurrentFile(`${config.images}/${result.data.uriPicture}`);
    } catch (err) {
      console.log(err)
      navigate("/parc");
    }
  };
    fetchData();
    window.scrollTo(0, 0);
  }, [slug, user?.id, navigate]);


  return (
    <Helmet title={data["name"]}>
      <section>
        <Container>
          <Row>
            <Col lg="12">
            {data?.uriPicture===null? (
              <DirectionsCarIcon
                style={{ fontSize: "200px", color: "var(--blue_color)" }}
                className="centered-element"
              />
            ) : (
              <img
                src={currentFile}
                loading="lazy"
                alt="véhicule"
                style={{ maxWidth: "500px", Height: "300px", margin: "50px" }}
              />)}
              <div className=" d-flex align-items-center gap-5 mb-5 ">
                <input
                  type="file"
                  onChange={(e) => fileHandler(e)}
                  accept="image/*"
                  id="file"
                  name="image"
                  className="btn_secondary input_file w-100"
                />
              </div>
            </Col>
            <Col lg="8">
              <div className="car__info">
                <div className=" d-flex align-items-center gap-5 mb-4 mt-3">
                  <FormGroup className="booking__form d-inline-block text-left">
                    <label className="text_color ">Nom de Véhicule</label>
                    <input
                      type="text"
                      placeholder="taper le nom"
                      required
                      name="name"
                      defaultValue={data["name"]}
                      onChange={(e) => handelChange(e)}
                    />
                  </FormGroup>
                  <FormGroup className="booking__form d-inline-block text-left">
                    <label className="text_color">Matricule</label>
                    <input
                      type="text"
                      onChange={(e) => handelChange(e)}
                      placeholder="taper le matricule"
                      required
                      name="registration"
                      defaultValue={data["registration"]}
                    />
                  </FormGroup>
                </div>
                <div className=" d-flex align-items-center gap-5 mb-4 mt-3">
                  <FormGroup className="booking__form d-inline-block text-left ">
                    <label className="text_color">
                      Catégorie du véhicule :
                    </label>
                    <select
                      className="select_input"
                      onChange={handelChange}
                      value={selectedCategory}
                      name="categoryId"
                    >
                      {categories.map((category, index) => {
                        return (
                          <option
                            key={index}
                            value={parseInt(category.id)}
                            selected={selectedCategory}
                            // defaultValue={category.name}
                          >
                            {category.name}
                          </option>
                        );
                      })}
                    </select>
                  </FormGroup>
                  <FormGroup className="booking__form d-inline-block text-left ">
                    <label className="text_color">Marque :</label>
                    <select
                      className="select_input"
                      onChange={(e) => handelChange(e)}
                      name="brandId"
                      value={selectedBrand}
                    >
                      {brands.map((brand, index) => {
                        return (
                          <option
                            key={index}
                            value={parseInt(brand.id)}
                            selected={data["brandId"] === parseInt(brand.id)}
                          >
                            {brand.name}
                          </option>
                        );
                      })}
                    </select>
                  </FormGroup>
                </div>

                <div className=" d-flex align-items-center gap-5 mb-4 mt-3">
                  <FormGroup className="booking__form d-inline-block text-left">
                    <label className="text_color ">Vitesse</label>
                    <input
                      placeholder="taper le vitesse"
                      required
                      name="speed"
                      defaultValue={data["speed"]}
                      onChange={(e) => handelChange(e)}
                    />
                  </FormGroup>
                  <FormGroup className="booking__form d-inline-block text-left ">
                    <label className="text_color">Carburateur</label>
                    <select
                      className="select_input"
                      onChange={(e) => handelChange(e)}
                      name="fuel"
                    >
                      <option
                        value={"essence"}
                        selected={data["fuel"] === "essence"}
                      >
                        {" "}
                        Essence (essence/pétrole)
                      </option>
                      <option
                        value={"gasole"}
                        selected={data["fuel"] === "gasole"}
                      >
                        {" "}
                        Gazole (diesel)
                      </option>
                      <option value={"gaz"} selected={data["fuel"] === "gaz"}>
                        {" "}
                        Gaz liquide (GPL)
                      </option>
                      <option
                        value={"electrique"}
                        selected={data["fuel"] === "electrique"}
                      >
                        {" "}
                        Electrique
                      </option>
                    </select>
                  </FormGroup>
                </div>
                <div className=" d-flex align-items-center gap-5 mb-4 mt-3">
                  <FormGroup className="booking__form d-inline-block text-left">
                    <label className="text_color ">Nombre de place</label>
                    <input
                      type="number"
                      required
                      name="occupancyCapacity"
                      slot="1"
                      defaultValue={data["occupancyCapacity"]}
                      onChange={(e) => handelChange(e)}
                    />
                  </FormGroup>

                  <FormGroup className="booking__form d-inline-block text-left">
                    <label className="text_color ">Prix par jour Dt/j</label>
                    <input
                      type="number"
                      required
                      name="payPerDay"
                      defaultValue={data["payPerDay"]}
                      onChange={(e) => handelChange(e)}
                    />
                  </FormGroup>
                </div>
              </div>
            </Col>
            <Col lg="4">
            <FormGroup className="booking__form w-100 mt-2 mb-3 text-left ">
                    <label className="text_color">Conducteur :</label>
                    <select
                      className="select_input"
                      onChange={(e) => handelChange(e)}
                      name="driver">
                      <option
                        value={"Avec ou sans conducteur"}
                        selected={data["driver"] === "Avec ou sans conducteur"}>
                        {" "}
                        Avec ou sans conducteur
                      </option>
                      <option
                        value={"Avec conducteur"}
                        selected={data["driver"] === "Avec conducteur"}>
                        {" "}
                        Avec conducteur
                      </option>
                      <option value={"Sans conducteur"} selected={data["driver"] === "Sans conducteur"}>
                        {" "}
                        Sans conducteur
                      </option>
                    </select>
                  </FormGroup>
              <div className="text-left mt-3">
                <label className="text_color " style={{marginTop: "25px"}}>Description</label>
                <Form.Control
                  as="textarea"
                  placeholder="Autre informations"
                  name="description"
                  defaultValue={data["description"]}
                  onChange={(e) => handelChange(e)}
                  style={{ height: "290px" }}
                />
              </div>
            </Col>
            <Col lg="12" className="mt-5 mb-5">
              <AccordionGroup size="lg">
                <Accordion variant="plain">
                  <AccordionSummary>
                    <div className="text_color "> Disponibilité </div>
                  </AccordionSummary>
                  <AccordionDetails className="accordion_container">
                    <div className="text_color text-left">
                      {" "}
                      <AvaiblityAccordion item={data} />{" "}
                    </div>
                  </AccordionDetails>
                </Accordion>
                {status && garage ? (
                  <>
                    <Accordion variant="plain">
                      <AccordionSummary>
                        <div className="text_color "> Documents </div>
                      </AccordionSummary>
                      <AccordionDetails className="accordion_container">
                        <div className="text_color text-left">
                          {" "}
                          <DocumentsAccordion item={data} />{" "}
                        </div>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion variant="plain">
                      <AccordionSummary>
                        <div className="text_color "> Entretiens </div>
                      </AccordionSummary>
                      <AccordionDetails className="accordion_container">
                        <div className="text_color text-left">
                          {" "}
                          <MaintenanceAccordion />{" "}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </>
                ) : null}
              </AccordionGroup>
              <div className="booking-info mt-5 mb-4">
                <button
                  onClick={(e) => submitHandler(e)}
                  className="btn_secondary"
                >
                  Enregistrer
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Modifier la formation</ModalHeader>
        <ModalBody>
          {isValid ? (
            <>
              {" "}
              <h2>Succés</h2>
              Les modifications des informations ont été effectuées avec succès
            </>
          ) : (
            <>
              Les données que vous avez saisi(e) n’est pas complet.S'il vous
              plaît, essayez de les compléter !
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            style={{ backgroundColor: "#E2001A" }}
            onClick={() => {
              toggle();
              // window.location.reload(true);
            }}
          >
            Fermer
          </Button>
        </ModalFooter>
      </Modal>
    </Helmet>
  );
};

export default CarAdmin;
