import React, { useState } from "react";
import {
  Button,
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Form,
} from "reactstrap";
import "../../assets/styles/car-item.css";
import { NewCalendarEvent } from "../../services/CalendarEvent";
import { useDispatch, useSelector } from "react-redux";
import useFetchManager from "../../hooks/useFetchManager";

function getTodayDate(x) {
  const today = new Date();
  const futureDate = new Date(today);
  futureDate.setDate(today.getDate() + x);
  const year = futureDate.getFullYear();
  let month = (futureDate.getMonth() + 1).toString();
  let day = futureDate.getDate().toString();
  month = month.length === 1 ? `0${month}` : month;
  day = day.length === 1 ? `0${day}` : day;

  return `${year}-${month}-${day}`;
}
function getCustomTime(offset) {
  const now = new Date();
  now.setHours(now.getHours() + offset);

  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");

  // Concaténer l'heure et les minutes
  const formattedTime = `${hours}:${minutes}`;

  return formattedTime;
}

const AddEvent = ({ modelopen, handleModel }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);

  const { data } = useFetchManager(`/api/vehicles/${user?.id}`);
  const cars = data;
  const [payment, setpayment] = useState("");
  const [dateprise, setdateprise] = useState(getTodayDate(0));
  const [datereprise, setdatereprise] = useState(getTodayDate(1));
  const [timeprise, settimeprise] = useState(getCustomTime(0));
  const [timereprise, settimereprise] = useState(getCustomTime(4));
  const [vehicle, setVehicle] = useState({});
  const [details, setDetails] = useState("");

  const dataVerification = (data) => {
    for (const key in data) {
      if (data.hasOwnProperty(key) && data[key] === "") {
        return false;
      }
    }
    return true;
  };
  const submitHandler = async () => {
    const dataR = {
      vehicleId: vehicle.id,
      startDate: dateprise + " " + timeprise,
      endDate: datereprise + " " + timereprise,
      method: payment,
      details: details,
    };
    if (dataVerification(dataR)) {
      NewCalendarEvent(dispatch, dataR);
      // console.log(dataR);
    }
    handleModel();
  };
  const handleSelected = (e) => {
    setpayment(e.target.value);
  };

  const handleCarChange = (event) => {
    const selectedName = event.target.value;
    const car = cars.find((c) => c.name === selectedName);
    setVehicle(car);
  };
  return (
    <Modal isOpen={modelopen} toggle={() => handleModel()}>
      <ModalHeader toggle={handleModel}>Réservation Local</ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col md={12}>
              <FormGroup className="booking__form  text-left w-100">
                <label className="text_color">Véhicule:</label>
                <select
                  className="select_input"
                  onChange={(e) => handleCarChange(e)}>
                  <option value={""} selected={true}>
                    Sélectionner le véhicule{" "}
                  </option>
                  )
                  {cars?.map((car, index) => {
                    return (
                      <option key={index} value={car.name}>
                        {car.name + " (" + car.registration + ")"}
                      </option>
                    );
                  })}
                </select>
              </FormGroup>
            </Col>
            <FormGroup className="booking__form d-inline-block  ">
              <label className="text_color">Date de début</label>
              <input
                id="datepickerStart"
                name="datepickerStart"
                type="date"
                defaultValue={dateprise}
                onChange={(e) => setdateprise(e.target.value)}
                placeholder="Journey Date"
                required
              />
            </FormGroup>
            <FormGroup className="booking__form d-inline-block ms-1 mb-4 ">
              <label className="text_color">Heure de début</label>
              <input
                type="time"
                value={timeprise}
                onChange={(e) => settimeprise(e.target.value)}
                required
                // className="time__picker"
              />
            </FormGroup>
            <FormGroup className="booking__form d-inline-block">
              <label className="text_color">Date de restitution</label>
              <input
                id="datepicker"
                name="datepicker"
                type="date"
                value={datereprise}
                onChange={(e) => setdatereprise(e.target.value)}
              />
            </FormGroup>
            <FormGroup className="booking__form d-inline-block ms-1 mb-4 ">
              <label className="text_color">Heure de fin</label>
              <input
                type="time"
                value={timereprise}
                onChange={(e) => settimereprise(e.target.value)}
                required
                // className="time__picker2"
              />
            </FormGroup>
          </Row>
          <FormGroup>
            <Label for="exampleAddress">Description</Label>
            <Input
              id="Name"
              name="title"
              placeholder="Nom et prénom du propriétaire"
              onChange={(e) => setDetails(e.target.value)}
              defaultValue={""}
            />
          </FormGroup>
          <FormGroup className="booking__form d-inline-block  ">
            <label className="text_color">Méthode de paiement</label>
            <select
              className="select_input"
              required
              onChange={(e) => handleSelected(e)}>
              <option value={""}>Choisir la méthode</option>
              <option value={"Espèce"}>Espèce</option>
              <option value={"Virment"}>Virement bancaire direct</option>
              <option value={"Chéque"}>Chèque</option>
              <option value={"Master Card"}>Master Card</option>
            </select>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <button onClick={(e) => submitHandler(e)} className=" btn_modal">
          Enregistrer
        </button>{" "}
        <Button color="secondary" onClick={handleModel}>
          Annuler
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddEvent;
//  <button className=" w-50 car__item-btn car__btn-details">
// <Link to={`/cars/${carName}`}>Details</Link>
//  </button>
