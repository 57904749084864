import {
  setCities,
  newCity,
  editCity,
  deleteCity,
  setLoading,
} from "../features/city";
import { setSnackbar } from "../features/snackbar";
import HttpForm from "../lib/http/httpForm";

const API_BASE_URL = "/api";
const USER_ID_KEY = "userId";

const getUserId = () => {
  const userId = localStorage.getItem(USER_ID_KEY);
  if (!userId) {
    throw new Error("User ID not found in localStorage");
  }
  return userId;
};

const handleApiError = (dispatch, errorType, errorMessage) => {
  const action = {
    snackbarOpen: true,
    snackbarType: errorType,
    snackbarMessage: errorMessage,
  };
  dispatch(setSnackbar(action));
};

export const GetCities = async (dispatch) => {
  try {
    const { data } = await HttpForm.get("/city");
    dispatch(setCities(data));
  } catch (e) {
    handleApiError(dispatch, "error", "Erreur lors du chargement des villes");
    console.log(e);
  }
};

export const NewCity = async (dispatch, city) => {
  try {
    dispatch(setLoading(false));
    const userId = getUserId();
    const { data } = await HttpForm.post(
      `${API_BASE_URL}/addcity/${userId}`,
      city
    );
    console.log(data);
    dispatch(newCity(data));
    handleApiError(dispatch, "success", "La ville a été soumise avec succès!");
    dispatch(setLoading(true));
  } catch {
    handleApiError(
      dispatch,
      "error",
      "Erreur lors de la soumission de la ville!"
    );
  }
};

export const EditCity = async (dispatch, city) => {
  try {
    dispatch(setLoading(false));
    const userId = getUserId();
    const { data } = await HttpForm.post(
      `${API_BASE_URL}/updateCity/${userId}/${city.get("id")}`,
      city
    );
    dispatch(editCity(data));
    handleApiError(
      dispatch,
      "success",
      "La modification a été soumise avec succès!"
    );
    dispatch(setLoading(true));
  } catch {
    handleApiError(
      dispatch,
      "error",
      "Erreur lors de la soumission de la modification!"
    );
  }
};

export const DeleteCity = async (dispatch, city) => {
  try {
    dispatch(setLoading(false));
    const userId = getUserId();
    await HttpForm.delete(`${API_BASE_URL}/city/${userId}/${city.id}`);
    dispatch(deleteCity(city));
    handleApiError(
      dispatch,
      "success",
      "La suppression a été soumise avec succès!"
    );
    dispatch(setLoading(true));
  } catch {
    handleApiError(
      dispatch,
      "error",
      "Erreur lors de la soumission de la suppression!"
    );
  }
};
