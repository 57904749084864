import { useEffect, useState } from "react";
import axios from "axios";
import config from "../assets/data/config.json";
import CookieHelper from "./../lib/Utils/helper";

const getCurrentUser = () => {
  const cookies = CookieHelper.getUserCookies("user")
    ? CookieHelper.getUserCookies("user").slice(3, -3)
    : null;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [data, setData] = useState({
    firstName: null,
    lastName: null,
    phone: null,
    address: null,
  });
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const { data } = await axios.get(`${config.url}/api/getCurrentUser`, {
          headers: { Authorization: `Bearer ${cookies}` },
        });
        setData(data);
        // setCookiesRole("role", response.data.roles[0]);
        localStorage.setItem("userId", data.id);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCurrentUser();
  }, [cookies]);

  return { data };
};

export default getCurrentUser;
