import React from "react";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../../components/Helmet/Helmet";
import CommonSection from "../../components/UI/CommonSection";
import CarItem from "../../components/UI/CarItem";
import { useState, useEffect } from "react";
import axios from "axios";
import Loader from "../../lib/Loader/Loader";
import config from "../../assets/data/config.json";

const AllCars = () => {
  const [carData, setCarData] = useState([]);
  const [isLoaded, setIisLoaded] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(`${config.url}/vehicles`);
        setCarData(result.data);
        setIisLoaded(true);
      } catch (err) {
        console.log(err);
        setCarData([]);
        setIisLoaded(true);
      }
    };
    fetchData();
  }, []);
  return (
    <Helmet title="Cars">
      <CommonSection title="Location de véhicule" />

      <section>
        <Container>
          <Row>
            {isLoaded ? (
              <>
                {carData.length === 0 ? (
                  <Col className="div_disponible text_color">
                    <h3>Aucune véhicule disponible</h3>
                    <div className="list_to_do">
                      <span>
                        Nous sommes désolés, mais les sociétés avec lesquelles
                        nous travaillons dans cette region n'ont aucune véhicule
                        disponible.
                        <br />
                        Que puis-je faire ?<br />
                        Vous pouvez essayer de :
                        <ul>
                          <li>changer la date ou l'heure de prise en charge</li>
                          <li>changer la date ou l'heure de restitution</li>
                          <li>
                            rechercher une véhicule dans une destination à
                            proximité
                          </li>
                        </ul>
                      </span>
                    </div>
                  </Col>
                ) : (
                  <>
                    {carData.map((item) => (
                      <CarItem item={item} key={item.id} />
                    ))}
                  </>
                )}
              </>
            ) : (
              <Loader />
            )}
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default AllCars;
