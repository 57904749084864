import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, FormGroup, Card, CardBody, CardTitle } from "reactstrap";
import { Container, Row, Col } from "reactstrap";
import config from "../../assets/data/config.json";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import image from "../../assets/images/man.jpg";
import Loader from "../../lib/Loader/Loader";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../features/snackbar";
// import { SetSubscription } from "../../services/Subscription";
import { LoginUser } from "../../services/User";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [cookies, setCookie] = useCookies(["user"]);
  const [loading, setLoading] = useState(false);

  const errorMessage =
    "Mot de passe incorrect. Réessayez ou cliquez sur 'Mot de passe oublié'pour le réinitialiser.";
  const successMessage = "L'opération s'est terminée avec succès";
  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios
      .post(`${config.url}/api/login`, {
        username: username,
        password: password,
      })
      .then((res) => {
        setCookie("user", JSON.stringify(res.data.token));
        LoginUser(dispatch, res.data.token);
        const action = {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: successMessage,
        };
        dispatch(setSnackbar(action));
        navigate("/");
      })
      .catch((error) => {
        const action = {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: errorMessage,
        };
        dispatch(setSnackbar(action));
      });

    setLoading(false);
  };

  useEffect(() => {
    if (cookies.user) {
      // getCurrentUser();
      navigate("/");
    }
  });

  // const [open, setOpen] = useState(false);
  // const vertical = "top";
  // const horizontal = "center";

  return (
    <div className="login_container">
      <Container className="d-flex min-vh-100">
        <Row className="m-auto align-self-center ">
          <Col lg="15" className="mb-5 text-center">
            <Card
              className="body_color"
              style={{ border: "transparent", borderRadius: "75px" }}>
              {loading ? (
                <Loader />
              ) : (
                <CardBody
                  className="body_color"
                  style={{ border: "transparent", borderRadius: "75px" }}>
                  <CardTitle className="body_color"></CardTitle>
                  <Row>
                    <Col xl="6" className="p-3">
                      <Form
                        className="form body_color"
                        onSubmit={submitHandler}>
                        <FormGroup className="contact__form">
                          <div>
                            <label htmlFor="username" className="text_color">
                              Adresse e-mail
                            </label>
                          </div>
                          <input
                            type="text"
                            style={{ width: "75%" }}
                            onChange={(e) => setUsername(e.target.value)}
                            className="input"
                            id="username"
                            required
                          />
                        </FormGroup>
                        <FormGroup className="contact__form">
                          <div>
                            <label htmlFor="password" className="text_color">
                              Mot de passe
                            </label>
                          </div>
                          <input
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                            style={{ width: "75%" }}
                            className="input"
                            id="password"
                            required
                          />
                        </FormGroup>
                        <FormGroup className="contact__form">
                          <button className=" btn_secondary mt-3">
                            Se connecter
                          </button>
                        </FormGroup>
                        <FormGroup>
                          <p className="text_color">
                            <Link
                              to="/forgetpassword"
                              className="form-link"
                              style={{
                                textDecoration: "none",
                                color: "var(--blue_color)",
                              }}>
                              Mot de passe oublié ?
                            </Link>
                          </p>
                        </FormGroup>
                        <FormGroup>
                          <p className="text_color">
                            Vous n'avez pas de compte ?{" "}
                            <Link
                              to="/register"
                              className="form-link"
                              style={{
                                textDecoration: "none",
                                color: "var(--blue_color)",
                              }}>
                              S'inscrire
                            </Link>
                          </p>
                        </FormGroup>
                      </Form>
                    </Col>
                    <Col xl="6">
                      <div
                        className="contact__info mt-3 text_color"
                        style={{ marginRight: "10px" }}>
                        <img
                          src={image}
                          loading="lazy"
                          className="w-100 h-100 p-3 mb-3 "
                          style={{ borderRadius: "15%" }}
                          alt={"contact_nous"}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
