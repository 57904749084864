import { createSlice, createAction } from "@reduxjs/toolkit";

export const setPackageError = createAction("setPackageError");
export const newPackageError = createAction("newPackageError");
export const editPackageError = createAction("editPackageError");
export const deletePackageError = createAction("deletePackageError");

export const PackageSlice = createSlice({
  name: "package",
  initialState: {
    packages: [],
    loading: false,
  },
  reducers: {
    setPackages: (state, action) => {
      return { ...state, packages: action.payload, loading: true };
    },
    newPackage: (state, action) => {
      return { ...state, packages: [...state.packages, action.payload] };
    },
    editPackage: (state, action) => {
      const packageToEtited = state.packages.map((packageEdited) => {
        if (packageEdited.id === action.payload.id) {
          packageEdited = action.payload;
        }
        return packageEdited;
      });
      return { ...state, packages: [...packageToEtited] };
    },
    deletePackage: (state, action) => {
      const packageDeleted = state.packages.filter(
        (packageDeleted) => packageDeleted.id !== action.payload.id
      );
      return { ...state, packages: [...packageDeleted] };
    },
    setLoading: (state, action) => {
      return { ...state, loading: action.payload };
    },
  },
});

export const {
  setPackages,
  newPackage,
  editPackage,
  deletePackage,
  setLoading,
} = PackageSlice.actions;

export default PackageSlice.reducer;
