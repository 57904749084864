import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";
import SquareIcon from "@mui/icons-material/Square";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import useFetch from "../../../hooks/useFetch";
import { useParams } from "react-router-dom";

function transformedData(data) {
  const res = data
    ?.filter((item) => item.confirmed === true)
    .map((item) => {
      return {
        ...item,
        id: item.id,
        color: "var(--blue_color)",
        start: item.startDate,
        end: item.endDate,
        title: "Reservé",
      };
    });

  return res;
}
export default function CalendarModal({ ref }) {
  const { slug } = useParams();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const { data } = useFetch("/reservationByVehicle/" + slug);

  const events = transformedData(data);

  const dayHeaderContent = (args) => {
    return args.date.toLocaleDateString("fr-FR", { weekday: "long" }); // Utilisez la localisation qui convient à vos besoins
  };
  return (
    <div>
      <button className="btn_secondary" onClick={toggle}>
        Disponibilte
      </button>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader className="body_color text_color" toggle={toggle}>
          Reserervation
        </ModalHeader>
        <ModalBody className="body_color">
          <FullCalendar
            ref={ref}
            headerToolbar={{
              left: "title",
              center: "",
              right: "prev,today,next",
            }}
            events={events}
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            locale={frLocale}
            dayHeaderContent={dayHeaderContent}
          />
          <div className="mt-3 text_color">
            <span>
              <SquareIcon color="error" /> Reserve <CropSquareIcon />
              Disponible
            </span>
          </div>
        </ModalBody>
        <ModalFooter className="body_color">
          <Button color="secondary" onClick={toggle}>
            Fermer
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
